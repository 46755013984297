import React from "react"
import {
	NavItem,
	NavLink
} from "reactstrap"
import { connect } from "react-redux"
import * as Icon from "react-feather"
import _ from "lodash"
import classnames from "classnames"
import AutoComplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent"

class NavContainer extends React.PureComponent {
	state = {
		navbarSearch: false,
		showBookmarks: false,
		value: "",
	}

	UNSAFE_componentWillMount() {
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
	}

	componentDidUpdate() {
		this.props.handleAppOverlay(this.state.value)
	}

	handleSwitchContainer = (suggestion) => {
		let container = _.get(suggestion, "domain", "")
		let server = _.replace(_.get(suggestion, "server", ""), "_", ".")
		return window.location.href = `${window.location.protocol}//${container}.${server}${window.location.pathname}${window.location.search}`
	}

	render() {
		// if (!this.isValid()) return false
		const containers = _.get(this.props, ["user", "auth_docker"], [])
		const currentContainer = _.get(_.find(containers, con => con.current), "docker", {})
		const suggestions = _.map(containers, con => {
			const server = _.get(con, ["docker", "server"], "") === "dev_podorders_store" ? "podorders_store" : _.get(con, ["docker", "server"], "")
			return {
				id: _.get(con, "docker_id", ""),
				title: _.get(con, ["docker", "label"], ""),
				filter_key: _.get(con, ["docker", "domain"], "") + server + _.get(con, ["docker", "label"], ""),
				domain: _.get(con, ["docker", "domain"], ""),
				server: server,
				name: null,
				starred: true,
				icon: "Server",
				link: false,
				full_link: _.get(con, ["docker", "domain"], "") + "." + _.replace(server, "_", ".")
			}
		})
		if (!containers || !containers.length) {
			return false
		}
		// const {subDomain} = _.get(currentContainer, "domain", false)//getDomainInfo()
		return <NavItem
			className="nav-item d-none d-lg-block"
			onClick={() => {
				this.setState({ showBookmarks: !this.state.showBookmarks })
			}}
		>
			<NavLink>
				<Icon.Server className={`text-${this.props.app.customizer.menuTheme}`} size={21} />
				{" "}
				{_.get(currentContainer, "domain", false) || "Applications"} {
					_.get(currentContainer, "label", false)
						? <span style={{ marginLeft: "3px" }}>{"(" + _.get(currentContainer, "label", false) + ")"}</span>
						: false
				}
			</NavLink>
			<div
				className={classnames("container-switch bookmark-input search-input", {
					show: this.state.showBookmarks
				})}
			>
				<div className="bookmark-input-icon">
					<Icon.Search size={15} className="primary" />
				</div>

				<AutoComplete
					suggestions={suggestions}
					className="form-control"
					placeholder="Search"
					filterKey="filter_key"
					autoFocus={true}
					suggestionLimit={this.state.value.length ? 6 : 100}
					clearInput={this.state.showBookmarks}
					defaultSuggestions={true}
					onChange={e => this.setState({ value: e.target.value })}
					externalClick={() => this.setState({ showBookmarks: false })}
					onKeyDown={e => {
						if (e.keyCode === 27 || e.keyCode === 13) {
							this.setState({
								showBookmarks: false
							})
							this.props.handleAppOverlay("")
						}
					}}
					customRender={(
						suggestion,
						i,
						filteredData,
						activeSuggestion,
						onSuggestionItemClick,
						onSuggestionItemHover,
						userInput
					) => {
						const IconTag = Icon[suggestion.icon ? suggestion.icon : "X"]
						if (userInput.length) {
							return (
								<li
									className={classnames(
										"suggestion-item d-flex justify-content-between",
										{
											active:
												filteredData.indexOf(suggestion) ===
												activeSuggestion
										},
									)}
									key={`container-${_.get(suggestion, "domain", "")}`}
									onClick={() => this.handleSwitchContainer(suggestion)}
								>
									<span
										className="component-info"
									>
										<IconTag size={15} />
										<span className="align-middle ml-1">
											{suggestion.domain}
										</span>
									</span>
									{
										suggestion.domain === "Manufacture App"
											? <span>
											<code>app.{_.head(_.get(suggestion, "server", "").split("_"))}</code>
										</span>
											: <span>
											<i>{suggestion.title}</i> <code>{_.head(_.get(suggestion, "server", "").split("_"))}</code>
										</span>
									}
								</li>
							)
						} else {
							return suggestion.starred === true ? (
								<li
									className={classnames(
										"suggestion-item d-flex justify-content-between",
										{
											active:
												filteredData.indexOf(suggestion) ===
												activeSuggestion
										}
									)}
									key={`container-${_.get(suggestion, "domain", "")}`}
									onClick={() => this.handleSwitchContainer(suggestion)}
								>
									<span
										className="component-info"
									>
										<IconTag size={15} />
										<span className="align-middle ml-1">
											{suggestion.domain}
										</span>
									</span>
									{
										suggestion.domain === "Manufacture App"
											? <span>
											<code>app.{_.head(_.get(suggestion, "server", "").split("_"))}</code>
										</span>
											: <span>
											<i>{suggestion.title}</i> <code>{_.head(_.get(suggestion, "server", "").split("_"))}</code>
										</span>
									}
								</li>
							) : null
						}
					}}
					onSuggestionsShown={userInput => {
						if (this.state.showBookmarks) {
							this.props.handleAppOverlay(userInput)
						}
					}}
					onSuggestionClick={(link, e, val, suggestion) => {
						this.handleSwitchContainer(suggestion)
					}}
				/>
			</div>
		</NavItem>
	}
}
const mapStateToProps = state => {
	return {
		hasRole: state.auth.login.hasRole,
		user: state.auth.login.loggedInUser,
		app: state.customizer,
		token: state.auth.login.token,
	}
}
export default connect(mapStateToProps)(NavContainer)
