// import { combineReducers } from "redux"
import _ from "lodash"
import uniqid from 'uniqid'
// import { v4 as uuidv4 } from 'uuid'
// import { ucFirst } from './../../../utils/string'
/* eslint-disable */
const DEFAULT_STATES = {
    inited: false,
    tab: 'library',
    category: 'all',
    loading: false,
    name: '',
    items: [],
    total: 0,
    page: 1,
    perpage: 50,
    messages: []
}

const cliparts = ( state = { ...DEFAULT_STATES }, action ) => {
    let items = []
    let total = 0
    let page = 1
    let newState = _.cloneDeep(state)
    let messages = _.get(newState, 'messages')
    let id = null
    switch (action.type) {
        case 'SET_CLIPART_LOADING':
            var loading = _.get(action, ['payload', 'loading'], false)
            return { ...state, loading }
        break

        case 'SET_CLIPART_CATEGORY':
            var category = _.get(action, ['payload', 'category'], false)
            return { ...state, category }
        break;

        case 'SET_CLIPART_TAB':
            var tab = _.get(action, ['payload', 'tab'], false)
            return { ...state, tab }
        break;

        case 'INIT_CLIPART':
            items = _.get(action, ['payload', 'items'], [])
            total = _.get(action, ['payload', 'total'], 0)
            return {
                ...state,
                inited: true,
                items,
                total
            }
        break;

        case 'SET_CLIPARTS':
            items = _.get(action, ['payload', 'items'], [])
            total = _.get(action, ['payload', 'total'], 0)
            return {
                ...state,
                inited: true,
                items,
                total
            }
        break;

        case 'ADD_CLIPARTS':
            items = _.get(action, ['payload', 'items'], [])
            page = _.get(action, ['payload', 'page'], 1)
            total = _.get(action, ['payload', 'total'], 0)
            return {
                ...state,
                items: [
                    ...state.items,
                    ...items
                ],
                page,
                total
            }
        break;

        case 'SET_CLIPART_PAGE':
            page = _.get(action, ['payload', 'page'], 1)
            return {
                ...state,
                page
            }
        break;

        case 'SET_SEARCH_NAME':
            var name = _.get(action, ['payload', 'name'])
            return {
                ...state,
                name,
                page: 1,
                total: 0
            }
        break;

        case 'ADD_UPLOAD_CLIPARTS':
            items = _.get(action, ['payload', 'items'], [])
            return {
                ...state,
                items: [
                    ...items,
                    ..._.get(state, 'items'),
                ]
            }
        break;

        case 'UPDATE_CLIPART':
            // UPDATE AFTER UPLOADED
            var clipart = _.get(action, ['payload', 'clipart'])
            id = _.get(clipart, 'id')
            items = _.cloneDeep(_.get(state, 'items'))
            items = _.map(items, (item) => {
                if ( _.get(item, 'id') && _.get(item, 'id') === id ) {
                    return clipart
                }
                return item
            })
            return {
                ...state,
                items
            }
        break

        case 'REMOVE_CLIPART':
            // UPDATE AFTER UPLOADED
            var _id = _.get(action, ['payload', '_id'])
            items = _.cloneDeep(_.get(state, 'items'))
            items = _.filter(items, (item) => _.get(item, '_id') && _.get(item, '_id') !== _id)
            return {
                ...state,
                items
            }
        break

        case 'CLIPART_FLASH_MESSAGE':
            var message = _.get(action, ['payload', 'message'])
            var type = _.get(action, ['payload', 'type'])
            messages.push({
                id: uniqid(),
                type,
                message
            })
            return {
                ...newState,
                messages
            }
        break;

        case 'CLIPART_REMOVE_FLASH_MESSAGE':
            id = _.get(action, ['payload', 'id'])
            messages = _.filter(messages, (me) => me.id !== id)
            return {
                ...newState,
                messages
            }
        break;

        default: {
            return state
        }
    }
}

export default cliparts
