export const pushBackgroundTask = (task) => {
	return (dispatch) => dispatch({ type: 'PUSH_BACKGROUND_TASK', payload: task });
};

export const removeBackgroundTask = (task) => {
	return (dispatch) => dispatch({ type: 'REMOVE_BACKGROUND_TASK', payload: task });
};

export const nullQueueTask = (task) => {
	return (dispatch) => dispatch({ type: 'NULL_QUEUE_TASK' });
};

export const errorBackgroundTask = (task) => {
	return (dispatch) => dispatch({ type: 'ERROR_BACKGROUND_TASK', payload: task });
};

export const removeErrorBackgroundTask = (task) => {
	return (dispatch) => dispatch({ type: 'REMOVE_ERROR_BACKGROUND_TASK', payload: task });
};
