import _ from 'lodash';
const Query = require('graphql-query-builder');

const FileField = [
	'filename',
	'path',
	'url',
	'width',
	'height',
	'size',
	'mimetype',
	{
		image_sizes: ['name', 'url', 'width', 'height'],
	},
];

export const responseField = [
	'_id',
	'name',
	'width',
	'height',
	'image',
	{
		tags: ['_id', 'name'],
	},
	{
		images: [
			'type',
			'name',
			{
				image: FileField,
			},
			'print_fit',
			'print_x',
			'print_y',
			'print_width',
			'print_height',
			'print_rotate',
			'print_knew_x',
			'print_knew_y',
			{
				settings: [
					'print_x',
					'print_y',
					'print_width',
					'print_height',
					'print_rotate',
					'print_knew_x',
					'print_knew_y',
					'print_scale_x',
					'print_scale_y',
					'print_crop_x',
					'print_crop_y',
					'print_crop_width',
					'print_crop_height',
				],
			},
		],
	},
	{
		prints: [
			'name',
			{
				prints: [
					'type',
					{
						image: FileField,
					},
				],
			},
		],
	},
	'status',
	'created_at',
	'updated_at',
	'created_by',
	{
		user: ['_id', 'email', 'name'],
	},
];

export const mockupDesignTagsQuery = (args) => {
	const query = new Query('mockupDesignTags', {
		name: _.get(args, 'name', ''),
	}).find(['_id', 'name']);
	return `query {${query}}`;
};

export const mockupDesignsQuery = (args) => {
	let status = _.get(args, 'status', '');
	const query = new Query('mockupDesigns', {
		name: _.get(args, 'name', ''),
		start: _.get(args, 'start', ''),
		end: _.get(args, 'end', ''),
		status: status ? status.toLowerCase() : '',
		tag: _.get(args, 'tag', ''),
	}).find(responseField);
	return `query {${query}}`;
};

export const mockupDesignQuery = (args) => {
	const query = new Query('mockupDesign', {
		_id: _.get(args, '_id', ''),
	}).find(responseField);
	return `query {${query}}`;
};

export const saveMockupDesignQuery = (args) => {
	let params = {
		status: _.get(args, 'status', ''),
		name: _.get(args, 'name', ''),
		width: _.get(args, 'width') ? parseFloat(_.get(args, 'width', 1100).toFixed(2)) : 1100,
		height: _.get(args, 'height') ? parseFloat(_.get(args, 'height', 1100).toFixed(2)) : 1100,
		image: _.get(args, 'image', ''),
	};

	let images = _.get(args, 'images', []);
	if (images.length > 0) {
		images = images.filter((print) => print.name);
		if (images) {
			images = images.map((print, index) => {
				let image = _.get(print, 'image', {});
				let settings = _.get(print, 'settings', {});
				let image_sizes = _.get(image, 'image_sizes', false);
				let print_settings = {};
				if (_.get(settings, 'print_x', 0)) {
					print_settings.print_x = _.get(settings, 'print_x', 0);
				}
				if (_.get(settings, 'print_y', 0)) {
					print_settings.print_y = _.get(settings, 'print_y', 0);
				}
				if (_.get(settings, 'print_width', 0)) {
					print_settings.print_width = _.get(settings, 'print_width', 0);
				}
				if (_.get(settings, 'print_height', 0)) {
					print_settings.print_height = _.get(settings, 'print_height', 0);
				}
				if (_.get(settings, 'print_rotate', 0)) {
					print_settings.print_rotate = _.get(settings, 'print_rotate', 0);
				}
				if (_.get(settings, 'print_knew_x', 0)) {
					print_settings.print_knew_x = _.get(settings, 'print_knew_x', 0);
				}
				if (_.get(settings, 'print_knew_y', 0)) {
					print_settings.print_knew_y = _.get(settings, 'print_knew_y', 0);
				}
				if (_.get(settings, 'print_scale_x', 0)) {
					print_settings.print_scale_x = _.get(settings, 'print_scale_x', 0);
				}
				if (_.get(settings, 'print_scale_y', 0)) {
					print_settings.print_scale_y = _.get(settings, 'print_scale_y', 0);
				}
				if (_.get(settings, 'print_crop_x', 0)) {
					print_settings.print_crop_x = _.get(settings, 'print_crop_x', 0);
				}
				if (_.get(settings, 'print_crop_y', 0)) {
					print_settings.print_crop_y = _.get(settings, 'print_crop_y', 0);
				}
				if (_.get(settings, 'print_crop_width', 0)) {
					print_settings.print_crop_width = _.get(settings, 'print_crop_width', 0);
				}
				if (_.get(settings, 'print_crop_height', 0)) {
					print_settings.print_crop_height = _.get(settings, 'print_crop_height', 0);
				}

				return {
					type: _.get(print, 'type', ''),
					name: _.get(print, 'name', ''),
					print_fit: !!_.get(print, 'print_fit', true),
					print_x: _.get(print, 'print_x') ? _.get(print, 'print_x', 0) : 0,
					print_y: _.get(print, 'print_y') ? _.get(print, 'print_y', 0) : 0,
					print_width: _.get(print, 'print_width') ? _.get(print, 'print_width', 0) : 0,
					print_height: _.get(print, 'print_height') ? _.get(print, 'print_height', 0) : 0,
					print_rotate: _.get(print, 'print_rotate') ? _.get(print, 'print_rotate', 0) : 0,
					print_knew_x: _.get(print, 'print_knew_x') ? _.get(print, 'print_knew_x', 0) : 0,
					print_knew_y: _.get(print, 'print_knew_y') ? _.get(print, 'print_knew_y', 0) : 0,
					settings: print_settings,
					image: {
						url: _.get(image, 'url', ''),
						path: _.get(image, 'path', ''),
						filename: _.get(image, 'filename', ''),
						mimetype: _.get(image, 'mimetype', ''),
						width: _.get(image, 'width', 0),
						height: _.get(image, 'height', 0),
						size: _.get(image, 'size', 0),
						image_sizes: image_sizes
							? image_sizes
									.filter((size) => size.url)
									.map((size) => {
										return {
											name: _.get(size, 'name', ''),
											url: _.get(size, 'url', ''),
											width: _.get(size, 'width', 0),
											height: _.get(size, 'height', 0),
										};
									})
							: [],
					},
				};
			});
		}
	}
	params.images = images;

	let prints = _.get(args, 'prints', []);

	if (prints && prints.length > 0) {
		// prints = prints.filter( (print) => print.type )
		prints = prints.map((print) => {
			return {
				name: String(_.get(print, 'name', '')),
				prints: _.get(print, 'prints', false)
					? _.get(print, 'prints', []).map((print_side) => {
							let image = _.get(print_side, 'image');
							let image_sizes = _.get(image, 'image_sizes', false);
							return {
								type: _.get(print_side, 'type', ''),
								image: {
									url: _.get(image, 'url', ''),
									path: _.get(image, 'path', ''),
									filename: _.get(image, 'filename', ''),
									mimetype: _.get(image, 'mimetype', ''),
									width: _.get(image, 'width', 0),
									height: _.get(image, 'height', 0),
									size: _.get(image, 'size', 0),
									image_sizes: image_sizes
										? image_sizes
												.filter((size) => size.url)
												.map((size) => {
													return {
														name: _.get(size, 'name', ''),
														url: _.get(size, 'url', ''),
														width: _.get(size, 'width', 0),
														height: _.get(size, 'height', 0),
													};
												})
										: [],
								},
							};
					  })
					: [],
			};
		});
	}
	params.prints = prints;
	let tags = _.get(args, 'tags', []);
	let new_tags = _.get(args, 'new_tags', []);
	new_tags = [...new_tags].filter((tag) => tag);
	if (new_tags && new_tags.length > 0) {
		params.new_tags = new_tags;
	}
	params.tags = tags ? tags.map((tag) => tag._id) : [];

	let query = '';
	if (_.get(args, '_id', '')) {
		params._id = _.get(args, '_id', '');
		query = new Query('updateMockupDesign', params).find(responseField);
	} else {
		query = new Query('createMockupDesign', params).find(responseField);
	}
	return `mutation {${query}}`;
};

export const trashMockupDesignQuery = (args) => {
	const delete_ids = _.get(args, 'delete_ids', []).map((d) => {
		return d.trim();
	});
	const query = new Query('trashMockupDesign', {
		ids: delete_ids,
	}).find(responseField);
	return `mutation {${query}}`;
};

export const mockupCounterQuery = (args) => {
	const query = new Query('mockupCounter', {
		name: _.get(args, 'name', ''),
		start: _.get(args, 'start', ''),
		end: _.get(args, 'end', ''),
		tag: _.get(args, 'tag', ''),
	}).find(['all', 'published', 'draft', 'trashed']);
	return `query {${query}}`;
};
