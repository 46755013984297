/* eslint-disable */
export default [
    {
        "name": "white",
        "color": "#FFFFFF"
    },
    {
        "name": "fuchsia",
        "color": "#F1387F"
    },
    {
        "name": "turquoise",
        "color": "#1289AF"
    },
    {
        "name": "denim",
        "color": "#687486"
    },
    {
        "name": "safety green",
        "color": "#E0F902"
    },
    {
        "name": "aquatic blue",
        "color": "#84C7E9"
    },
    {
        "name": "kiwi",
        "color": "#72E86F"
    },
    {
        "name": "orange",
        "color": "#F54905"
    },
    {
        "name": "brown",
        "color": "#432411"
    },
    {
        "name": "purple",
        "color": "#4C2075"
    },
    {
        "name": "red",
        "color": "#D41C28"
    },
    {
        "name": "heather black",
        "color": "#3A3A3A"
    },
    {
        "name": "heather gray",
        "color": "#CBCBCB"
    },
    {
        "name": "royal blue",
        "color": "#48599B"
    },
    {
        "name": "bright green",
        "color": "#34C64B"
    },
    {
        "name": "dark red",
        "color": "#A71D1F"
    },
    {
        "name": "light heather gray",
        "color": "#DFDFDF"
    },
    {
        "name": "khaki",
        "color": "#B9B87F"
    },
    {
        "name": "burgundy",
        "color": "#750033"
    },
    {
        "name": "black",
        "color": "#000000"
    },
    {
        "name": "navy",
        "color": "#00224C"
    },
    {
        "name": "yellow",
        "color": "#F7EF00"
    },
    {
        "name": "charcoal",
        "color": "#757575"
    },
    {
        "name": "powder blue",
        "color": "#BAD0E4"
    },
    {
        "name": "forest green",
        "color": "#0E6315"
    }
]
