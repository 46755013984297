const initialState = {
  files: [],
  messages: [],
  routeParam: null,
  counter: {},
  page: 1,
  perpage: 20,
  total: 1,
  errors: [],
  tmpFiles: []
}

const todo = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FILES":
      return { ...state, files: action.files, routeParam: action.routeParams }
    case "ADD_FILES":
      return { ...state, files: [ ...state.files, ...action.files ] }

    case "REMOVE_TMP_FILE":
      var { tmpFiles } = state;
      tmpFiles = [ ...tmpFiles ].filter( (f) => f !== action.file )
      return { ...state, tmpFiles: [ ...tmpFiles ] }

    case "UPDATE_FILE":
      return { ...state }

    case "ADD_FILE":
      return { ...state }

    case "CHANGE_FILTER":
      state.routeParam = action.filter
      return { ...state }

    case "SET_FILE_COUNTER":
      state.counter = action.counter
      return { ...state }

    case "SET_ERROR_MESSAGES":
      state.errors = action.errors
      return { ...state }

    case "SET_UPLOADING_FILES":
      state.tmpFiles = [ ...action.files ]
      return { ...state }

    case "SET_TOTAL":
      state.total = action.total
      return { ...state }

    default:
      return state
  }
}

export default todo
