export const config = (state = { account_plan: {}, hub_plan: {} }, action) => {
  switch (action.type) {
    case "APP_CONFIG": {
      let hubConfig = {}
      try {
        hubConfig = JSON.parse(action.payload?.hub_config)
      } catch (error) {
        
      }
      return {
        ...state,
        ...action.payload,
        hub_config: hubConfig
      }
    }
    default: {
      return state
    }
  }
}
