import _ from 'lodash';
const Query = require('graphql-query-builder');

export const responseField = ['_id', 'id', 'name', 'image', 'description', 'min_price', 'max_price'];

export const responseFieldCategorySpecifics = [
	'name',
	'type',
	'value',
	'helper',
	'mode',
	'key',
	'placeholder',
	'default_value',
	'disabled',
];

export const responseFieldCategory = ['id', 'name', 'level', 'parent'];

export const responseFieldCategorySearch = [
	'label',
	{
		categories: responseFieldCategory,
	},
];

export const responseFieldPreset = [
	'_id',
	'id',
	'name',
	'raw_name',
	'image',
	'description',
	'provider',
	{
		prety_attributes: [
			'attribute_type',
			'plf_attribute_name',
			{
				options: ['ffm_value', 'plf_value', 'plf_price', 'hex', 'default'],
			},
		],
	},
	{
		attribute_specifics: [
			'sku',
			'sale_price',
			'base_price',
			'base_price_invoice',
			{
				name_value: ['name', 'type', 'value'],
			},
			'fix_profit',
			'fixed_profit',
		],
	},
	{
		fulfil_attributes: [
			'attribute_type',
			'options'
		],
	},
	{
		specifics: ['key', 'value', 'required'],
	},
	'fix_profit',
	'fixed_profit',
	'tax_fee_fix',
	'tax_fee',
	'min_price',
	'max_price',
	'category',
	'size_chart',
	'shipping_preset',
	{
		platform_category: [
			'type',
			{
				category_selected: ['id', 'level', 'name', 'parent'],
			},
		],
	},
	'shipping_cost',
	'shipping_additional_cost',
	'international_shipping_cost',
	'international_shipping_additional_cost',
	'provider_id',
	'print_area_id',
	{
		platform_specifics: [
			'platform',
			{
				specifics: ['key', 'value', 'required'],
			},
		],
	},
	{
		print_areas: ['position', 'print'],
	},
	'type',
	'print_template',
	'weight',
	'package_width',
	'package_height',
	'package_length',
	'package_dimension_unit',
	{
		extend_images: ['src'],
	},
	'price_addition',
	'price',
	'favorite',
	'mockup_count',
	'tags',
	'collections',
];

export const providerProductTypeQuery = (args) => {
	const query = new Query('providerProductType', {
		provider: _.get(args, 'provider', ''),
		id: String(_.get(args, 'id', '')),
	}).find(responseField);
	return `query {${query}}`;
};

export const productPresetQuery = (args) => {
	const query = new Query('productPreset', {
		_id: _.get(args, '_id', ''),
		name: _.get(args, 'name', ''),
		provider: _.get(args, 'provider', ''),
		...(() => {
			return _.get(args, 'withoutCustom', '')
				? {
						withoutCustom: true,
				  }
				: {};
		})(),
		category: _.get(args, 'category', ''),
		favorite: Boolean(_.get(args, 'favorite')),
	}).find(responseFieldPreset);
	return `query {${query}}`;
};

export const productPresetCountQuery = (args) => {
	const query = new Query('productPresetCount', {
		_id: _.get(args, '_id', ''),
		name: _.get(args, 'name', ''),
		...(() => {
			return _.get(args, 'withoutCustom', '')
				? {
						withoutCustom: true,
				  }
				: {};
		})(),
	}).find([
		'all',
		'apparel',
		'alloverprint',
		'giftaccessories',
		'homedecorations',
		'facemask',
		'canvasposter',
		'jewelry',
	]);
	return `query {${query}}`;
};

export const productPresetCountProviderQuery = (args) => {
	const query = new Query('productPresetCountProvider', {
		_id: _.get(args, '_id', ''),
		name: _.get(args, 'name', ''),
		...(() => {
			return _.get(args, 'withoutCustom', '')
				? {
						withoutCustom: true,
				  }
				: {};
		})(),
	}).find([
		'all',
		'customcat',
		'lionnix',
		'merchize',
		'geargag',
		'scalable',
		'private',
		'printify',
		'dreamship',
		'gearment',
		'onos',
	]);
	return `query {${query}}`;
};

export const productPresetCreateQuery = (args) => {
	const query = new Query('productPresetCreate', {
		provider: _.get(args, 'provider', ''),
		product_type_id: _.get(args, 'product_type', ''),
		provider_id: _.get(args, 'provider_id', ''),
		print_area_id: _.get(args, 'print_area_id', ''),
	}).find(responseFieldPreset);
	return `mutation {${query}}`;
};

export const productPresetCreateByJsonQuery = (args) => {
	const query = new Query('productPresetCreateByJson', {
		data: _.get(args, 'data', ''),
	}).find(responseFieldPreset);
	return `mutation {${query}}`;
};

export const productPresetUpdateQuery = (args) => {
	const query = new Query('productPresetUpdate', {
		_id: _.get(args, '_id', ''),
		favorite: _.get(args, 'favorite', false),
	}).find(responseFieldPreset);
	return `mutation {${query}}`;
};

export const productPresetPasteQuery = (args) => {
	const params = {
		data: _.get(args, 'data', ''),
	};
	const query = new Query('productPresetPaste', params).find(responseFieldPreset);
	return `mutation {${query}}`;
};

export const productPresetRemoveQuery = (args) => {
	const query = new Query('productPresetRemove', {
		_id: _.get(args, '_id', ''),
	}).find(['_id']);
	return `mutation {${query}}`;
};

export const platformProductCategoryQuery = (args) => {
	const query = new Query('platformProductCategory', {
		platform: _.get(args, 'platform', ''),
		level: _.get(args, 'level', ''),
		parent: _.get(args, 'parent', ''),
	}).find(responseFieldCategory);
	return `query {${query}}`;
};

export const platformProductCategorySearchQuery = (args) => {
	const query = new Query('platformProductCategorySearch', {
		platform: _.get(args, 'platform', ''),
		search: _.get(args, 'search', ''),
	}).find(responseFieldCategorySearch);
	return `query {${query}}`;
};

export const platformProductCategorySpecificsQuery = (args) => {
	const query = new Query('platformProductSpecifics', {
		platform: _.get(args, 'platform', ''),
		id: _.get(args, 'id', ''),
	}).find(responseFieldCategorySpecifics);
	return `query {${query}}`;
};

export const providerProductTypeProviderQuery = (args) => {
	const query = new Query('providerProductTypeProvider', {
		provider: _.get(args, 'provider', ''),
		product_type_id: _.get(args, 'product_type', ''),
	}).find(['id', 'title']);
	return `query {${query}}`;
};

export const providerProductTypePrintAreaQuery = (args) => {
	const query = new Query('providerProductTypePrintArea', {
		provider: _.get(args, 'provider', ''),
		product_type_id: _.get(args, 'product_type', ''),
	}).find(['id', 'title']);
	return `query {${query}}`;
};

export const providerProductTypeProviderInfoQuery = (args) => {
	const query = new Query('providerProductType', {
		provider: _.get(args, 'provider', ''),
		id: String(_.get(args, 'id', '')),
	}).find([
		'id',
		{
			prety_attributes: [
				'attribute_type',
				'plf_attribute_name',
				{
					options: [
						'ffm_value',
						'plf_value',
						// "plf_price",
						'hex',
						'default',
					],
				},
			],
		},
		{
			attribute_specifics: [
				'sku',
				'sale_price',
				'base_price',
				{
					name_value: ['name', 'type', 'value'],
				},
				// "fix_profit",
				// "fixed_profit"
			],
		},
	]);
	return `query {${query}}`;
};

const productMockupTemplateResponse = [
	'_id',
	'name',
	'provider',
	'product_id',
	{
		product: responseFieldPreset,
	},
	'sku',
	'id',
	'mockup_type',
	'src',
	{
		sides: [
			'id',
			'name',
			{
				image: ['url', 'width', 'height'],
			},
			{
				document: [
					'width',
					'height',
					{
						settings: ['name', 'value'],
					},
				],
			},
			{
				print_area: ['x', 'y', 'width', 'height', 'scaleX', 'scaleY', 'rotation', 'knewX', 'knewY'],
			},
			{
				layers: [
					'id',
					'type',
					'name',
					{
						settings: ['name', 'value'],
					},
				],
			},
		],
	},
	'model',
	'created_at',
	'updated_at',
];

// catalog mockup templates
export const productMockupPresetsQuery = (args) => {
	let params = {};
	if (_.get(args, 'product_id')) {
		params.product_id = _.get(args, 'product_id');
	}
	if (_.get(args, 'preset_id')) {
		params.preset_id = _.get(args, 'preset_id');
	}
	if (_.get(args, 'status')) {
		params.status = _.get(args, 'status');
	}
	if (_.get(args, '_id')) {
		params._id = _.get(args, '_id');
	}
	if (_.get(args, 'id')) {
		params.id = _.get(args, 'id');
	}
	if (_.get(args, 'sku')) {
		params.sku = _.get(args, 'sku');
	}
	if (_.get(args, 'name')) {
		params.name = _.get(args, 'name');
	}
	if (_.get(args, 'provider')) {
		params.provider = _.get(args, 'provider');
	}
	const query = new Query('productMockupPresets', params).find([...productMockupTemplateResponse, 'type']);
	return `query {${query}}`;
};
