export const loadSuggestions = () => {
	return (dispatch) => {
		dispatch({
			type: 'MAYBE_UPDATE_SUGGESTIONS',
			suggestions: [],
		});
	};
};

export const updateStarred = (object) => {
	return (dispatch) => {
		dispatch({
			type: 'UPDATE_STARRED',
			object,
		});
		dispatch(loadSuggestions());
	};
};
