import Logo from './../assets/img/logo/logo.png'

/* eslint-disable */
export default {
    logo: {
        width: 177,
        topBarSource: Logo,
        // url: 'https://seller.onospod.com',
        accessibilityLabel: 'PodOrders.io',
    },
    colorScheme: 'inverse',
    colors: {
        background: 'rgba(76,29,149,var(1))',
        surface: '#111213',//91d5ff
        surfaceHighlight: '#91d5ff',
        onSurface: '#111213',
        interactive: '#2e72d2',
        secondary: '#111213',
        primary: '#6371c7',// '#8937df',// '#3f4eae',//'#c30f6a',// '#34b24d',//'#3b5998',
        critical: '#d82c0d',
        warning: '#ffc453',
        highlight: '#5bcdda',
        success: '#16a34a',// '#49a25d',// '#008060',
        decorative: '#ffc96b',
    },
}
