import _ from "lodash"
import { api } from "./../../../client"
import { fontsQuery } from "./../../../@query/font.query"

export const setProvider = (provider = '') => {
    return async dispatch => {
        dispatch({
            type: "SET_PROVIDER",
            payload: {
                provider
            }
        })
        dispatch(initFonts({ name: '' }))
    }
}

export const setFetching = (loading = false) => {
    return async dispatch => {
        dispatch({
            type: "LOADING",
            payload: {
                loading
            }
        })
    }
}

export const setEditorFontIds = (ids = []) => {
    return async (dispatch, getState) => {
        // const state = _.get(getState(), ['editor', 'fontManager'], [])

        dispatch({
            type: "LOADING",
            payload: {
                loading: true
            }
        })
        api( fontsQuery( { ids } ) )
        .finally(() => {
            dispatch({
                type: "LOADING",
                payload: {
                    loading: false
                }
            })
        })
        .then((res) => {
            let fonts = _.get(res, ['data', 'data', 'fonts'], [])
            dispatch(setFontsDefault(fonts))
        })
    }
}

export const initFonts = ({ name = '', page = 1 }) => {
    return async (dispatch, getState) => {
        const state = _.get(getState(), ['editor', 'fontManager'], [])
        // const default_fonts = _.get(state, 'default_fonts', [])
        dispatch({
            type: "LOADING",
            payload: {
                loading: true
            }
        })
        api( fontsQuery( { name, provider: _.get(state, 'provider', ''), ids: _.get(state, 'ids', []) } ), { page } )
        .then((res) => {
            let fonts = _.get(res, ['data', 'data', 'fonts'], [])
            let total = _.get(res, ['headers', 'x-total'], 0)
            dispatch({
                type: "INIT_FONTS",
                payload: {
                    items: fonts,
                    page,
                    total
                }
            })
            dispatch(setFontsDefault(fonts))
        })
        .finally(() => {
            dispatch({
                type: "LOADING",
                payload: {
                    loading: false
                }
            })
        })
    }
}

export const fetchFonts = () => {
    return async (dispatch, getState) => {
        const state = _.get(getState(), ['editor', 'fontManager'], [])
        const provider = _.get(state, 'provider')
        dispatch({
            type: "LOADING",
            payload: {
                loading: true
            }
        })
        api( fontsQuery( { name: _.get(state, [provider, 'name']), provider: _.get(state, 'provider', '') } ), { page: _.get(state, [provider, 'page']) } )
        .then((res) => {
            let fonts = _.get(res, ['data', 'data', 'fonts'], [])
            let total = parseInt(_.get(res, ['headers', 'x-total'], 0))
            dispatch({
                type: "SET_FONTS",
                payload: {
                    items: fonts,
                    total
                }
            })
            dispatch(setFontsDefault(fonts))
        })
        .finally(() => {
            dispatch({
                type: "LOADING",
                payload: {
                    loading: false
                }
            })
        })
    }
}

export const setFontsDefault = (fonts) => {
    return async(dispatch) => {
        return dispatch({
            type: 'SET_DEFAULT_FONTS',
            payload: {
                default_fonts: fonts
            }
        })
    }
}

export const setSearch = (name) => {
    return async(dispatch) => {

        dispatch({
            type: 'SET_SEARCH_FONT',
            payload: {
                name
            }
        })

        dispatch({
            type: 'SET_FONT_PAGE',
            payload: {
                page: 1
            }
        })

        dispatch(fetchFonts())
    }
}
