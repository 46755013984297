/* eslint-disable no-useless-escape */
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { history } from './history';
import { getCookie } from './redux/actions/cookie/cookie';
import { store } from './redux/storeConfig/store';

const _token = getCookie('_token');

function getDomainInfo(cHub = true) {
	const originURL = window.location.origin;
	const protocol = window.location.protocol;
	const fullDomain = window.location.hostname;
	const subDomain = _.trim(_.head(fullDomain.match(/(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i)), '.');
	let rootDomain = _.trim(_.trimStart(fullDomain, subDomain), '.');
	// if (window.location.host === "localhost:3000") {
	// 	rootDomain = "salehunter.io"
	// }
	const isLocalhost = originURL.match(/https?:\/\/localhost/gi);
	const isFakeLogin = getCookie('cHub');
	let graphqlLink = null;
	let restURL = null;
	if (isLocalhost) {
		graphqlLink = protocol + '//' + process.env.REACT_APP_GRAPHQL_LINK;
		restURL = protocol + '//' + process.env.REACT_APP_GRAPHQL_LINK;
	} else if (fullDomain.match(/(?: http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i)) {
		graphqlLink = protocol + '//api-' + fullDomain;
		restURL = protocol + '//api-' + fullDomain;
	} else {
		graphqlLink = protocol + '//api.' + fullDomain;
		restURL = protocol + '//api.' + fullDomain;
	}
	if (isFakeLogin && !isLocalhost && cHub) {
		graphqlLink = protocol + '//api-' + isFakeLogin + '.' + rootDomain;
		restURL = protocol + '//api-' + isFakeLogin + '.' + rootDomain;
	}

	// restURL = 'https://friendly-mouse-90.loca.lt'
	// graphqlLink = 'https://api-lcn-test.podorders.store'
	return {
		originURL: originURL,
		subDomain: subDomain,
		rootDomain: rootDomain,
		restURL: restURL,
		graphqlLink: graphqlLink,
		isLocalhost: isLocalhost,
		isFakeLogin: isFakeLogin,
		isSaleHunter: rootDomain === 'salehunter.io',
		isGeekPod: rootDomain === 'geekpod.io',
		taskSocketIo: process.env.REACT_APP_TASK_SOCKET_LINK, //'wss://task.onospod.com'//
	};
}
let GRAPHQL_LINK = getDomainInfo().graphqlLink;

if (getCookie('cHub') && window.location.hostname !== 'localhost') {
	axios.defaults.headers.common['FakeLogin'] = true;
}
if (_token) {
	axios.defaults.headers.common['Authorization'] = `Bearer ${_token}`;
}

const api_fetch = (url = '') => {
	if (url === 'auth') {
		GRAPHQL_LINK = getDomainInfo(false).graphqlLink;
	}
	let headers = {
		'X-Requested-With': 'XMLHttpRequest',
	};
	const token = getCookie('_token');
	if (token) headers = Object.assign({ Authorization: `Bearer ${token}` }, headers);
	const api_axios = axios.create({
		baseURL: GRAPHQL_LINK + '/' + url,
		timeout: 90000,
		headers: headers,
	});
	api_axios.interceptors.response.use(function (res) {
		// Do something with response data
		// Nếu có res.errors thì redirect về hết page 404
		if (res.data.errors && res.data.errors.length && res.data.errors[0].msg === 'Permission denied!') {
			// window.location = '/page404.html';
			// history.push('/permission-denied')
			// disable redirect permission-denied when request fired, because maybe we have many requests inside one page
			// const currentQuery = `${history.location.pathname}${history.location.search}`
			// history.push( currentQuery, { denied: true } )
		}
		return res;
	}, handleError);
	return api_axios;
};

const api = (query, paginate, api_url = '') => {
	const token = getCookie('_token');
	let headers = {
		'X-Requested-With': 'XMLHttpRequest',
		'X-Page': _.get(paginate, 'page', 1),
		'X-Per-Page': _.get(paginate, 'perpage', 20),
		'x-time': moment().format('DD-MM-YYYY HH:mm:ss.SSSS'),
	};

	if (token) headers = Object.assign({ Authorization: `Bearer ${token}` }, headers);

	const api_axios = axios.create({
		baseURL: GRAPHQL_LINK + '/graphql',
		timeout: 90000,
		headers: headers,
	});
	// history.push( '/permission-denied', { previousLocation: currentQuery, modal: 1 } )
	api_axios.interceptors.response.use(function (res) {
		if (
			_.get(res, ['data', 'data', 'debug_info', 'debug_now']) &&
			String(_.get(res, ['data', 'data', 'debug_info', 'debug_now'])) !==
				String(_.get(res, ['config', 'headers', 'x-time']))
		) {
			console.log(__filename, String(_.get(res, ['config', 'headers', 'x-time'])));
			console.error('======================= Error #1 =======================');
			console.log(__filename, _.get(res, ['data', 'data']));
		}
		if (
			_.get(res, ['data', 'data', 'debug_info', 'debug_vhost']) &&
			String(_.get(res, ['data', 'data', 'debug_info', 'debug_vhost'])) !==
				String(_.head(_.split(window.location.host, ':')))
		) {
			console.log(__filename, String(_.get(res, ['data', 'data', 'debug_info', 'debug_vhost'])));
			console.error('======================= Error #2 =======================');
			console.log(__filename, _.get(res, ['data', 'data']));
		}
		// Do something with response data
		// Nếu có res.errors thì redirect về hết page 404
		if (res.data.errors && res.data.errors.length && res.data.errors[0].msg === 'Permission denied!') {
			// window.location = '/page404.html';
			// history.push('/permission-denied')
			// disable redirect permission-denied when request fired, because maybe we have many requests inside one page
			// const currentQuery = `${history.location.pathname}${history.location.search}`
			// history.push( currentQuery, { denied: true } )
		}
		return _.cloneDeep(res);
	}, handleError);
	return api_axios.post(api_url, {
		query,
	});
};

function handleError(error) {
	if (error.message === 'Network Error') {
		// The user doesn't have internet
		store.dispatch({ type: 'MAINTENANCE' });
		return Promise.reject(error);
	}
	if (!error.response) {
		return Promise.reject(error);
	}
	const responseData = _.get(error, ['response', 'data', 'errors'], []);
	const checkWarningIp = _.find(responseData, (e) => e.message && e.message.includes('Warning IP'));
	const checkDocker = _.find(responseData, (e) => e.message && e.message.includes('Authorization 404'));
	switch (error.response.status) {
		case 400:
			if (checkWarningIp) {
				store.dispatch({ type: 'WARNING_IP' });
			} else if (checkDocker) {
				store.dispatch({ type: 'DOCKER_NOTFOUND' });
			} else {
				store.dispatch({ type: 'WRONG_TOKEN' });
			}
			break;
		case 401:
			// window.location.reload()
			break;
		case 404:
			if (!getDomainInfo().isLocalhost) {
				history.push('/404');
			}
			// Show 404 page
			break;
		case 405:
			// Method Not Allowed
			break;
		case 500:
			store.dispatch({ type: 'MAINTENANCE' });
			// history.push("/500")
			// Server Error redirect to 500
			break;
		case 503:
			store.dispatch({ type: 'MAINTENANCE' });
			// history.push("/maintenance")
			// Server Error redirect to 500
			break;
		default:
			// Unknown Error
			break;
	}
	return Promise.reject(error);
}

const endpointApi = GRAPHQL_LINK + '/graphql';

const restApi = async (method = 'POST', api_path = '', headers = {}, params = {}) => {
	method = method.toLowerCase();
	let token = getCookie('_token');

	if (window.shopifyApp) {
		// token = await getSessionToken(window.shopifyApp) // requires an App Bridge instance
	}
	if (token && !headers.Authorization) {
		headers.Authorization = `Bearer ${token}`;
	}
	headers = {
		...headers,
		withCredentials: true,
		'X-Requested-With': 'XMLHttpRequest',
	};
	let options = {
		url: api_path,
		baseURL: _.get(getDomainInfo(), 'restURL'),
		method: method,
		timeout: 30000,
		responseType: 'JSON',
		headers,
	};

	if (method === 'get') {
		options.params = params;
	} else {
		options.body = params;
	}
	return axios.request(options);
};
const api_mutation = async (mutation, paginate, api_url = '', options = {}) => {
	let headers = {
		'X-Requested-With': 'XMLHttpRequest',
		'X-Page': _.get(paginate, 'page', 1),
		'X-Per-Page': _.get(paginate, 'perpage', 20),
	};

	const token = getCookie('_token');
	if (token) headers = Object.assign({ Authorization: `Bearer ${token}` }, headers);

	const api_axios = axios.create({
		baseURL: GRAPHQL_LINK + '/graphql',
		timeout: 90000,
		headers: headers,
	});
	// history.push( '/permission-denied', { previousLocation: currentQuery, modal: 1 } )
	api_axios.interceptors.response.use(function (res) {
		// Do something with response data
		// Nếu có res.errors thì redirect về hết page 404
		if (res.data.errors && res.data.errors.length && res.data.errors[0].msg === 'Permission denied!') {
			// window.location = '/page404.html';
			// history.push('/permission-denied')
			// disable redirect permission-denied when request fired, because maybe we have many requests inside one page
			// const currentQuery = `${history.location.pathname}${history.location.search}`
			// history.push( currentQuery, { denied: true } )
		}
		return res;
	}, handleError);

	return api_axios.post(api_url, mutation, options);
};

const clientQuery = (params, paginate, api_url = '') => {
	let headers = {
		'X-Requested-With': 'XMLHttpRequest',
		'X-Page': _.get(paginate, 'page', 1),
		'X-Per-Page': _.get(paginate, 'perpage', 20),
	};

	const token = getCookie('_token');
	if (token) headers = Object.assign({ Authorization: `Bearer ${token}` }, headers);

	const api_axios = axios.create({
		baseURL: GRAPHQL_LINK + '/graphql',
		timeout: 90000,
		headers: headers,
	});
	// history.push( '/permission-denied', { previousLocation: currentQuery, modal: 1 } )
	api_axios.interceptors.response.use(function (res) {
		// Do something with response data
		// Nếu có res.errors thì redirect về hết page 404
		if (res.data.errors && res.data.errors.length && res.data.errors[0].msg === 'Permission denied!') {
			// window.location = '/page404.html';
			// history.push('/permission-denied')
			// disable redirect permission-denied when request fired, because maybe we have many requests inside one page
			// const currentQuery = `${history.location.pathname}${history.location.search}`
			// history.push( currentQuery, { denied: true } )
		}
		return res;
	}, handleError);
	const { query, variables } = params;
	return api_axios.post(api_url, { query: _.get(query, ['loc', 'source', 'body']), variables });
};

export { api, api_fetch, endpointApi, GRAPHQL_LINK, getDomainInfo, restApi, api_mutation, clientQuery, handleError };
