import { combineReducers } from 'redux';
import app from './app/';
import customizer from './customizer/';
import auth from './auth/';
import navbar from './navbar/Index';
import task from './task';
import designCollection from './design-collection';
import file from './file';
import editor from './editor';
import background from './background';
import trend from './trend';

const rootReducer = combineReducers({
	app: app,
	customizer: customizer,
	auth: auth,
	navbar: navbar,
	taskApp: task,
	designCollection: designCollection,
	file,
	editor,
	background,
	trend,
});

export default rootReducer;
