import _ from 'lodash'
import uniqid from 'uniqid'
/*eslint-disable */
const initialState = {
    inited: false,
    provider: 'Custom',
    files: [],
    selecteds: [],
    loading: false,
    search: '',
    messages: [],
    routeParam: null,
    counter: {},
    page: 1,
    perpage: 32,
    total: 1,
    allowMultiple: false
}

const fileReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_FILES":
            var newState = {
                ...state,
                inited: true,
                files: action.files,
            }

            if ( _.get(action, 'page') ) {
                newState.page = _.get(action, 'page', 1)
            }
            if ( _.get(action, 'total') ) {
                newState.total = _.get(action, 'total', 1)
            }
            return { ...newState }
        break

        case 'SET_FILE_TAB':
            var tab = _.get(action, ['payload', 'tab'], false)
            return { ...state, provider: tab }
        break;

        case "LOADING":
            var loading = _.get(action, ['payload', 'loading'], false)
            return { ...state, loading }
        break;

        case "SET_MULTIPLE":
            return {
                ...state,
                allowMultiple: _.get(action, 'multiple', false)
            }
        break;

        case "SELECT_FILES":
            return {
                ...state,
                selecteds: _.get(action, 'files')
            }
        break;

        case "ADD_FILES":
            return {
                ...state,
                files: [ ...state.files, ...action.files ],
                page: _.get(action, 'page'),
            }

        case "SET_PAGE":
            return {
                ...state,
                page: _.get(action, 'page'),
            }

        case "SET_SEARCH_FILE":
            return {
                ...state,
                search: _.get(action, 'search', ''),
                page: 1
            }

        case 'ADD_UPLOAD_FILES':
            var items = _.get(action, ['payload', 'items'], [])
            return {
                ...state,
                files: [
                    ...items,
                    ..._.get(state, 'files'),
                ]
            }
        break;

        case 'UPDATE_FILE':
            // UPDATE AFTER UPLOADED
            var file = _.get(action, ['payload', 'file'])
            var id = _.get(file, 'id')
            var files = _.cloneDeep(_.get(state, 'files'))
            files = _.map(files, (item) => {
                if ( _.get(item, 'id') && _.get(item, 'id') === id ) {
                    return file
                }
                return item
            })
            return {
                ...state,
                files
            }
        break

        case 'REMOVE_FILE':
            // UPDATE AFTER UPLOADED
            var _id = _.get(action, ['payload', '_id'])
            var files = _.cloneDeep(_.get(state, 'files'))
            files = _.filter(files, (item) => _.get(item, '_id') && _.get(item, '_id') !== _id)
            return {
                ...state,
                files
            }
        break

        case 'FILE_FLASH_MESSAGE':
            var newState = _.cloneDeep(state)
            var messages = _.get(newState, 'messages')
            var message = _.get(action, ['payload', 'message'])
            var type = _.get(action, ['payload', 'type'])
            messages.push({
                id: uniqid(),
                type,
                message
            })
            return {
                ...newState,
                messages
            }
        break;

        case 'FILE_REMOVE_FLASH_MESSAGE':
            var newState = _.cloneDeep(state)
            var messages = _.get(newState, 'messages')
            var id = _.get(action, ['payload', 'id'])
            messages = _.filter(messages, (me) => me.id !== id)
            return {
                ...newState,
                messages
            }
        break;

        default:
          return state
    }

    return state
}

export default fileReducer
