const initialState = {
  tasks: [],
  messages: [],
  routeParam: null,
  counter: {},
  total: 0,
  page: 1,
  errors: []
}
/* eslint-disable */
const todo = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TASKS":
      return { ...state, tasks: action.tasks, routeParam: action.routeParams, total: action.total }
    case "ADD_TASKS":
      return { ...state, tasks: [ ...state.tasks, ...action.tasks ], page: action.page }
    case "UPDATE_TASKS":
      let todo = action.todo
      let updatedState = { ...state.tasks, [todo.id]: { ...todo } }
      return { ...state, tasks: updatedState }

    case "COMPLETE_TASK":
      var newTasks = [ ...state.tasks ]
      newTasks = [ ...newTasks ].map(i => {
        if ( i._id === action.id ) {
          i.status = action.value ? 'completed' : 'todo'
        }
        return i;
      })

      state.tasks = newTasks;
      return { ...state }

    case "UPDATE_LABEL":
      let taskToUpdate = state.tasks.find(i => i.id === action.id).tags
      if (!taskToUpdate.includes(action.label)) taskToUpdate.push(action.label)
      else taskToUpdate.splice(taskToUpdate.indexOf(action.label), 1)
      return { ...state }

    case "UPDATE_TASK":
      return { ...state }

    case "ADD_TASK":
      return { ...state }

    case "CHANGE_FILTER":
      state.routeParam = action.routeParams
      return { ...state }

    case "SET_CHAT_MESSAGES":
      state.messages = action.messages
      return { ...state }

    case "SET_TASK_COUNTER":
      state.counter = action.counter
      return { ...state }

    case "SET_ERROR_MESSAGES":
      state.errors = action.errors
      return { ...state }

    case "SET_DESIGN_APPLIED":
      var newTasks = [ ...state.tasks ]
      newTasks = [ ...newTasks ].map(i => {
        if ( i._id === action.id ) {
          i.applied = action.applied
        }
        return i;
      })

      state.tasks = newTasks;
      return { ...state }

    default:
      return state
  }

  return state
}

export default todo
