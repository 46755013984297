const initialState = {
	rules: [],
};

const trendReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_RULES': {
			return { ...state, rules: action.payload };
		}
		default: {
			return state;
		}
	}
};

export default trendReducer;
