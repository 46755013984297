// import { combineReducers } from "redux"
import _ from "lodash"
import uniqid from 'uniqid'
// import { v4 as uuidv4 } from 'uuid'
import { arrayMove } from 'react-sortable-hoc';
import { ucFirst } from './../../../utils/string'
import DEFAULT_COLORS from './colors'
import { store } from "./../../storeConfig/store"
import { saveProductMockup } from './../../actions/editor'
import { UploadMajor } from '@shopify/polaris-icons'

/* eslint-disable */
const toFloat = (number) => {
    return parseFloat(parseFloat(number).toFixed(2))
}

const DEFAULT_PRINT_AREA_2D = {}
const DEFAULT_DOCUMENT = {
    mockup_type: '2D',//2D
    width: 2000,
    height: 2000,
    settings: {
        color: '',
        backgroundColor: '',
        backgroundImage: ''
    }
}

const DEFAULT_SIDES = [
    {
        id: 'front',
        name: '3D Front',
        image: {
            url: 'https://dt5ymcp9sajok.cloudfront.net/seller_pod/1637137413_lvIh4W.png',
            width: 530,
            height: 630
        },
        layers: [],
        print_area: { ...DEFAULT_PRINT_AREA_2D },
        document: { ...DEFAULT_DOCUMENT }
    },
    {
        id: 'back',
        name: 'Back',
        image: {
            url: 'https://dt5ymcp9sajok.cloudfront.net/seller_pod/1637137446_XGNQF9.png',
            width: 530,
            height: 630
        },
        layers: [],
        print_area: { ...DEFAULT_PRINT_AREA_2D },
        document: { ...DEFAULT_DOCUMENT }
    }
]

const DEFAULT_MOCKUP_TEMPLATE = {
    sides: [ ...DEFAULT_SIDES ]
}

const DEFAULT_SAVE_STEPS = [
    {
        id: 'generate_mockup',
        text: 'Generate mockup\'s images.',
    },
    {
        id: 'save_mockup',
        text: 'Save mockup.',
    },
    {
        id: 'save_product',
        text: 'Save product.',
    },
    {
        id: 'done',
        text: 'Release.',
        onAction: () => {
            return store.dispatch(saveProductMockup())
        }
    }
]

const DEFAULT_STATES = {
    inited: false,
    sides: false,
    width: false, // editor width
    height: false, // editor height
    offsetX: false,
    offsetY: false,
    loaded: false,
    loading: false, // save item
    openSave: false,
    saving: false,
    save_step: false,
    save_steps: [ ...DEFAULT_SAVE_STEPS ],
    dragging: false,
    sideId: '',
    menuId: 'side',//'product',
    printAreaEditable: false,
    panelId: 'document',
    layerId: false,
    layerContext: false,
    stores: false,
    material: false,
    fontLoaded: false,
    messages: [],
    scale: 1,
    xsMode: false,
    editPreset: false,
    prevState: false,
    nextState: false,
    product: null,
    preset: { ...DEFAULT_MOCKUP_TEMPLATE },
    mockup: {},
    step: 'design',
    steps: [
        {
            id: 'design',
            text: 'Design',
        },
        {
            id: 'details',
            text: 'Details & Pricing',
        },
        {
            id: 'publish',
            text: 'Publish',
            icon: UploadMajor,
            onAction: () => {
                return store.dispatch(saveProductMockup())
            }
        }
    ],
    defaultColors: [ ...DEFAULT_COLORS ],
    // empty, will set when init product
    document: {},
    print_area: {},
    print_areas: [ // 3D
        {
            position: 'front',
            name: 'Front',
            print: '', // print image
            width: 1668,
            height: 1820,
            bottom: 1919,
            left: 174,
            right: 1842,
            top: 99
        },
        {
            position: 'left_sleeve',
            name: 'Left Sleeve',
            print: '', // print image
            width: 1668,
            height: 1820,
            bottom: 1919,
            left: 174,
            right: 1842,
            top: 99
        },
        {
            position: 'right_sleeve',
            name: 'Right Sleeve',
            print: '', // print image
            width: 1668,
            height: 1820,
            bottom: 1919,
            left: 174,
            right: 1842,
            top: 99
        },
        {
            position: 'neek',
            name: 'Neek',
            print: '', // print image
            width: 501,
            height: 127,
            bottom: 225,
            left: 755,
            right: 1256,
            top: 98
        },
    ]
}

const DEFAULT_LAYER = {
    id: '',
    name: '',
    type: '',
    settings: {
        visible: true
    }
}

const getCurrentSideIndex = (sides, sideId) => {
    return _.findIndex( sides, (side) => side.id === sideId )
}

const getCurrentSide = (sides, sideId) => {
    let sideIndex = _.findIndex( sides, (side) => side.id === sideId )
    return sideIndex !== -1 ? _.cloneDeep(_.get(sides, sideIndex)) : false
}

const getCurrentLayerIndex = (sides, sideId, layerId) => {
    let side = getCurrentSide(sides, sideId)
    let layers = _.get(side, 'layers', [])
    return _.findIndex(layers, (layer) => layer.id === layerId)
}

const getCurrentLayer = (sides, sideId, layerId) => {
    let side = getCurrentSide(sides, sideId)
    let layers = _.cloneDeep(_.get(side, 'layers', []))
    return _.find(layers, (layer) => layer.id === layerId)
}

const editor = ( state = { ...DEFAULT_STATES }, action ) => {
    switch (action.type) {
        case 'TOGGLE_LOADING':
            let loading = _.get(state, 'loading')
            return {
                ..._.cloneDeep(state),
                loading: ! loading
            }
        break;

        case 'TOGGLE_SAVE_MODE':
          let openSave = _.get(state, 'openSave', false)
            return {
                ..._.cloneDeep(state),
                openSave: ! openSave
            }
        break;

        case 'TOGGLE_SAVING':
          let saving = _.get(state, 'saving')
            return {
                ..._.cloneDeep(state),
                saving: ! saving
            }
        break;

        case 'SETUP_PRODUCT':
            var product = _.cloneDeep(_.get(action, ['payload', 'product']))
            var fonts = _.cloneDeep(_.get(action, ['payload', 'fonts']))
            var preset = _.cloneDeep(_.get(action, ['payload', 'preset']))
            var mockup = _.get(action, ['payload', 'mockup'], false) ? _.get(action, ['payload', 'mockup'], false) : false
            var editPreset = !! _.get(action, ['payload', 'editPreset'], false)
            if ( ! mockup ) {
                let { _id, id, sku, created_at, updated_at, ...mockupInfo }  = preset
                mockup = mockupInfo
            }
            if ( ! _.get(product, 'attribute_specifics_modify') && _.get(product, 'attribute_specifics') ) {
                product.attribute_specifics_modify = _.map(_.get(product, 'attribute_specifics'), (attribute) => {
                    attribute.selected = true
                    return attribute
                })
            }
            let defaultColors = []
            if ( _.get(product, 'prety_attributes') ) {
                let colour = _.find(_.get(product, 'prety_attributes'), (attribute) => _.toLower(attribute.attribute_type) === 'color')
                if ( colour && _.get(colour, 'options') && _.get(colour, 'options').length > 0 ) {
                    defaultColors = _.map(_.get(colour, 'options'), (option) => {
                        return {
                            name: _.get(option, 'ffm_value', ''),
                            color: _.get(option, 'hex', '') ? `#${_.get(option, 'hex', '')}` : ''
                        }
                    })
                }
            }

            var sides = _.get(mockup, 'sides')
            var sideActive = _.head(sides)
            var sideActiveId = _.get( sideActive, 'id' )
            var document = _.cloneDeep(_.get( sideActive, 'document' ))
            var newState = {
                ...state,
                defaultColors,
                sides,
                editPreset,
                menuId: editPreset ? 'side' : (defaultColors && defaultColors.length > 0 ? 'product' : 'text'),
                layers: _.cloneDeep(_.get(sideActive, 'layers', [])),
                sideId: sideActiveId,
                document,
                print_area: _.get(sideActive, 'print_area')
            }

    
            var xsMode = _.get(action, ['payload', 'xsMode'])
            if ( xsMode !== undefined ) {
                newState.xsMode = xsMode
                if ( xsMode ) {
                    newState.menuId = false
                } else {
                    newState.menuId = _.get(newState, 'editPreset') ? 'side' : (defaultColors && defaultColors.length > 0 ? 'product' : 'text')
                }
            }

            return {
                ...newState,
                inited: true,
                product,
                preset,
                mockup: _.cloneDeep(mockup)
            }
        break;

        case 'SETUP_PRODUCT_COLOR':
            var newState = _.cloneDeep(state)
            var sides = _.cloneDeep(_.get(newState, 'sides'))
            var color = _.get(action, ['payload', 'color'])
            var document = _.get(newState, 'document')
            var settings = _.get(document, 'settings', {})

            if ( color !== undefined ) {
                _.set(newState, ['mockup', 'sides'], _.map(_.get(newState, ['mockup', 'sides']), side => {
                    _.set(side, ['document', 'settings', 'color'], color)
                    return side
                }))
                if ( _.get(newState, 'editPreset') ) {
                    _.set(newState, ['preset', 'sides'], _.map(_.get(newState, ['preset', 'sides']), side => {
                        _.set(side, ['document', 'settings', 'color'], color)
                        return side
                    }))
                }
                _.set(newState, 'sides', _.map(_.get(newState, 'sides'), side => {
                    _.set(side, ['document', 'settings', 'color'], color)
                    return side
                }))
            }
            return newState
        break

        case 'SET_MOCKUP_PREVIEW':
            var newState = _.cloneDeep(state)
            var mockup = _.cloneDeep(_.get(newState, 'mockup'))
            return {
                ...newState,
                mockup: (() => {
                    return {
                        ...mockup,
                        src: _.get(action, ['payload', 'file', 'url'], '')
                    }
                })()
            }
        break;

        case 'SET_SIDE_MOCKUP_IMAGE':
            var newState = _.cloneDeep(state)
            var sides = _.get(newState, 'sides')
            var sideId = _.get(action, ['payload', 'id'])
            var image = _.get(action, ['payload', 'image'])

            sides = _.map(sides, (side) => {
                if ( _.get(side, 'id') === sideId ) {
                    side.mockup = image
                }
                return side
            })
            return {
                ...newState,
                sides
            }
        break;

        case 'SET_CURRENT_STAGE':
            var newState = _.cloneDeep(state)
            _.set(newState, 'currentStage', _.get(action, ['payload', 'stage']))
            return { ...newState }
        break

        case 'WINDOW_INIT':
            var product = _.get(action, ['payload', 'product'])
            var width = toFloat(_.get(action, ['payload', 'width']))
            var height = toFloat(_.get(action, ['payload', 'height']))
            var step = _.get(action, ['payload', 'step'])

            var newState = _.cloneDeep(state)
            var editPreset = _.get(newState, 'editPreset', false)
            if ( product !== undefined ) {
                newState.product = product
            }
            if ( width ) {
                newState.width = width
            }
            if ( height ) {
                newState.height = height
            }
            if ( step ) {
                newState.step = step
            }

            if ( width || height ) {
                let width = _.get(newState, 'width')
                let height = _.get(newState, 'height')
                var sides = _.cloneDeep(_.get(state, 'sides'))

                sides = _.map(sides, (side, index) => {
                    // let embedDoc = _.cloneDeep(_.get(side, 'document'))
                    let defaultSide = _.cloneDeep(_.get(newState, ['preset', 'sides', index]))
                    if ( ! editPreset ) {
                        defaultSide = _.cloneDeep(_.get(newState, ['mockup', 'sides', index]))
                    }
                    let embedDoc = _.get(defaultSide, 'document')
                    let embedWidth = _.get(embedDoc, 'width', 0)
                    let embedHeight = _.get(embedDoc, 'height', 0)
                    let originRatio = embedWidth / embedHeight
                    if ( isNaN(originRatio) ) {
                        originRatio = 1
                    }
                    // FIXED MAIN IMAGE WIDTH - HEIGHT
                    let FIXED_PERCENT = 100 / 100
                    let maxHeight = height * FIXED_PERCENT
                    let maxWidth = width * FIXED_PERCENT

                    var stageWidth = 0
                    var stageHeight = 0
                    stageHeight = maxHeight
                    stageWidth = stageHeight * originRatio
                    if ( stageWidth >= maxWidth ) {
                        stageWidth = maxWidth
                        stageHeight = stageWidth / originRatio
                    } else {
                        stageHeight = maxHeight
                        stageWidth = stageHeight * originRatio
                    }

                    stageWidth = toFloat(stageWidth)
                    stageHeight = toFloat(stageHeight)
                    var offsetX = toFloat((width - stageWidth * _.get(newState, 'scale')) / 2)
                    var offsetY = toFloat((height - stageHeight * _.get(newState, 'scale')) / 2)

                    // catalog print area config
                    let print_area = _.get(defaultSide, 'print_area')
                    if ( print_area !== undefined || print_area !== null ) {
                        let x = _.get(print_area, 'x') ? toFloat(_.get(print_area, 'x')) : undefined
                        let y = _.get(print_area, 'y') ? toFloat(_.get(print_area, 'y')) : undefined
                        let width = _.get(print_area, 'width') ? toFloat(_.get(print_area, 'width')) : undefined
                        let height = _.get(print_area, 'height') ? toFloat(_.get(print_area, 'height')) : undefined
                        // let scaleX = _.get(print_area, 'scaleX') ? toFloat(_.get(print_area, 'scaleX')) : 1
                        // let scaleY = _.get(print_area, 'scaleY') ? toFloat(_.get(print_area, 'scaleY')) : 1

                        if ( ! width || ! height || x === undefined || y === undefined ) {
                            let presetPrintArea = {
                                width: toFloat(embedWidth / 4),
                                height: toFloat(embedHeight / 4),
                                x: toFloat((embedWidth - toFloat(embedWidth / 4)) / 2),
                                y: toFloat((embedHeight - toFloat(embedHeight / 3)) / 2)
                            }
                            _.set(newState, ['preset', 'sides', index, 'print_area'], presetPrintArea)
                            _.set(newState, ['mockup', 'sides', index, 'print_area'], presetPrintArea)

                            // FAKE PRINT AREA
                            if ( ! width || ! height ) {
                                width = toFloat(stageWidth / 4)
                                height = toFloat(stageHeight / 3)
                            }

                            if ( x === undefined || y === undefined ) {
                                x = toFloat((stageWidth - width) / 2)
                                y = toFloat((stageHeight - height) / 2)
                            }
                            // END FAKE PRINT AREA
                            _.set(side, 'print_area', { x, y, width, height })
                        } else {
                            // RESIZE
                            let widthRatio = toFloat(width / embedWidth)
                            let heightRatio = toFloat(height / embedHeight)
                            let newWidth = toFloat(stageWidth * widthRatio)
                            let newHeight = toFloat(stageHeight * heightRatio)
                            let newX = toFloat((x * newWidth) / width)
                            let newY = toFloat((y * newHeight) / height)

                            _.set(side, 'print_area', {
                                x: newX,
                                y: newY,
                                width: newWidth,
                                height: newHeight,
                                ...(() => {
                                    return _.reduce(['rotation', 'scaleX', 'scaleY', 'knewX', 'knewY'], (settings, name) => {
                                        if ( _.get(side, ['print_area', name]) !== undefined && _.get(side, ['print_area', name]) !== false && _.get(side, ['print_area', name]) !== null ) {
                                            settings[name] = _.get(side, ['print_area', name])
                                        }
                                        return settings
                                    }, {})
                                })()
                            })
                        }
                    }

                    // CALCULATE NEW LAYERS OFFSETS
                    if ( ( width !== _.get(state, 'width') || height !== _.get(state, 'height') ) ) {
                        // START TEST
                        let embedDoc = _.get(side, 'document')
                        let embedWidth = _.get(embedDoc, 'width', 0)
                        let embedHeight = _.get(embedDoc, 'height', 0)
                        // END TEST
                        let layers = _.get(side, 'layers')
                        side.layers = _.map(_.cloneDeep(layers), (layer) => {
                            const {
                                settings: {
                                    width,
                                    height,
                                    x,
                                    y
                                }
                            } = layer

                            let newWidth = (width * stageWidth) / embedWidth
                            let newHeight = (height * stageHeight) / embedHeight
                            let newX = (x * newWidth) / width
                            let newY = (y * newHeight) / height

                            _.set(layer, ['settings', 'x'], toFloat(newX))
                            _.set(layer, ['settings', 'y'], toFloat(newY))
                            _.set(layer, ['settings', 'width'], toFloat(newWidth))
                            _.set(layer, ['settings', 'height'], toFloat(newHeight))

                            return layer
                        })
                    }

                    _.set(side, ['document', 'width'], stageWidth)
                    _.set(side, ['document', 'height'], stageHeight)

                    if ( _.get(side, 'id') === _.get(newState, 'sideId') ) {
                        _.set(newState, ['document', 'width'], stageWidth)
                        _.set(newState, ['document', 'height'], stageHeight)
                        _.set(newState, 'offsetX', offsetX)
                        _.set(newState, 'offsetY', offsetY)
                        _.set(newState, 'print_area', _.get(side, 'print_area'))
                    }
                    return side
                })

                _.set(newState, 'sides', sides)
            }
            return {
                ...newState
            }
        break;

        case 'WINDOW_DESTROY':
            return { ...DEFAULT_STATES }
        break;

        case 'WINDOW_LOADED':
            return { ..._.cloneDeep(state), loaded: !!action.payload.loaded }
        break;

        case 'SET_MOCKUP_PRESET_NAME':
            var newState = _.cloneDeep(state)
            var name = _.get(action, ['payload', 'name'], '')
            _.set(newState, ['preset', 'name'], name)

            return newState
        break;

        case 'UNDO':
            if ( _.get(state, 'prevState', false) ) {
                var newState = _.cloneDeep(_.get(state, 'prevState', false))
                return {
                    ...newState,
                    prevState: _.get(newState, 'prevState') ? _.cloneDeep(_.get(newState, 'prevState')) : false
                }
            }
            return { ...state }
        break;

        case 'REDO':
            if ( _.get(state, 'nextState', false) ) {
                var newState = _.cloneDeep(_.get(state, 'prevState', false))
                return {
                    ...newState,
                    nextState: _.cloneDeep(_.get(state, 'nextState', false)) ? _.cloneDeep(_.cloneDeep(_.get(state, 'nextState', false))) : false
                }
            }
            return { ...state }
        break;

        case 'ACTIVE_SIDE':
            var sides = _.cloneDeep(_.get(state, 'sides'))
            var activeSideId = _.get(action, ['payload', 'sideId'], '')
            var activeSide = getCurrentSide(sides, activeSideId)

            return {
                ...state,
                layers: _.cloneDeep(_.get(activeSide, 'layers', [])),
                loaded: false,
                // currentStage: null,
                sideId: activeSideId,
                layerId: '',
                print_area: _.get(activeSide, 'print_area', {}),
                document: _.get(activeSide, 'document'),
                panelId: 'document'
            }
        break;

        case 'ACTIVE_PANEL':
            var side = getCurrentSide(_.cloneDeep(_.get(state, ['mockup', 'sides'])), _.get(state, 'sideId', ''))
            var layerId = _.get( _.head(_.get(side, 'layers')), 'id' )
            return { ..._.cloneDeep(state), panelId: _.get(action, ['payload', 'panelId'], ''), layerId }
        break;

        case 'ACTIVE_EDITOR_PRINT':
            var newState = _.cloneDeep(state)
            return {
                ...newState,
                printAreaEditable: _.get(action, ['payload', 'active']),
                layerId: false
            }
        break;

        case 'ACTIVE_MENU':
            var menuId = _.get( action, ['payload', 'menuId'], '' )
            return { ..._.cloneDeep(state), menuId }
        break;

        case 'ACTIVE_LAYER':
            return { ..._.cloneDeep(state), panelId: 'layer', layerId: _.get(action, ['payload', 'layerId'], '') }
        break;

        case 'SET_STORES':
            return { ..._.cloneDeep(state), stores: _.get(action, ['payload', 'stores'], []) }
        break;

        case 'ADD_SIDE':
            let r = _.cloneDeep(state)
            let uuid = uniqid()
            r = {
                ...r,
                sides: [
                    ..._.cloneDeep(_.get(state, 'sides')),
                    {
                        id: uuid,
                        name: uuid,
                        layers: [],
                        document: {}
                    }
                ]
            }

            var sides = _.cloneDeep(_.get(r, ['mockup', 'sides'], []))
            sides = [
                ...sides,
                {
                    id: uuid,
                    name: uuid,
                    layers: [],
                    document: {}
                }
            ]
            _.set(r, ['mockup', 'sides'], sides)
            _.set(r, ['preset', 'sides'], sides)
            return r

        case 'REMOVE_SIDE':
            var newState = _.cloneDeep(state)
            var activeSideId = _.get(newState, 'sideId')
            var newSides = _.filter(_.cloneDeep(_.get(newState, ['mockup', 'sides'])), s => s.id !== _.get(action, ['payload', 'id']) )
            return {
                ...newState,
                ...(() => {
                    let mockup = _.cloneDeep(_.get(state, 'mockup'))
                    return { mockup: { ...mockup, sides: newSides } }
                })(),
                ...(() => {
                    if ( _.get(newState, 'editPreset') ) {
                        let preset = _.cloneDeep(_.get(state, 'preset'))
                        return { preset: { ...preset, sides: _.filter(_.cloneDeep(_.get(newState, ['preset', 'sides'])), s => s.id !== _.get(action, ['payload', 'id']) ) } }
                    }
                    return {}
                })(),
                sides: newSides,
                ...(() => {
                    if ( activeSideId === _.get(action, ['payload', 'id']) ) {
                        return {
                            sideId: _.get(_.head(newSides), 'id')
                        }
                    }
                    return {}
                })()
            }

        case 'UPDATE_SIDE':
            var newState = _.cloneDeep(state)
            var sideId = _.get(action, ['payload', 'id'])
            let sIndex = _.findIndex( _.cloneDeep(_.get(newState, ['sides'])), s => s.id === sideId )
            var side = _.cloneDeep(_.get(newState, ['sides', sIndex]))
            var image = _.get(action, ['payload', 'image'])
            var editPreset = _.get(newState, 'editPreset', false)
            var rootUpdate = _.get(action, ['payload', 'root'], false)

            // START BASE DATA
            side = {
                ...side,
                ...(() => {
                    return _.reduce(['name', 'id', 'loading', 'mockup'], (acc, name) => {
                        if ( _.get(action, ['payload', name]) !== undefined ) {
                            acc[name] = _.get(action, ['payload', name])
                        }
                        return acc
                    }, {})
                })()
            }
            _.set(newState, ['sides', sIndex], side)
            // END BASE DATA

            if ( editPreset && _.get(action, ['payload', 'name']) ) {
                _.set(newState, ['preset', 'sides', sIndex, 'name'], _.get(action, ['payload', 'name'], ''))
                _.set(newState, ['mockup', 'sides', sIndex, 'name'], _.get(action, ['payload', 'name'], ''))
            }

            // AFTER GENERATE MOCKUP PREVIEW
            if ( _.get(action, ['payload', 'mockup']) !== undefined ) {
                if ( editPreset ) {
                    _.set(newState, ['preset', 'sides', sIndex, 'mockup'], _.get(action, ['payload', 'mockup']))
                } else {
                    _.set(newState, ['mockup', 'sides', sIndex, 'mockup'], _.get(action, ['payload', 'mockup']))
                }
            }
            // AFTER GENERATE MOCKUP PREVIEW

            if ( _.get(action, ['payload', 'loading']) !== undefined ) {
                _.set(newState, ['mockup', 'sides', sIndex, 'loading'], _.get(action, ['payload', 'loading']) )
                _.set(newState, ['preset', 'sides', sIndex, 'loading'], _.get(action, ['payload', 'loading']) )
            }

            // FIRST INIT MOCKUP PRESET, SET DOCUMENT WIDTH - HEIGHT
            if ( image ) {
                _.set(side, 'image', image)
                _.set(newState, ['mockup', 'sides', sIndex, 'image'], image )
                _.set(newState, ['preset', 'sides', sIndex, 'image'], image )
                if ( _.get(image, 'width') !== undefined || _.get(image, 'height') !== undefined ) {
                    _.set(newState, ['preset', 'sides', sIndex, 'document', 'width'], _.get(image, 'width'))
                    _.set(newState, ['preset', 'sides', sIndex, 'document', 'height'], _.get(image, 'height'))
                    _.set(newState, ['mockup', 'sides', sIndex, 'document', 'width'], _.get(image, 'width'))
                    _.set(newState, ['mockup', 'sides', sIndex, 'document', 'height'], _.get(image, 'height'))
                    // current side
                    _.set(side, ['document', 'width'], _.get(image, 'width'))
                    _.set(side, ['document', 'height'], _.get(image, 'height'))
                }
            }
            // FIRST INIT MOCKUP PRESET, SET DOCUMENT WIDTH - HEIGHT

            // SET DOCUMENT WIDTH - HEIGHT manual
            var document = _.get(action, ['payload', 'document'])
            if ( editPreset && document ) {
                let newDocument = _.reduce(['width', 'height'], (acc, name) => {
                    if ( _.get(document, name) !== undefined ) {
                        acc[name] = toFloat(_.get(document, name))
                    }
                    return acc
                }, _.get(side, 'document') || {})
                _.set(newState, ['preset', 'sides', sIndex, 'document'], newDocument)
                _.set(newState, ['mockup', 'sides', sIndex, 'document'], newDocument)
            }
            // SET DOCUMENT WIDTH - HEIGHT manual

            // SET PRINT AREA WIDTH - HEIGHT manual
            var print_area = _.get(action, ['payload', 'print_area'])
            if ( editPreset && print_area ) {
                let side = _.get(newState, ['preset', 'sides', sIndex])
                if ( rootUpdate ) {
                    side = {
                        ...side,
                        print_area: {
                            ...(() => {
                                return _.reduce(['x', 'y', 'width', 'height', 'rotation', 'scaleX', 'scaleY', 'knewX', 'knewY'], (settings, name) => {
                                    let value = _.get(print_area, name)
                                    if ( value === undefined ) {
                                        return settings
                                    }
                                    settings[name] = value
                                    return settings
                                }, _.get(side, 'print_area') || {})
                            })()
                        }
                    }
                    _.set(newState, ['preset', 'sides', sIndex], side)
                    _.set(newState, ['mockup', 'sides', sIndex], side)
                } else {
                    let presetWidth = _.get(newState, ['preset', 'sides', sIndex, 'document', 'width'])
                    let presetHeight = _.get(newState, ['preset', 'sides', sIndex, 'document', 'height'])
                    let sideWidth = _.get(newState, ['sides', sIndex, 'document', 'width'])
                    let sideHeight = _.get(newState, ['sides', sIndex, 'document', 'height'])
                    let sidePrintArea = _.get(newState, ['sides', sIndex, 'print_area'])

                    sidePrintArea = {
                        ...sidePrintArea,
                        ...(() => {
                            return _.reduce(['x', 'y', 'width', 'height'], (acc, name) => {
                                if ( _.get(print_area, name) !== undefined ) {
                                    acc[name] = _.get(print_area, name)
                                }
                                return acc
                            }, sidePrintArea)
                        })()
                    }
                    side = {
                        ...side,
                        print_area: {
                            ...(() => {
                                return _.reduce(['rotation', 'scaleX', 'scaleY', 'knewX', 'knewY'], (settings, name) => {
                                    let value = _.get(print_area, name)
                                    if ( value === undefined ) {
                                        return settings
                                    }
                                    settings[name] = value
                                    return settings
                                }, _.get(side, 'print_area') || {})
                            })()
                        }
                    }

                    let newPresetPrintArea = _.reduce(['rotation', 'scaleX', 'scaleY', 'knewX', 'knewY'], (settings, name) => {
                        let value = _.get(print_area, name)
                        if ( value === undefined ) {
                            return settings
                        }
                        settings[name] = value
                        return settings
                    }, _.cloneDeep(_.get(newState, ['preset', 'sides', sIndex, 'print_area']) || {}))
                    let widthRatio = toFloat(_.get(sidePrintArea, 'width') / sideWidth)
                    let heightRatio = toFloat(_.get(sidePrintArea, 'height') / sideHeight)
                    let newWidth = toFloat(presetWidth * widthRatio)
                    let newHeight = toFloat(presetHeight * heightRatio)
                    let newX = toFloat(_.get(sidePrintArea, 'x') * newWidth / _.get(sidePrintArea, 'width'))
                    let newY = toFloat(_.get(sidePrintArea, 'y') * newHeight / _.get(sidePrintArea, 'height'))

                    _.set(newPresetPrintArea, 'width', newWidth)
                    _.set(newPresetPrintArea, 'height', newHeight)
                    _.set(newPresetPrintArea, 'x', newX)
                    _.set(newPresetPrintArea, 'y', newY)

                    _.set(newState, ['preset', 'sides', sIndex, 'print_area'], newPresetPrintArea)
                    _.set(newState, ['mockup', 'sides', sIndex, 'print_area'], newPresetPrintArea)
                    _.set(newState, ['sides', sIndex, 'print_area'], newPresetPrintArea)
                }
            }
            // SET PRINT AREA WIDTH - HEIGHT manual
            return newState
            break

        case 'SORT_SIDES':
            var { oldIndex, newIndex } = action.payload
            var sides = _.cloneDeep( _.get(state, 'sides') )
            sides = arrayMove(sides, oldIndex, newIndex)
            return {
                ..._.cloneDeep(state),
                ...(() => {
                    let newState = _.cloneDeep(state)
                    let mockup = _.get(newState, 'mockup')
                    let sides = _.cloneDeep(_.get(mockup, 'sides'))
                    sides = arrayMove(sides, oldIndex, newIndex)
                    return {
                        mockup: {
                            ...mockup,
                            sides
                        }
                    }
                })(),
                sides
            }
        break;

        case 'ADD_LAYER':
            var newState = _.cloneDeep(state)
            var sides = _.cloneDeep(_.get(newState, ['sides'], []))
            var layer = _.cloneDeep(_.get(action, ['payload', 'layer'], false))
            if ( ! layer ) {
                return newState
            }
            var sideId = _.get(newState, 'sideId', false)
            var side = false
            if ( sideId ) {
                let sideIndex = _.findIndex( sides, (side) => side.id === sideId )
                let layers = _.cloneDeep(_.get(sides, [sideIndex, 'layers'], []))
                let type = _.get(layer, 'type')
                var layerId = uniqid()
                let newLayer = {
                    ..._.cloneDeep(DEFAULT_LAYER),
                    ...layer,
                    id: layerId
                }
                if ( ! _.get(newLayer, 'name') ) {
                    newLayer.name = `${ucFirst(_.get(layer, 'type', ''))} #${layers.length + 1}`
                }
                layers.push(newLayer)
                layers = _.cloneDeep(layers)
                _.set(sides, [sideIndex, 'layers'], layers)
                return {
                    ...newState,
                    panelId: 'layer',
                    sides: _.cloneDeep(sides),
                    layerId
                }
            }
            return { ...newState }
        break;

        case 'DUPLICATE_LAYER':
            var sides = _.cloneDeep(_.get(state, 'sides'))
            var side = getCurrentSide(sides, _.get(state, 'sideId', ''))
            var sideIndex = getCurrentSideIndex(sides, _.get(state, 'sideId', ''))
            var layerIndex = getCurrentLayerIndex(sides, _.get(state, 'sideId', ''), state.layerId)
            var layer = _.cloneDeep(getCurrentLayer(sides, _.get(state, 'sideId', ''), state.layerId))
            var layerId = uniqid()//`${uniqid()}-${uuidv4()}`
            var layers = _.cloneDeep(_.get(side, 'layers'))
            var settings = _.get(layer, 'settings')
            settings.x = _.get(settings, 'x') + 30
            settings.y = _.get(settings, 'y') + 30
            layers.push({
                ...layer,
                settings,
                name: `Clone ${_.get(layer, 'name', '')}`,
                id: layerId,
                loading: !! _.get(action, ['payload', 'loading'], false)
            })
            _.set(sides, [sideIndex, 'layers'], _.cloneDeep(layers))
            return { ..._.cloneDeep(state), panelId: 'layer', sides: _.cloneDeep(sides), layerId, prevState: { ...state } }
        break;

        case 'REMOVE_LAYER':
            var sides = _.cloneDeep( _.get(state, 'sides', []) )
            var sideIndex = getCurrentSideIndex(sides, _.get(state, 'sideId', ''))
            var side = getCurrentSide(sides, _.get(state, 'sideId', ''))
            var layers = _.get(side, 'layers')
            layers = _.filter(layers, (layer) => layer.id !== _.get(action, ['payload', 'layerId']))
            _.set(sides, [sideIndex, 'layers'], layers)
            return {
                ..._.cloneDeep(state),
                sides,
                prevState: _.get(state, ['prevState', 'prevState']) ? _.cloneDeep(_.get(state, ['prevState', 'prevState'])) : false
            }
        break;

        case 'UPDATE_LAYER':
            var newState = _.cloneDeep(state)
            var payload = action.payload
            var layerId = _.get(payload, 'id', false)
            var layerName = _.get(payload, 'name', false)
            var settings = _.get(payload, 'settings', false)
            var sides = _.cloneDeep(_.get(newState, 'sides'))
            var side = getCurrentSide(sides, _.get(newState, 'sideId', ''))
            var sideIndex = getCurrentSideIndex(sides, _.get(newState, 'sideId', ''))
            var layerIndex = getCurrentLayerIndex(sides, _.get(newState, 'sideId', ''), layerId)
            var layer = _.cloneDeep(getCurrentLayer(sides, _.get(newState, 'sideId', ''), layerId))

            let updateDone = false
            _.set(sides, [sideIndex, 'layers', layerIndex], {
                ...layer,
                ...(() => {
                    let params = {
                        id: layerId
                    }
                    if ( layerName ) {
                        params.name = layerName
                    }
                    if ( settings && layer && Object.keys(settings).length > 0 ) {
                        params.settings = {
                            ...layer.settings,
                            ...settings
                        }
                    } else if ( layer ) {
                        params.settings = layer.settings
                    }
                    if ( _.get(payload, 'options') ) {
                        params.options = _.get(payload, 'options')
                    }
                    if ( _.get(payload, 'loading') !== undefined ) {
                        params.loading = !! _.get(payload, 'loading')
                    }
                    if ( params.loading === false && (layerName || (params.settings && Object.keys(params.settings).length > 0) ) ) {
                        updateDone = true
                    }
                    return params
                })()
            })
            _.set(newState, 'sides', sides)

            if ( updateDone ) {
                newState.prevState = _.cloneDeep(state)
            }

            return newState
        break;

        case 'SORT_LAYER':
            var { oldIndex, newIndex } = action.payload
            var sides = _.cloneDeep( _.get(state, 'sides') )
            var sideIndex = getCurrentSideIndex(sides, _.get(state, 'sideId', ''))
            var side = getCurrentSide(sides, _.get(state, 'sideId', ''))
            var layers = _.get(side, 'layers')
            layers = arrayMove(layers, oldIndex, newIndex)
            _.set(sides, [sideIndex, 'layers'], layers)
            return {
                ..._.cloneDeep(state),
                sides,
                prevState: _.cloneDeep(state)
            }
        break;
        case 'SET_CONTEXT_MENU':
            var x = _.get(action, ['payload', 'x'], false)
            var y = _.get(action, ['payload', 'y'], false)
            return { ..._.cloneDeep(state), layerContext: { x, y } }
        break;
        case 'PREV_STEP':
            var currentStepIndex = _.findIndex(_.get(state, 'steps'), step => step.id === _.get(state, 'step'))
            if (currentStepIndex > 0) {
                let prevStepIndex = currentStepIndex - 1
                return { ...state, step: _.get(state, ['steps', prevStepIndex, 'id']) }
            }

            return { ...state }
        break;
        case 'NEXT_STEP':
            var currentStepIndex = _.findIndex(_.get(state, 'steps'), step => step.id === _.get(state, 'step'))
            if (currentStepIndex < _.get(state, 'steps').length) {
                let nextStepIndex = currentStepIndex + 1
                var step = _.get(state, ['steps', nextStepIndex, 'id'])
                if ( typeof _.get(state, ['steps', nextStepIndex, 'onAction']) === 'function' ) {
                    let onAction = _.get(state, ['steps', nextStepIndex, 'onAction'])
                    onAction()
                    return { ...state }
                } else {
                    return { ..._.cloneDeep(state), step }
                }
            }

            return { ...state }
        break;
        case 'SET_STEP':
            var step = _.get(action, ['payload', 'step'])
            if ( _.find(_.get(state, 'steps'), (s) => s.id === step) ) {
                return { ..._.cloneDeep(state), step }
            }
            return { ...state }
        break;

        case 'SET_SIZES':
            let sizes = _.get(action, ['payload', 'sizes'])
            var prety_attributes = _.get(state, ['product', 'prety_attributes'])
            let attribute_specifics_modify = _.get(state, ['product', 'attribute_specifics_modify'])
            var variants = _.map( _.cloneDeep(attribute_specifics_modify), (variant) => {
                let selected = _.find(_.get(variant, 'name_value'), (t) => {
                    if ( _.get(t, 'type') !== 'Size' ) {
                        return false
                    }
                    return _.find(sizes, s => s.ffm_value === _.get(t, 'value') && s.default ) ? true : false
                }) ? true : false
                return {
                    ...variant,
                    selected: selected
                }
            } )

            return {
                ..._.cloneDeep(state),
                product: {
                    ..._.get(state, 'product'),
                    prety_attributes: _.map(prety_attributes, (attribute) => {
                        if ( _.get(attribute, 'attribute_type') !== 'Size' ) {
                            return attribute
                        }
                        attribute.options = sizes
                        return attribute
                    }),
                    attribute_specifics_modify: variants
                }
            }
        break;

        case 'UPDATE_VARIANTS':
            var variants = _.get(action, ['payload', 'variants'])
            return {
                ..._.cloneDeep(state),
                product: {
                    ..._.get(state, 'product'),
                    attribute_specifics_modify: variants
                }
            }
        break;

        case 'SET_FONT_LOADED':
            var loaded = _.get(action, ['payload', 'loaded'])
            return {
                ..._.cloneDeep(state),
                fontLoaded: loaded
            }
        break;

        case 'NEXT_SAVE_STEP':
            var newState = _.cloneDeep(state)
            var editPreset = _.get(newState, 'editPreset', false)
            // save mockup
            if ( _.get(action, ['payload', 'mockup']) ) {
                let mockup = _.get(action, ['payload', 'mockup'])
                let sides = _.get(mockup, 'sides')
                if ( editPreset ) {
                    _.set(newState, 'preset', mockup)
                } else {
                    _.set(newState, 'mockup', mockup)
                }
                _.set(newState, 'sides', sides)
            }
            if ( _.get(action, ['payload', 'product']) ) {
                _.set(newState, 'product', _.get(action, ['payload', 'product']))
            }

            var step = _.get(newState, 'save_step')
            var stepIndex = step !== false ? _.findIndex( _.get(newState, 'save_steps'), (s) => s.id === step ) : 0
            var nextStepIndex = (stepIndex < _.get(newState, 'save_steps').length - 1) ? stepIndex + 1 : (stepIndex === 0 ? 1 : stepIndex - 1)
            var nextStep = _.get(newState, ['save_steps', nextStepIndex, 'id'], false)

            if ( stepIndex === _.get(newState, 'save_steps').length - 1) {
                _.set(newState, ['save_steps', stepIndex, 'done'], true)
            } else if ( nextStepIndex === 0 ) {
                _.set(newState, ['save_steps', stepIndex, 'done'], false)
                _.set(newState, 'save_step', _.get(newState, ['save_steps', stepIndex, 'id']))
            } else {
                _.set(newState, ['save_steps', stepIndex, 'done'], true)
                _.set(newState, ['save_steps', nextStepIndex, 'done'], false)
                _.set(newState, 'save_step', nextStep)
            }
            return newState
        break;

        case 'RESET_SAVE_STEPS':
            var newState = _.cloneDeep(state)
            _.set(newState, 'save_steps', [...DEFAULT_SAVE_STEPS])
            _.set(newState, 'save_step', false)
            _.set(newState, 'error', false)
            _.set(newState, 'saving', false)
            _.set(newState, 'openSave', false)
            return newState
        break;

        case 'SET_SAVE_ERROR':
            var newState = _.cloneDeep(state)
            _.set(newState, 'error', _.get(action, ['payload', 'error'], ''))
            return { ...newState }
        break;

        case 'FLASH_MESSAGE':
            var newState = _.cloneDeep(state)
            var messages = _.get(newState, 'messages')
            var message = _.get(action, ['payload', 'message'])
            var type = _.get(action, ['payload', 'type'])
            messages.push({
                id: uniqid(),
                type,
                message
            })
            return {
                ...newState,
                messages
            }
        break;

        case 'REMOVE_FLASH_MESSAGE':
            var newState = _.cloneDeep(state)
            var messages = _.get(newState, 'messages')
            var id = _.get(action, ['payload', 'id'])
            messages = _.filter(messages, (me) => me.id !== id)
            return {
                ...newState,
                messages
            }
        break;

        case 'SET_EDITOR_SCALE':
            var newState = _.cloneDeep(state)

            return {
                ...newState,
                scale: _.get(action, ['payload', 'scale'])
            }
        break;

        case 'SET_EDITOR_XS_MODE':
            var newState = _.cloneDeep(state)
            var xsMode = _.get(action, ['payload', 'active'])
            if ( xsMode !== undefined ) {
                if ( xsMode ) {
                    newState.menuId = false
                } else {
                    newState.menuId = _.get(state, 'editPreset') ? 'side' : 'text'
                }
            }
            return {
                ...newState,
                xsMode: xsMode
            }
        break

        // 3D
        case 'SET_MATERIAL':
            return {
                ..._.cloneDeep(state),
                material: _.get(action, 'material')
            }
        break;
        default: {
            return state
        }
    }
}

export default editor
