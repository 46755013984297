import _ from "lodash"
import { api, api_mutation } from './../../../client'
import { internalItemQuery, makeItemInfo } from "./../../../@query/internalitem.query"
import { productMockupQuery, makeProductMockupQuery, makeMockupPreviewQuery, makeProductMockupPresetQuery } from "./../../../@query/product-mockup.query"
import { productMockupPresetsQuery, providerProductTypeProviderInfoQuery } from "./../../../@query/productpreset.query"
import { makeSignedUpload, updatePreSignedFile } from "./../../../@query/file.query"
import { setEditorFontIds } from './fonts'
import { history } from './../../..//history'

/* eslint-disable */
const MATCHMEDIA = window.matchMedia("(max-width: 992px)")

const FloatRanges = [
    'x',
    'y',
    'width',
    'height',
    'fontSize',
    'lineHeight',
    'opacity',
    'rotation',
    'knewX',
    'knewY',
    'scaleY',
    'scaleX',
    'letterSpacing',
]

const compileSettings = (settings) => {
    return _.reduce( settings, (acc, r, index) => {
        let name = _.get(r, 'name')
        let value = _.get(r, 'value')
        if ( FloatRanges.includes(name) && value !== '' && value !== null ) {
            value = parseFloat(parseFloat(value).toFixed(2))
        }
        return {
            ...acc,
            [name]: value
        }
    }, {} )
}

const formatMockup = (mockup = false) => {
    let result = _.cloneDeep(mockup)
    let sides = _.cloneDeep(_.get(result, 'sides'))
    sides = _.map(sides, (side) => {
        return {
            ...side,
            document: {
                ...side.document,
                ...(() => {
                    return {
                        settings: compileSettings(_.get(side, ['document', 'settings']))
                    }
                })()
            },
            layers: _.get(side, 'layers') ? _.reduce(_.cloneDeep(_.get(side, 'layers')), (acc, layer) => {
                return [
                    ...acc,
                    {
                        ...layer,
                        settings: compileSettings(_.get(layer, 'settings'))
                    }
                ]
            }, []) : []
        }
    })

    _.set(result, 'sides', sides)

    return result
}


export const setupProduct = (product = null, mockup_id = null, preset_id = null) => {
    return async (dispatch, getState) => {
        let payload = {}
        if ( product ) {
            payload.product = product
        }

        return api(productMockupPresetsQuery({ id: preset_id }))
        .then(async (res) => {
            let presets = _.get(res, ['data', 'data', 'productMockupPresets'])
            let preset = _.head(presets)
            payload.presets = presets
            payload.preset = preset

            if ( mockup_id && ! ['', 'preset', 'create'].includes(_.toLower(mockup_id)) ) {
                await api(productMockupQuery({ id: mockup_id }))
                .then((res) => {
                    let mockup = _.head(_.get(res, ['data', 'data', 'productMockups']))
                    payload.mockup = mockup
                    return mockup
                })
                .then((mockup) => {
                    let item_info_id = _.get(mockup, 'item_info_id')
                    if ( item_info_id ) {
                        return api(internalItemQuery({
                            _id: item_info_id
                        }))
                        .then((res) => {
                            let product = _.head(_.get(res, ['data', 'data', 'itemsInfo'] ))
                            payload.product = product
                        })
                    }
                })
            } else {
                if ( ['', 'preset'].includes(_.toLower(mockup_id)) && _.get(preset, 'type') === 'Custom' ) {
                    payload.editPreset = true
                }
                let { _id, id, sku, created_at, updated_at, ...mockup }  = preset
                payload.mockup = mockup

                await api(providerProductTypeProviderInfoQuery({ id: _.get(preset, 'product_id'), provider: _.get(preset, 'provider') }))
                .then((res) => {
                    let product = _.head(_.get(res, ['data', 'data', 'providerProductType']))
                    payload.product = product
                })
            }
            payload.mockup = formatMockup(_.get(payload, 'mockup'))

            payload.xsMode = MATCHMEDIA.matches
            // run setup
            dispatch({
                type: "SETUP_PRODUCT",
                payload
            })

            let font_ids = _.reduce(_.cloneDeep(_.get(payload, ['mockup', 'sides'])), (acc, side) => {
                return [
                    ...acc,
                    ...(() => {
                        return _.reduce(_.filter(_.cloneDeep(_.get(side, 'layers')), l => l.type === 'text' && _.get(l, ['settings', 'font_id'])), (acc, layer) => {
                            return [
                                ...acc,
                                _.get(layer, ['settings', 'font_id'])
                            ]
                        }, [])
                    })()
                ]
            }, [])
            if ( font_ids && font_ids.length > 0 ) {
                dispatch(setEditorFontIds(font_ids))
            } else {
                dispatch(setFontLoaded(true))
            }
        })

    }
}

export const setFontLoaded = (loaded) => {
    return async dispatch => {
        return dispatch({
            type: 'SET_FONT_LOADED',
            payload: {
                loaded
            }
        })
    }
}

export const initEditorWindow = ({ product, width, height }) => {
    return async dispatch => {
        let payload = {}
        if ( product ) {
            payload.product = product
        }
        if ( width ) {
            payload.width = width
        }
        if ( height ) {
            payload.height = height
        }
        let xsMode = window.matchMedia("(max-width: 992px)")
        payload.xsMode = xsMode.matches
        dispatch({
            type: "WINDOW_INIT",
            payload
        })
    }
}

export const destroyEditorWindow = () => {
    return async dispatch => {
        dispatch({
            type: "WINDOW_DESTROY"
        })
    }
}

export const activeMenu = (menuId = '') => {
    return async (dispatch, getState) => {
        let state = getState()
        let editor = _.get(state, ['editor', 'editor'])
        let xsMode = _.get(editor, 'xsMode', false)
        return dispatch({
            type: "ACTIVE_MENU",
            payload: {
                menuId: ! xsMode ? menuId : ( menuId !== _.get(editor, 'menuId') ? menuId : '' )
            }
        })
    }
}

export const editorLoaded = (loaded = true) => {
    return async dispatch => {
        dispatch({
            type: "WINDOW_LOADED",
            payload: {
                loaded
            }
        })
    }
}

export const setCurrentStage = (stage = null) => {
    return async dispatch => {
        dispatch({
            type: "SET_CURRENT_STAGE",
            payload: {
                stage
            }
        })
    }
}

export const undoHandler = () => {
    return async dispatch => {
        dispatch({
            type: "UNDO"
        })
    }
}

export const redoHandler = () => {
    return async dispatch => {
        dispatch({
            type: "REDO"
        })
    }
}

export const activeSide = (sideId = 'front') => {
    return async (dispatch, getState) => {
        const editor = _.get(getState(), ['editor', 'editor'])
        dispatch({
            type: "ACTIVE_SIDE",
            payload: {
                sideId
            }
        })

        if ( _.get(editor, 'xsMode') ) {
            dispatch(activeMenu(''))
        }
    }
}

export const activePanel = (panelId = 'document') => {
    return async dispatch => {
        dispatch({
            type: "ACTIVE_PANEL",
            payload: {
                panelId
            }
        })
    }
}

export const updateSide = (side = {}) => {
    return async (dispatch, getState) => {
        let state = _.get(getState(), ['editor', 'editor'])
        dispatch({
            type: "UPDATE_SIDE",
            payload: {
                ...side
            }
        })
        dispatch(initEditorWindow({
            width: _.get(state, 'width'),
            height: _.get(state, 'height'),
        }))
    }
}

export const activeEditorPrint = (active = false) => {
    return async (dispatch, getState) => {
        let editor = _.get(getState(), ['editor', 'editor'])
        if ( _.get(editor, 'printAreaEditable') === active ) {
            return
        }
        dispatch({
            type: "ACTIVE_EDITOR_PRINT",
            payload: {
                active
            }
        })
    }
}

export const activeLayer = (layerId = '', x, y) => {
    return async (dispatch, getState) => {
        const editor = _.get(getState(), ['editor', 'editor'])
        dispatch({
            type: "ACTIVE_LAYER",
            payload: {
                layerId
            }
        })
        if ( x !== undefined || y !== undefined ) {
            dispatch({
                type: "SET_CONTEXT_MENU",
                payload: {
                    x,
                    y
                }
            })
        }
    }
}

export const addLayer = (layer = false) => {
    return async (dispatch, getState) => {
        const editor = _.get(getState(), ['editor', 'editor'])
        dispatch(activeLayer(false))

        _.set(layer, 'loading', true)
        dispatch({
            type: "ADD_LAYER",
            payload: {
                layer
            }
        })

        if ( _.get(editor, 'xsMode') ) {
            dispatch(activeMenu(''))
        }
    }
}

export const removeLayer = (layerId = false) => {
    return async dispatch => {
        dispatch({
            type: "REMOVE_LAYER",
            payload: {
                layerId
            }
        })
    }
}

export const addSide = (side = {}) => {
    return async dispatch => {
        dispatch({
            type: "ADD_SIDE"
        })
    }
}

export const removeSide = (id) => {
    return async dispatch => {
        dispatch({
            type: "REMOVE_SIDE",
            payload: {
                id
            }
        })
    }
}

export const sortSide = (oldIndex, newIndex) => {
    return async dispatch => {
        dispatch({
            type: "SORT_SIDES",
            payload: {
                oldIndex,
                newIndex
            }
        })
    }
}

export const updateLayer = (layer = {}) => {
    return async (dispatch, getState) => {
        const state = _.get(getState(), ['editor', 'editor'])
        dispatch({
            type: "UPDATE_LAYER",
            payload: {
                ...layer
            }
        })
    }
}

export const duplicateLayer = (layerId) => {
    return async (dispatch, getState) => {
        const state = _.get(getState(), ['editor', 'editor'])
        dispatch({
            type: "DUPLICATE_LAYER",
            payload: {
                layerId
            }
        })
    }
}

export const sortLayer = (oldIndex, newIndex) => {
    return async (dispatch, getState) => {
        const state = _.get(getState(), ['editor', 'editor'])
        dispatch({
            type: "SORT_LAYER",
            payload: {
                oldIndex,
                newIndex
            }
        })
    }
}

export const setContextLayer = (x, y) => {
    return async dispatch => {
        dispatch({
            type: "SET_CONTEXT_MENU",
            payload: {
                x,
                y
            }
        })
    }
}

export const setSizes = (sizes) => {
    return async dispatch => {
        dispatch({
            type: "SET_SIZES",
            payload: {
                sizes
            }
        })
    }
}

export const updateVariants = variants => {
    return async dispatch => {
        dispatch({
            type: "UPDATE_VARIANTS",
            payload: {
                variants
            }
        })
    }
}

export const generateMockup = async () => {
    return async (dispatch, getState) => {
        let state = _.get(getState(), ['editor', 'editor'])
        let sides = _.get(state, 'sides')
        let itemInfoId = _.get(state, ['product', '_id'])

        // SAVE IMAGES EACH SIDE BY SERVER
        async function* _saveImage(side) {
            if ( ! _.get(side, ['image', 'url']) ) {
                return
            }
            try {
                if ( _.get(side, ['image', 'url']).includes('blob:') ) {
                    let file = await api_mutation(makeSignedUpload({ file: _.get(side, 'image'), type: 'library' }))
                    .then((res) => {
                        let url = _.get(res, ['data', 'data', 'signedUpload', 'url'], '')
                        let id = _.get(res, ['data', 'data', 'signedUpload', '_id'], '')
                        var options = {
                            headers: {
                                'Content-Type': _.get(_.get(side, 'image'), 'type', '')
                            }
                        }
                        return fetch(url, {
                            method: 'PUT',
                            body: _.get(side, 'image'),
                            headers: {
                                'Content-Type': _.get(_.get(side, 'image'), 'type', '')
                            }
                        })
                        .then((res) => {
                            if ( _.get(res, 'status') === 200 ) {
                                return api_mutation(updatePreSignedFile({ _id: id }))
                                .then((res) => {
                                    let fileUploaded = _.get(res, ['data', 'data', 'updatePreSignedFile'])
                                    return fileUploaded
                                })
                            } else {
                                // on error
                                throw new Error('ERROR')
                            }
                        })
                    })
                    yield dispatch(updateSide({
                        id: _.get(side, 'id'),
                        loading: false,
                        image: file,
                    }))
                }
                let state = _.get(getState(), ['editor', 'editor'])
                let preview = await api(makeMockupPreviewQuery({
                    item_info_id: itemInfoId,
                    preset_id: _.get(state, ['preset', '_id'], ''),
                    side: _.reduce(['document'], (acc, name) => {
                        _.set(acc, ['document', 'width'], _.get(_.find(_.get(state, ['preset', 'sides']), s => s.id === side.id), ['document', 'width']))
                        _.set(acc, ['document', 'height'], _.get(_.find(_.get(state, ['preset', 'sides']), s => s.id === side.id), ['document', 'height']))
                        return acc
                    }, _.find(_.get(state, 'sides'), s => s.id === side.id))
                }))
                let file = _.get(preview, ['data', 'data', 'makeMockupPreview'])
                yield dispatch(updateSide({
                    id: _.get(side, 'id'),
                    mockup: file
                }))
            } catch (err) {
                yield dispatch({ type: 'SET_SAVE_ERROR', payload: { error: `Oops! mockup file uploaded failure` } })
            }
        }

        async function* _saveImages(sides) {
            try {
                for await ( let side of sides ) {
                    for await ( let step of _saveImage(side) ) {
                        await step
                    }
                }

                yield dispatch({
                    type: 'NEXT_SAVE_STEP'
                })

            } catch (error) {
                yield dispatch({ type: 'SET_SAVE_ERROR', payload: { error: error.message } })
                yield dispatch({ type: 'TOGGLE_SAVING' })
            }
        }

        // unset current mockup preview
        await Promise.all(_.map(sides, side => {
            dispatch(updateSide({
                id: _.get(side, 'id'),
                mockup: null
            }))
        }))

        for await ( let step of _saveImages(sides) ) {
            await step
        }

        return dispatch({
            type: 'NEXT_SAVE_STEP'
        })
        // SAVE IMAGES EACH SIDE BY SERVER

        // // SAVE IMAGES EACH SIDE
        // async function* _saveImage(side) {
        //     yield dispatch(activeSide(_.get(side, 'id')))
        //     let state = _.get(getState(), ['editor', 'editor'])
        //     let stage = state.currentStage.clone()
        //     // let stage = _.get(window.Konva, ['stages', 0])
        //     // stage = stage.clone()
        //     stage.find('.print_area').each((print) => {
        //         print.hide()
        //     })
        //     let data = stage.toDataURL({
        //         mimeType: 'image/jpeg',
        //         pixelRatio: 1
        //     })

        //     if ( ! data ) {
        //         throw new Error(`Oops! something went wrong`)
        //     }
        //     let blob = dataURItoBlob(data)

        //     let file = new File([blob], `${uniqid.time()}.jpeg`, { type: blob.type } )
        //     return api_mutation(makeSignedUpload({ file, type: 'mockup' }))
        //     .then((res) => {
        //         let url = _.get(res, ['data', 'data', 'signedUpload', 'url'], '')
        //         let id = _.get(res, ['data', 'data', 'signedUpload', '_id'], '')
        //         var options = {
        //             headers: {
        //                 'Content-Type': _.get(file, 'type', '')
        //             }
        //         }
        //         return fetch(url, {
        //             method: 'PUT',
        //             body: file,
        //             headers: {
        //                 'Content-Type': _.get(file, 'type', '')
        //             }
        //         })
        //         .then((res) => {
        //             if ( _.get(res, 'status') === 200 ) {
        //                 return api_mutation(updatePreSignedFile({ _id: id }))
        //                 .then((res) => {
        //                     let fileUploaded = _.get(res, ['data', 'data', 'updatePreSignedFile'])
        //                     return dispatch({
        //                         type: "SET_SIDE_MOCKUP_IMAGE",
        //                         payload: {
        //                             id: _.get(side, 'id'),
        //                             image: fileUploaded
        //                         }
        //                     })
        //                 })
        //             } else {
        //                 // on error
        //                 throw new Error(`Oops! ${_.get(file, 'name')} uploaded failed`)
        //             }
        //         })
        //     })
        //     .catch(err => {
        //         throw err
        //     })
        // }

        // async function* _saveImages(sides) {
        //     try {
        //         for await ( let side of sides ) {
        //             for await ( let step of _saveImage(side) ) {
        //                 await step
        //             }
        //         }

        //         yield dispatch({
        //             type: 'NEXT_SAVE_STEP'
        //         })

        //         yield dispatch(activeLayer(null))

        //     } catch (error) {
        //         yield dispatch({ type: 'SET_SAVE_ERROR', payload: { error: error.message } })
        //         yield dispatch({ type: 'TOGGLE_SAVING' })
        //     }
        // }

        // for await ( let step of _saveImages(sides) ) {
        //     await step
        // }

        // return dispatch({
        //     type: 'NEXT_SAVE_STEP'
        // })
        // // SAVE IMAGES EACH SIDE
    }
}

export const saveMockup = async () => {
    return async (dispatch, getState) => {
        let state = getState()
        let editor = _.get(state, ['editor', 'editor'])
        let save_step = _.get(editor, 'save_step')
        let mockup = _.get(editor, 'mockup')

        return api(makeProductMockupQuery({
            ...mockup,
            preset_id: _.get(editor, ['preset', '_id']),
            item_info_id: _.get(editor, ['product', '_id']),
            sides: _.get(editor, 'sides', []),
            src: _.get( _.head(_.get(editor, 'sides', [])), ['mockup', 'url'], '')
        }))
        .then((res) => {
            let error = _.get(res, ['data', 'errors', 0, 'msg'])
            if ( error ) {
                throw new Error(error)
            }
            let mockup = _.get(res, ['data', 'data', 'makeProductMockup'])
            return mockup
        })
        .then((mockup) => {
            let payload = {
                mockup: formatMockup(mockup)
            }
            return dispatch({
                type: 'NEXT_SAVE_STEP',
                payload
            })
        })
        .catch(error => {
            throw error
        })
    }
}

export const _saveMockupPreset = async () => {
    return async (dispatch, getState) => {
        let state = getState()
        let editor = _.get(state, ['editor', 'editor'])
        let save_step = _.get(editor, 'save_step')
        let mockup = _.get(editor, 'mockup')

        return api(makeProductMockupPresetQuery({
            _id: _.get(editor, ['preset', '_id']),
            ...mockup,
            name: _.get(editor, ['preset', 'name'], ''),
            status: 'Publish',
            sides: _.get(editor, ['preset', 'sides'], []),
            src: _.get( _.head(_.get(editor, 'sides', [])), ['mockup', 'url'], '')
        }))
        .then((res) => {
            let error = _.get(res, ['data', 'errors', 0, 'msg'])
            if ( error ) {
                throw new Error(error)
            }
            let mockup = _.get(res, ['data', 'data', 'makeProductMockupPreset'])
            return mockup
        })
        .then((mockup) => {
            return dispatch({
                type: 'NEXT_SAVE_STEP',
                payload: {
                    mockup: formatMockup(mockup)
                }
            })
        })
        .catch(error => {
            throw error
        })
    }
}

export const saveProduct = async () => {
    return async (dispatch, getState) => {
        let state = getState()
        let editor = _.get(state, ['editor', 'editor'])
        let product = _.get(editor, 'product')
        let save_step = _.get(editor, 'save_step')
        let mockup = _.get(editor, 'mockup')

        let productParams = {}
        if ( product ) {
            productParams = {
                ...product,
                mockup_id: _.get(mockup, '_id'),
                images: _.reduce(_.get(mockup, 'sides'), (images, side) => {
                    images.push({
                        _id: _.get(side, '_id'),
                        src: _.get(side, ['mockup', 'url']),
                        image_sizes: _.get(side, 'image_sizes'),
                    })
                    return images
                }, []),
                attribute_specifics: _.get(product, 'attribute_specifics'),
                attribute_specifics_modify: _.map(_.cloneDeep(_.get(product, 'attribute_specifics_modify')), (attribute) => {
                    return {
                        ...attribute,
                        ffm_sku: _.get(attribute, 'sku')
                    }
                })
            }
        } else {
            product = _.cloneDeep(_.get(editor, ['preset', 'product']))
            productParams = {
                product_id: _.get(product, '_id')
            }
            delete product._id
            delete product.sku
            productParams = {
                ...product,
                ...productParams,
                mockup_id: _.get(mockup, '_id'),
                images: _.reduce(_.get(mockup, 'sides'), (images, side) => {
                    images.push({
                        _id: _.get(side, '_id'),
                        src: _.get(side, ['mockup', 'url']),
                        image_sizes: _.get(side, 'image_sizes'),
                    })
                    return images
                }, []),
                attribute_specifics: _.get(product, 'attribute_specifics'),
                attribute_specifics_modify: _.map(_.cloneDeep(_.get(product, 'attribute_specifics')), (attribute) => {
                    return {
                        ...attribute,
                        ffm_sku: _.get(attribute, 'sku')
                    }
                })
            }
        }

        productParams.attribute_specifics = _.map(_.get(productParams, 'attribute_specifics'), (attribute) => {
            attribute = _.cloneDeep(attribute)
            delete attribute.quantity
            return {
                ...attribute
            }
        })
        productParams.attribute_specifics_modify = _.map(_.cloneDeep(_.get(productParams, 'attribute_specifics_modify')), (attribute) => {
            attribute = _.cloneDeep(attribute)
            delete attribute.sku
            return {
                ...attribute,
                ffm_sku: _.get(attribute, 'sku')
            }
        })

        return api_mutation(makeItemInfo({
            product: productParams
        }))
        .then((res) => {
            let _id = _.get( _.head(_.get(res, ['data', 'data', 'makeItemInfo'])), '_id' )
            return api(internalItemQuery({
                _id
            }))
            .then((res) => {
                let product = _.head(_.get(res, ['data', 'data', 'itemsInfo'] ))
                return api(makeProductMockupQuery({
                    _id: _.get(mockup, '_id'),
                    item_info_id: _.get(product, '_id')
                }))
                .then((res) => {
                    let mockup = _.get(res, ['data', 'data', 'makeProductMockup'])
                    let payload = {
                        product,
                        mockup: formatMockup(mockup)
                    }
                    return dispatch({
                        type: 'NEXT_SAVE_STEP',
                        payload
                    })
                })
            })
        })
    }
}

export const saveProductMockup = () => {
    return async (dispatch, getState) => {
        async function* _saveHandler() {
            try {
                yield dispatch({
                    type: 'NEXT_SAVE_STEP'
                })

                yield dispatch(activeLayer(null))

                // open saving
                yield dispatch({ type: 'TOGGLE_SAVE_MODE' })

                // set saving
                yield dispatch({ type: 'TOGGLE_SAVING' })

                // generate mockup
                yield dispatch(await generateMockup({}))

                // save mockup
                yield dispatch(await saveMockup())

                let editor = _.get(getState(), ['editor', 'editor'])

                history.replace(`/mockup/${_.get(editor, ['preset', 'id'])}/editor/${_.get(editor, ['mockup', 'id'])}`)

                // release mockup
                yield dispatch(await saveProduct())

                // disable saving
                yield dispatch({ type: 'TOGGLE_SAVING' })
            } catch (error) {
                console.log(__filename, error)
                yield dispatch({ type: 'SET_SAVE_ERROR', payload: { error: error.message } })
                yield dispatch({ type: 'TOGGLE_SAVING' })
            }
        }

        for await ( let step of _saveHandler() ) {
            await step
        }
    }
}

export const saveMockupPreset = () => {
    return async (dispatch, getState) => {
        async function* _saveHandler() {
            try {
                yield dispatch({
                    type: 'NEXT_SAVE_STEP'
                })

                yield dispatch(activeLayer(null))

                // open saving
                yield dispatch({ type: 'TOGGLE_SAVE_MODE' })

                // set saving
                yield dispatch({ type: 'TOGGLE_SAVING' })

                // generate mockup
                yield dispatch(await generateMockup({}))

                yield dispatch({ type: 'NEXT_SAVE_STEP' })

                // save mockup
                yield dispatch(await _saveMockupPreset())

                yield dispatch({ type: 'NEXT_SAVE_STEP' })

                // disable saving
                yield dispatch({ type: 'TOGGLE_SAVING' })
            } catch (error) {
                yield dispatch({ type: 'SET_SAVE_ERROR', payload: { error: error.message } })
                yield dispatch({ type: 'TOGGLE_SAVING' })
            }
        }

        for await ( let step of _saveHandler() ) {
            await step
        }
    }
}

export const downloadMockup = () => {
    return async (dispatch, getState) => {
        let editor = _.get(getState(), ['editor', 'editor'])

        const _download = async () => {
            function _downloadFile(file) {
                // Create a link and set the URL using `createObjectURL`
                const link = document.createElement("a");
                link.style.display = "none";
                link.href = URL.createObjectURL(file);
                link.download = file.name;

                // It needs to be added to the DOM so it can be clicked
                document.body.appendChild(link);
                link.click();

                // To make this work on Firefox we need to wait
                // a little while before removing it.
                setTimeout(() => {
                    URL.revokeObjectURL(link.href);
                    link.parentNode.removeChild(link);
                }, 0);
            }

            let editor = _.get(getState(), ['editor', 'editor'])
            const { mockup } = editor
            let sides = _.get(mockup, 'sides')

            return Promise.all(_.map(sides, (side) => {
                let mockup = _.get(side, 'mockup')
                return fetch(_.get(mockup, 'url'))
                .then(res => res.blob())
                .then(blob => {
                    var objectURL = URL.createObjectURL(blob)
                    var file = new File( [blob], `${_.get(side, 'id')}.jpg`, {type : 'image/jpg'} )
                    _downloadFile(file)
                })
            }))
        }

        async function* _downloadHandler() {
            let editPreset = _.get(editor, 'editPreset')
            if ( editPreset ) {
                yield dispatch(await saveMockupPreset())
            } else {
                yield dispatch(await saveProductMockup())
            }
            yield await _download()
        }

        for await ( let step of _downloadHandler() ) {
            await step
        }
    }
}

export const setMockupPresetName = (name) => {
    return async dispatch => {
        dispatch({
            type: 'SET_MOCKUP_PRESET_NAME',
            payload: {
                name
            }
        })
    }
}

export const resetSaveSteps = () => {
    return async dispatch => {
        dispatch({
            type: 'RESET_SAVE_STEPS'
        })
    }
}

export const nextStep = () => {
    return async (dispatch, getState) => {
        let state = _.get(getState(), ['editor', 'editor'])
        var currentStepIndex = _.findIndex(_.get(state, 'steps'), step => step.id === _.get(state, 'step'))
        if (currentStepIndex < _.get(state, 'steps').length) {
            let nextStepIndex = currentStepIndex + 1
            let step = _.get(state, ['steps', nextStepIndex, 'id'])
            if ( typeof _.get(state, ['steps', nextStepIndex, 'onAction']) === 'function' ) {
                let onAction = _.get(state, ['steps', nextStepIndex, 'onAction'])
                onAction()
            } else {
                dispatch({
                    type: "NEXT_STEP",
                    step
                })
            }
        }
    }
}

export const prevStep = () => {
    return async dispatch => {
        dispatch({
            type: "PREV_STEP"
        })
    }
}

export const setStores = (stores) => {
    return async dispatch => {
        dispatch({
            type: "SET_STORES",
            payload: {
                stores
            }
        })
    }
}

export const setProductColor = (color = '#ffffff') => {
    return async dispatch => {
        dispatch({
            type: "SETUP_PRODUCT_COLOR",
            payload: {
                color
            }
        })
    }
}

export const flashMessage = (message = '', type = 'error') => {
    return async dispatch => {
        dispatch({
            type: "FLASH_MESSAGE",
            payload: {
                type,
                message
            }
        })
    }
}

export const removeFlashMessage = (id) => {
    return async dispatch => {
        dispatch({
            type: "REMOVE_FLASH_MESSAGE",
            payload: {
                id
            }
        })
    }
}

export const setZoom = (scale) => {
    return async (dispatch, getState) => {
        let editor = _.get(getState(), ['editor', 'editor'])
        dispatch({
            type: "SET_EDITOR_SCALE",
            payload: {
                scale
            }
        })
        dispatch(initEditorWindow({
            width: _.get(editor, 'width'),
            height: _.get(editor, 'height'),
        }))
    }
}

export const setXSMode = (active = false) => {
    return async (dispatch, getState) => {
        let editor = _.get(getState(), ['editor', 'editor'])
        dispatch({
            type: "SET_EDITOR_XS_MODE",
            payload: {
                active
            }
        })
    }
}

// 3D
export const setMaterial = (material) => {
    return async dispatch => {
        dispatch({
            type: "SET_MATERIAL",
            material
        })
    }
}
