import moment from 'moment'
export const ucFirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const toFloat = (num, fix = 2) => {
    return num && num !== "NaN" ? parseFloat(parseFloat(num).toFixed(fix)) : 0
}

export const hexToRgb = hex => {
    const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
    let result = null
    if (normal) {
        result = normal.slice(1).map(e => parseInt(e, 16))
    }

    const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
    if (shorthand) {
        result = shorthand.slice(1).map(e => 0x11 * parseInt(e, 16))
    }
    if ( result && result.length >= 3 ) {
        return {
            reg: result[0],
            green: result[1],
            blue: result[2],
            alpha: result[3] !== undefined ? result[3] : 1
        }
    }

    return result;
}
/**
 * @param $from Moment
 * @param $to Moment
 *
 * @return Int
 */
export const findBusinessDay = ( from, to, x = true ) => {
  if ( ! from || ! to ) {
    throw new Error( 'Missing params' )
  }

  from = moment(from.clone().startOf('day'))
  to = moment(to.clone().startOf('day'))
  let dayDiff = to.diff(from, 'days')

  var count = 0
  if ( from.format('YYYY-MM-DD') !== to.format('YYYY-MM-DD') ) {
    if ( from.day() !== 0 && from.day() !== 6 ) {
      count++
    }
    if ( count === 0 && to.day() !== 0 && to.day() !== 6 ) {
      count++
    }

    if ( dayDiff === 1 ) {
      return count
    }
  }

  for ( let i = 1; i <= dayDiff; i++ ) {
    let newDate = from.clone().add( i, 'days' )
    let day = newDate.day()

    if ( day !== 0 && day !== 6 ) {
      count++
    }
  }

  return count
}