import _ from 'lodash';
const Query = require('graphql-query-builder');

export const responseField = ['_id', 'type', 'code', 'subject', 'content', 'image', 'created_at', 'status', 'referent'];

export const notificationsCountQuery = (args) => {
	const query = new Query('notificationsCount', {
		_id: _.get(args, '_id', ''),
		...(() => {
			return _.get(args, 'types', []).lenth ? { types: _.get(args, 'types', []) } : {};
		})(),
		...(() => {
			return _.get(args, 'codes', []).lenth ? { codes: _.get(args, 'codes', []) } : {};
		})(),
		status: _.get(args, 'status', ''),
	}).find(['count']);
	return `query {${query}}`;
};

export const notificationsQuery = (args) => {
	const query = new Query('notifications', {
		_id: _.get(args, '_id', ''),
		...(() => {
			return _.get(args, 'types', []).lenth ? { types: _.get(args, 'types', []) } : {};
		})(),
		...(() => {
			return _.get(args, 'codes', []).lenth ? { codes: _.get(args, 'codes', []) } : {};
		})(),
		status: _.get(args, 'status', ''),
	}).find(responseField);
	return `query {${query}}`;
};

export const notificationMakeQuery = (args) => {
	const query = new Query('notificationMake', {
		ids: _.get(args, 'ids', ''),
		status: _.get(args, 'status', ''),
	}).find(responseField);
	return `mutation {${query}}`;
};

export const notificationReadAllQuery = () => {
	const query = new Query('notificationReadAll').find(responseField);
	return `mutation {${query}}`;
};
