export const control = (state = { app_ready: false }, action) => {
  switch (action.type) {
    case "MAINTENANCE": {
      return { ...state, maintenance: true }
    }
    case "APP_READY": {
      return { ...state, app_ready: true }
    }
    case "DOCKER_NOTFOUND": {
      return { ...state, app_404: true }
    }
    default: {
      return state
    }
  }
}
