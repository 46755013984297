import { api } from "./../../../client"
import { updateProfileQuery } from "./../../../@query/user.query"
import themeConfig from "../../../configs/themeConfig"

const customizerReducer = (state = themeConfig, action) => {
	switch (action.type) {
	case "CHANGE_CUSTOMIZER":
		return { ...state, ...action.payload }
	case "CHANGE_MODE":
		api(updateProfileQuery({ customizer: { ...state, theme: action.mode } }))
		return { ...state, theme: action.mode }
	case "COLLAPSE_SIDEBAR":
		api(updateProfileQuery({ customizer: { ...state, sidebarCollapsed: action.value } }))
		return { ...state, sidebarCollapsed: action.value }
	case "CHANGE_NAVBAR_COLOR":
		api(updateProfileQuery({ customizer: { ...state, navbarColor: action.color } }))
		return { ...state, navbarColor: action.color }
	case "CHANGE_NAVBAR_TYPE":
		api(updateProfileQuery({ customizer: { ...state, navbarType: action.style } }))
		return { ...state, navbarType: action.style }
	case "CHANGE_FOOTER_TYPE":
		api(updateProfileQuery({ customizer: { ...state, footerType: action.style } }))
		return { ...state, footerType: action.style }
	case "CHANGE_MENU_COLOR":
		api(updateProfileQuery({ customizer: { ...state, menuTheme: action.style } }))
		return { ...state, menuTheme: action.style }
	case "HIDE_SCROLL_TO_TOP":
		api(updateProfileQuery({ customizer: { ...state, hideScrollToTop: action.value } }))
		return { ...state, hideScrollToTop: action.value }
	default:
		return state
	}
}

export default customizerReducer
