import { createBrowserHistory } from 'history';
import { getDomainInfo } from './client';
import { refEncode } from './utility/function';
import { getCookie } from './redux/actions/cookie/cookie';

let defaultHistory = createBrowserHistory();
const devEndpoint = 'http://localhost:3200';
// :3200 if muliti project, 3000 if single
let getEndpoint = window.location.hostname.split('.').slice(-2).join('.');
// Geekpod domain here
// if (getEndpoint === "salehunter.io") {
//   getEndpoint = "geekpod.io"
// }
const proEndpoint = `https://account.${getEndpoint}`;

const loginUrl = (ref = true, withPath = true) => {
	const isLocalhost = getDomainInfo().isLocalhost;
	const endpoint = isLocalhost ? devEndpoint : proEndpoint;
	const path = isLocalhost ? 'login' : 'login-service';
	if (!withPath) {
		return `${endpoint}/${path}`;
	}
	const { href, origin, search, pathname } = window.location;
	if (!ref) {
		return `${endpoint}/${path}`;
	}
	return `${endpoint}/${path}?ref=${refEncode({
		href: href,
		origin: origin,
		search: search,
		pathname: pathname,
	})}`;
};

const logoutUrl = (ref = true, withPath = true) => {
	const isLocalhost = getDomainInfo().isLocalhost;
	const endpoint = isLocalhost ? devEndpoint : proEndpoint;
	const path = isLocalhost ? 'logout' : 'logout-service';
	if (!withPath) {
		return `${endpoint}/${path}`;
	}
	const { href, origin, search, pathname } = window.location;
	if (!ref) {
		return `${endpoint}/${path}`;
	}
	return `${endpoint}/${path}?ref=${refEncode({
		href: href,
		origin: origin,
		search: search,
		pathname: pathname,
	})}`;
};

const accountUrl = () => {
	const endpoint = getDomainInfo().isLocalhost ? devEndpoint : proEndpoint;
	return `${endpoint}`;
};

const registerUrl = () => {
	const endpoint = getDomainInfo().isLocalhost ? devEndpoint : proEndpoint;
	const { href, origin, search, pathname } = window.location;
	return `${endpoint}/register?ref=${refEncode({
		href: href,
		origin: origin,
		search: search,
		pathname: pathname,
	})}`;
};
export let history = {
	...defaultHistory,
	loginUrl: loginUrl,
	login: (ref = true, withPath = true) => {
		return (window.location.href = loginUrl(ref, withPath));
	},
	logoutUrl: logoutUrl,
	logout: (ref = true, withPath = true) => {
		return (window.location.href = logoutUrl(ref, withPath));
	},
	registerUrl: registerUrl,
	account: () => {
		return window.location.replace(accountUrl());
	},
	toTarget: (url) => {
		return (window.location.href = url);
	},
	accountUrl: accountUrl,
	oauth2Url: (params = {}) => {
		params = {
			token: getCookie('_token'),
			origin: window.location.origin,
			...params,
		};
		const isLocalhost = getDomainInfo().isLocalhost;
		const endpoint = isLocalhost ? devEndpoint : proEndpoint;

		const urlString = new URLSearchParams(params).toString();
		return `${endpoint}/oauth2?${urlString}`;
	},
};
