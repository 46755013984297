import React, { Component } from 'react';
import _ from 'lodash'
import { connect } from "react-redux"
import { AppProvider, Frame, Link, Loading } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json'
import themeConfig from './../configs/sellerThemeConfig'
import 'antd/dist/antd.css';
import '@shopify/polaris/build/esm/styles.css';

import "./../assets/scss/custom-antd.scss"
import "./../assets/scss/custom-polaris.scss"

class SellerLayout extends Component {

    state = {
        mobileNavigationActive: false,
        loading: false,
        pathname: _.get(this.props, ['history', 'pathname'], ''),
        modal: _.get(this.props, 'modal')
    }

    componentDidMount() {

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let state = {}
        if ( _.get(nextProps, ['history', 'pathname']) !== _.get(this.state, 'pathname', '') ) {
            state.pathname = _.get(nextProps, ['history', 'pathname'], '')
        }
        if ( _.get(nextProps, ['modal', 'open']) !== _.get(this.state, ['modal', 'open']) ) {
            state.modal = _.get(nextProps, 'modal')
        }

        if ( Object.keys(state).length > 0 ) {
            this.setState({ ...state }, () => {
                if (state.pathname) {

                }
            })
        }
    }

    toggleLoading = () => {
        this.setState({ loading: ! this.state.loading })
    }

    toggleMobileNavigationActive = () => {
        this.setState({ mobileNavigationActive: ! this.state.mobileNavigationActive })
    }

    render() {
        let props = {}
        if ( ! this.props.fullLayout && this.props.token && this.props.user ) {
            props = {
                topBar: null,
                navigation: null,
                showMobileNavigation: this.state.mobileNavigationActive,
                onNavigationDismiss: this.toggleMobileNavigationActive
            }
        }
        return (
            <AppProvider i18n={translations} theme={themeConfig} linkComponent={Link}>
                <Frame {...props}>
                    { this.state.loading && <Loading /> }
                    { this.props.children }
                </Frame>
            </AppProvider>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.login.loggedInUser,
        userRole: state.auth.login.userRole,
        config: state.auth.login.config,
        token: state.auth.login.token
    }
}

export default connect(mapStateToProps, {  })(SellerLayout)
