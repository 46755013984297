import React from "react"
import {
  connect
} from "react-redux"
import _ from "lodash"
import moment from "moment"
import { getCookie } from './../../../redux/actions/cookie/cookie';


class NavPlan extends React.PureComponent {
  state = {
    forced_hidden: false,
    expires_at: _.get(this.props.currentContainer, ["plan_log", "expires_at"], null) || _.get(this.props.currentContainer, "created_at", 0) + (30 * 24 * 60 * 60)
  }

  toggleModal = () => {
    this.setState({
      forced_hidden: true
    })
  }

  isExpired = () => {
    return moment().unix() > this.state.expires_at
  }

  isOpen = () => {
    if (this.isExpired()) {
      return true
    }
    if (this.state.forced_hidden) {
      return false
    }
    if (
      getCookie("hide_plan_topup") &&
      parseInt(getCookie("hide_plan_topup")) < moment().add(1, "days").startOf("days").unix() &&
      parseInt(getCookie("hide_plan_topup")) > moment().add(-1, "days").startOf("days").unix()
    ) {
      return false
    }
    return moment().add(3, "days").endOf('days').unix() > this.state.expires_at
  }

  isClose = () => {
    return !this.isExpired()
  }

  renderTimeLeft = () => {
    const now = moment().unix()
    const expires = this.state.expires_at
    const difSecon = expires - now
    const difMinus = parseInt(difSecon / 60)
    const difHour = parseInt(difSecon / (60 * 60))
    const difDay = parseInt(difSecon / (24 * 60 * 60))
    if (now >= expires) {
      return "Expired"
    }
    if (difDay > 0) return difDay + (difDay === 1 ? " day " : " days ") + "left"
    if (difHour > 0) return difHour + (difHour === 1 ? " hour " : " hours ") + "left"
    return (difMinus + 1) + (difMinus === 1 ? " minus " : " minus ") + "left"
  }

  render() {
    return false
    // if (this.props.currentContainer.unlimit_lifetime || getDomainInfo().isSaleHunter || getDomainInfo().rootDomain === 'localhost') {
    //   return false
    // }
    // return <NavItem className="nav-item d-none d-lg-block">
    //   <NavLink onClick={() => history.push("/settings/plan")}>
    //     <b style={{ lineHeight: "20px", fontSize: "13px" }} className={`text-${this.props.app.customizer.navbarColor === 'default' ? this.props.app.customizer.menuTheme : 'default'}`}><Package /> {
    //       _.get(_.find(_.get(this.props, "plan_config", []), (planConfig, planName) => {
    //         return this.props.currentContainer.plan === planName
    //       }), "name", "Free trial")} ({this.renderTimeLeft()})</b>
    //   </NavLink>
    //   <Modal
    //     isOpen={this.isOpen()}
    //     className="modal-dialog-centered modal-lg"
    //   >
    //     <ModalBody className="text-center p-0">
    //       <Plan />
    //     </ModalBody>
    //     {
    //       this.isClose() ? <ModalFooter>
    //         <Checkbox
    //           color="primary"
    //           icon={<Check className="vx-icon" size={16} />}
    //           label="Don't show again for today"
    //           defaultChecked={false}
    //           onChange={(e) => {
    //             if (e.target.checked) {
    //               setCookie("hide_plan_topup", moment().unix())
    //             } else {
    //               delCookie("hide_plan_topup")
    //             }
    //           }}
    //         />
    //         {/* <p>Still not convinced?</p> */}
    //         <Button color="primary" onClick={this.toggleModal} disabled={this.state.reload}>
    //           Keep your plan
    //         </Button>{" "}
    //       </ModalFooter>
    //         : false
    //     }

    //   </Modal>
    // </NavItem>
  }
}
const mapStateToProps = state => {
  return {
    user: state.auth.login.loggedInUser,
    currentContainer: _.get(_.find(_.get(state.auth.login, ["loggedInUser", "auth_docker"], []), con => con.current), "docker", {}),
    app: state.customizer,
    plan_config: state.app.config.hub_config
  }
}
export default connect(mapStateToProps)(NavPlan)