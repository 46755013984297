import React from 'react';
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Media, Spinner, Badge } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as Icon from 'react-feather';
import _ from 'lodash';
import moment from 'moment';
import { history } from './../../../history';
import { api } from './../../../client';
import {
	notificationsQuery,
	notificationReadAllQuery,
	notificationMakeQuery,
} from './../../../@query/notification.query';
import { connect } from 'react-redux';

class NavNotification extends React.PureComponent {
	state = {
		mounted: false,
		reload: true,
		reget: true,
		count: 0,
		notifications: [],
		page: 1,
		nomore: false,
	};

	UNSAFE_componentWillMount() {
	}

	getNotification = () => {
		this.setState(
			{
				reget: true,
			},
			() =>
				api(notificationsQuery({}), { page: this.state.page, perpage: 20 }).then((response) => {
					this.setState({
						notifications:
							this.state.page <= 1
								? _.get(response, ['data', 'data', 'notifications'], [])
								: [...this.state.notifications, ..._.get(response, ['data', 'data', 'notifications'], [])],
						reget: false,
						nomore: _.get(response, ['data', 'data', 'notifications'], []).length ? false : true,
					});
				})
		);
	};

	readNotification = (id, referentLink) => {
		if (id === 'all') {
			api(notificationReadAllQuery()).then((response) => {
				if (_.get(response, ['data', 'data', 'notifications'], [])) {
					this.setState({
						count: 0,
						notifications: this.state.notifications.map((notification) => ({ ...notification, status: 'Readed' })),
					});
				}
			});
		} else {
			const notification = _.find(this.state.notifications, (n) => n._id === id);
			api(notificationMakeQuery({ ids: [id], status: 'Readed' })).then((response) => {
				if (_.get(response, ['data', 'data', 'notifications'], [])) {
					const countScaleDown =
						notification.status === 'Readed' ? this.state.count : this.state.count - 1 > 0 ? this.state.count - 1 : 0;
					this.setState(
						{
							count: countScaleDown,
							notifications: this.state.notifications.map((noti) => ({
								...noti,
								status: noti._id === id ? 'Readed' : noti.status,
							})),
						},
						() => {
							if (window.location.pathname + window.location.search !== referentLink + notification.referent) {
								history.push(referentLink + notification.referent);
							}
						}
					);
				}
			});
		}
	};

	render() {
		return (
			<UncontrolledDropdown tag='li' className='dropdown-notification nav-item'>
				<DropdownToggle
					tag='a'
					className='nav-link nav-link-label'
					onClick={() => {
						this.getNotification();
					}}
				>
					<Icon.Bell size={21} />
					<Badge pill color='danger' className='badge-up'>
						{typeof this.props.user.notificationCount === 'undefined' ? (
							<Spinner style={{ color: '#fff' }} className='reload-spinner' size='sm' />
						) : (
							<strong>{' ' + this.props.user.notificationCount + ' '}</strong>
						)}
					</Badge>
				</DropdownToggle>
				<DropdownMenu tag='ul' right className='dropdown-menu-media'>
					<li className='dropdown-menu-header'>
						<div className='dropdown-header mt-0'>
							<h3 className='text-white'>{this.state.count} New</h3>
							<span className='notification-title'>App Notifications</span>
						</div>
					</li>
					<PerfectScrollbar
						className='media-list overflow-hidden position-relative'
						options={{
							wheelPropagation: false,
						}}
						onScrollY={(container) => {
							if (container.scrollHeight - container.scrollTop < 500 && !this.state.reget && !this.state.nomore) {
								this.setState(
									{
										page: this.state.page + 1,
									},
									() => this.getNotification()
								);
							}
						}}
					>
						{_.map(_.get(this.state, 'notifications', []), (notification, index) => {
							const humentTime = notification.created_at >= moment().subtract(18, 'hours').unix();
							let IconNoti = Icon.Bell;
							let referentLink = '';
							switch (notification.type) {
								case 'Order':
									IconNoti = Icon.Package;
									referentLink = '/orders/all?status=All&id=';
									break;
								case 'Item':
									IconNoti = Icon.Archive;
									referentLink = '/items/internal?_id=';
									break;
								case 'Support':
									IconNoti = Icon.MessageSquare;
									break;
								default:
									break;
							}
							return (
								<div
									key={index + notification._id}
									className='d-flex justify-content-between'
									style={{ opacity: notification.status === 'New' ? 1 : 0.4 }}
									onClick={() => this.readNotification(notification._id, referentLink)}
								>
									<Media className='d-flex align-items-start'>
										<Media left href='#'>
											<IconNoti className={`font-medium-5 ${_.toLower(notification.code)}`} size={21} />
										</Media>
										<Media body>
											<Media heading className={`media-heading ${_.toLower(notification.code)}`} tag='h6'>
												{_.get(notification, 'subject', '')}
											</Media>
											<p className='notification-text'>
												{_.get(notification, 'content', '')
													.split('\n')
													.map((content, idx) => (
														<span className='d-block' key={idx}>
															{content}
														</span>
													))}
											</p>
										</Media>
										<small>
											<time
												className='media-meta'
												dateTime={
													humentTime
														? moment.unix(notification.created_at).fromNow()
														: moment.unix(notification.created_at).format('H:mm:ss DD/MM/YYYY')
												}
											>
												{humentTime
													? moment.unix(notification.created_at).fromNow()
													: moment.unix(notification.created_at).format('H:mm:ss DD/MM/YYYY')}
											</time>
										</small>
									</Media>
								</div>
							);
						})}
						{this.state.reget ? (
							<div className='text-center my-1'>
								<Spinner color='primary' className='reload-spinner' />
							</div>
						) : (
							false
						)}
					</PerfectScrollbar>
					<li className='dropdown-menu-footer'>
						<DropdownItem tag='a' className='p-1 text-center' onClick={() => this.readNotification('all')}>
							<span className='align-middle'>Read all notifications</span>
						</DropdownItem>
					</li>
				</DropdownMenu>
			</UncontrolledDropdown>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.auth.login,
	}
}
export default connect(mapStateToProps)(NavNotification)