import React from "react"
import * as Icon from "react-feather"

let horizontalMenuConfig = [{
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: < Icon.Home size = {
      20
    }
    />,
    // permissions: ["admin", "editor"],
    navLink: "/dashboard",
    parentOf: "/dashboard"
  },
  {
    id: "order",
    title: "Sale",
    type: "item",
    icon: < Icon.List size = {
      20
    }
    />,
    // permissions: ["admin", "editor"],
    navLink: "/orders/all?status=Processing",
    parentOf: "/orders/:filter"
  },
  {
    id: "support",
    title: "Support",
    type: "item",
    icon: < Icon.MessageSquare size = {
      20
    }
    />,
    navLink: "/support/:filter",
    parentOf: "/support/inprocess",
  },
  {
    id: "store",
    title: "Stores",
    type: "item",
    icon: < Icon.ShoppingBag size = {
      20
    }
    />,
    // permissions: ["admin", "editor"],
    navLink: "/stores/all",
    parentOf: "/stores/:filter"
  },
  {
    id: "policy",
    title: "Policy",
    type: "item",
    icon: < Icon.Pocket size = {
      20
    }
    />,
    // permissions: ["admin", "editor"],
    navLink: "/policy",
    parentOf: "/policy"
  },
  {
    id: "term-of-condition",
    title: "Term of condition",
    type: "item",
    icon: < Icon.Pocket size = {
      20
    }
    />,
    // permissions: ["admin", "editor"],
    navLink: "/term-of-condition",
    parentOf: "/term-of-condition"
  }
]
export default horizontalMenuConfig
