import { combineReducers } from "redux"
import editor from './editor'
import fontManager from './fonts'
import cliparts from './cliparts'

export default combineReducers({
    editor,
    fontManager,
    cliparts
})
