import { combineReducers } from "redux"
import { control } from "./appReducer"
import { config } from "./appConfigReducer"

const appReducers = combineReducers({
	control,
	config
})

export default appReducers
