import gql from 'graphql-tag';
import _ from 'lodash';
const Query = require('graphql-query-builder');

export const responseFileField = [
	'_id',
	'url',
	'path',
	'mask',
	{
		tags: ['name'],
	},
	{
		created_by: ['_id', 'email', 'name', 'role'],
	},
	{
		image_sizes: ['name', 'url', 'width', 'height', 'mask'],
	},
	'sku',
	'filename',
	'size',
	'width',
	'height',
	'mimetype',
	'created_at',
	'updated_at',
];

export const responseDesignFiled = [
	'_id',
	'sku',
	'tags',
	{
		created_by: ['_id', 'email', 'name', 'role'],
	},
	{
		image: ['filename', '_id', 'url', 'width', 'height', 'size', 'mimetype'],
	},
	{
		print_files: [
			'type',
			{
				image: ['_id', 'filename', 'url', 'width', 'height', 'size', 'mimetype'],
			},
		],
	},
];

export const respnseFileTagField = ['name'];

/*****************************Start Query for Files*****************************/
export const getFile = (args) => {
	let params = {
		_id: _.get(args, '_id', ''),
	};
	const query = new Query('file', params).find(responseFileField);
	return `query {${query}}`;
};
export const uploadDeisgn = (args) => {
	let params = {
		_id: _.get(args, 'id', ''),
	};
	if (args.ref_id !== undefined && args.category) {
		params.ref_id = args.ref_id;
		params.category = args.category;
	}

	const query = new Query('uploadFile', params).find(responseFileField);
	return `query {${query}}`;
};

export const removeFile = (args) => {
	let params = {
		_id: _.get(args, '_id', ''),
	};
	const query = new Query('removeFile', params).find(responseFileField);
	return `mutation {${query}}`;
};

export const fetchFiles = (args) => {
	let params = {
		sku: _.get(args, 'sku', ''),
		created_by: _.get(args, 'created_by', ''),
		name: _.get(args, 'name'),
	};
	if (_.get(args, 'provider')) {
		// library || Custom
		params.provider = _.get(args, 'provider', '');
	}
	const query = new Query('files', params).find(responseFileField);
	return `query {${query}}`;
};

export const fetchFilesQuery = (args) => {
	let params = {
		_id: _.get(args, 'id', ''),
		sku: _.get(args, 'id', ''),
		urls: _.get(args, 'urls', []), // use on modal
		tags: _.get(args, 'tags', []),
		width: _.get(args, 'width', ''),
		height: _.get(args, 'height', ''),
		name: _.get(args, 'name', ''),
		start: _.get(args, 'start', ''),
		end: _.get(args, 'end', ''),
	};

	const query = new Query('files', params).find(responseFileField);
	return `query {${query}}`;
};

export const fileTagQuery = (args) => {
	let params = {
		name: _.get(args, 'name', ''),
	};

	const query = new Query('fileTags', params).find(respnseFileTagField);
	return `query {${query}}`;
};

export const saveFile = (args) => {
	let params = {
		_id: _.get(args, '_id', ''),
		tags: _.get(args, 'tags', []),
	};
	let tags = _.get(args, 'tags', []);
	tags = [...tags].map((t) => t.name);

	params.tags = tags;
	const query = new Query('saveFile', params).find(responseFileField);
	return `mutation {${query}}`;
};
/*****************************End Query for Files*****************************/

export const fileBlobQuery = (args) => {
	const query = new Query('fileBlob', {
		url: _.get(args, 'url', ''),
	}).find(['raw']);
	return `query {${query}}`;
};

// COPY FROM seller.onospod.com
export const makeSignedUpload = (args) => {
	const signedFileFragment = gql`
		fragment SignedFile on FileSigned {
			_id
			url
		}
	`;
	let query = gql`
		mutation signedUpload($type: String, $filename: String, $mimetype: String, $size: Int) {
			signedUpload(type: $type, filename: $filename, mimetype: $mimetype, size: $size) {
				...SignedFile
			}
		}
		${signedFileFragment}
	`;

	let variables = {
		type: _.get(args, 'type'),
		filename: _.get(args, ['file', 'name']),
		mimetype: _.get(args, ['file', 'type']),
		size: _.get(args, ['file', 'size']),
	};

	return {
		operationName: 'signedUpload',
		query: query.loc.source.body,
		variables,
	};
};

export const updatePreSignedFile = (args) => {
	const signedFileFragment = gql`
		fragment UpdateSignedFile on File {
			_id
			url
			image_sizes {
				name
				url
			}
		}
	`;
	let query = gql`
		mutation updatePreSignedFile($_id: String) {
			updatePreSignedFile(_id: $_id) {
				...UpdateSignedFile
			}
		}
		${signedFileFragment}
	`;

	let variables = {
		_id: _.get(args, '_id'),
	};

	return {
		operationName: 'updatePreSignedFile',
		query: query.loc.source.body,
		variables,
	};
};
// END COPY FROM seller.onospod.com

/* eslint-disable */
export default {
	uploadDeisgn,
	fetchFiles,
	fetchFilesQuery,
	removeFile,
	saveFile,
	getFile,
	fileBlobQuery,
	makeSignedUpload,
	updatePreSignedFile,
};
/* eslint-enable */
