import _ from 'lodash';
import moment from 'moment';
const Query = require('graphql-query-builder');

const responseUserPermission = [
	'module',
	'title',
	{
		access: ['view', 'add', 'update', 'delete'],
	},
];

const currentUserResponseField = [
	'_id',
	'identity',
	'identity_label',
	'email',
	'groups',
	'token',
	'name',
	{
		auth_docker: [
			'docker_id',
			'current',
			{
				docker: ['domain', 'label', 'server', 'trend'],
			},
		],
	},
	{
		customizer: [
			'theme',
			'sidebarCollapsed',
			'navbarColor',
			'navbarType',
			'footerType',
			'menuTheme',
			'hideScrollToTop',
		],
	},
	'total_credit',
	'ffm_sku',
	'ffm_sku_label',
];

const responseField = [
	'_id',
	'identity',
	'identity_label',
	'email',
	'role',
	'groups',
	'token',
	'name',
	'h',
	{
		group: ['_id', 'name'],
	},
	{
		permission: responseUserPermission,
	},
	'status',
	'last_login',
	'created_at',
	'updated_at',
	{
		auth_docker: [
			'auth_id',
			'docker_id',
			'groups',
			'current',
			{
				docker: [
					'_id',
					'domain',
					'status',
					'image',
					'created_at',
					'hiden',
					'label',
					'server',
					'plan',
					'annually',
					'unlimit_lifetime',
					{
						plan_log: ['plan', 'payment_at', 'expires_at'],
					},
				],
			},
		],
	},
	{
		customizer: [
			'theme',
			'sidebarCollapsed',
			'navbarColor',
			'navbarType',
			'footerType',
			'menuTheme',
			'hideScrollToTop',
		],
	},
	'total_credit',
	'vip',
	'ship_by_seller',
	// "user_type",
	// "active_status"
	'phone_number',
	'sale_plan',
	'facebook',
	'skype',
	'ffm_sku',
	'ffm_sku_label',
	'plan_config',
];

const responseUserGroupField = [
	'_id',
	'name',
	'status',
	'role',
	'parent_id',
	'is_default',
	{
		permission: responseUserPermission,
	},
	'created_at',
	'updated_at',
];

const responseCheckUserAccess = ['allowed'];
export const checkUserAccess = (args) => {
	const query = new Query('checkUserAccess', {
		method: _.get(args, 'method', ''),
		action: _.get(args, 'action', 'view'),
	}).find(responseCheckUserAccess);
	return `query {${query}}`;
};

export const userPermissionQuery = () => {
	const query = new Query('userPermissions', {}).find(responseUserPermission);
	return `query {${query}}`;
};

export const defaultUserPermissionQuery = () => {
	const query = new Query('getDefaultPermissions', {}).find(responseUserPermission);
	return `query {${query}}`;
};

export const userGroupQuery = (args) => {
	var status = _.get(args, 'status', '');
	if (status === 'Opened') {
		status = '';
	}
	const query = new Query('usergroups', {
		name: _.get(args, 'name', ''),
		status: status,
		get_all: _.get(args, 'get_all', false),
	}).find(responseUserGroupField);
	return `query {${query}}`;
};
export const userGroupQueryMake = (args) => {
	let params = {
		id: _.get(args, 'id', ''),
		params: {
			name: _.get(args, 'name', ''),
			status: _.get(args, 'status', ''),
			permission: _.get(args, 'permission', []),
		},
	};
	const query = new Query('makeUserGroup', params).find(responseUserGroupField);
	return `mutation {${query}}`;
};

export const userGroupQueryMakeMany = (args) => {
	args = JSON.parse(args);
	const params = args.map((arg) => {
		return {
			id: _.get(arg, 'id', ''),
			params: {
				name: _.get(arg, 'name', ''),
				status: _.get(arg, 'status', 'Active'),
				permission: _.get(arg, 'permission', ''),
			},
		};
	});
	const query = new Query('makeUserGroups', params).find(responseUserGroupField);
	return `mutation {${query}}`;
};
export const userGroupQueryRemove = (args) => {
	const delete_ids = _.get(args, 'delete_ids', []).map((d) => {
		return d.trim();
	});
	const query = new Query('trashUserGroup', {
		delete_ids: delete_ids,
	}).find(responseUserGroupField);
	return `mutation {${query}}`;
};

export const getCurrentUserQuery = (args) => {
	const query = new Query('cUser').find(currentUserResponseField);
	return `query {${query}}`;
};
export const getUserByEmailQuery = (args) => {
	let params = {};
	if (_.get(args, 'email', false)) {
		params['email'] = _.get(args, 'email', false);
	}
	const query = new Query('getUserByEmail', params).find(['_id', 'email']);
	return `query {${query}}`;
};
export const loginWithSessionToken = (args) => {
	const query = new Query('cUser', {
		session_token: _.get(args, 'session_token'),
	}).find(responseField);
	return `query {${query}}`;
};

export const getUserByIdQuery = (args) => {
	let params = {};
	if (_.get(args, '_id', false)) {
		params['_id'] = _.get(args, '_id', false);
	}
	const query = new Query('getUserById', params).find(responseField);
	return `query {${query}}`;
};

export const updateProfileQuery = (args) => {
	let params = {};
	if (_.get(args, 'name', false)) {
		params['name'] = _.get(args, 'name', false);
	}
	if (_.get(args, 'password', false)) {
		params['password'] = _.get(args, 'password', false);
	}
	if (_.get(args, 'new_password', false)) {
		params['new_password'] = _.get(args, 'new_password', false);
	}
	if (_.get(args, 'h', '') === 'show') {
		params['h'] = '';
	}
	if (_.get(args, 'customizer', false)) {
		const cusParams = _.get(args, 'customizer', {});

		let cusParam = {};
		if (typeof cusParams.theme !== 'undefined' && cusParams.theme !== null) {
			cusParam['theme'] = cusParams.theme;
		}
		if (typeof cusParams.sidebarCollapsed !== 'undefined' && cusParams.sidebarCollapsed !== null) {
			cusParam['sidebarCollapsed'] = cusParams.sidebarCollapsed;
		}
		if (typeof cusParams.navbarColor !== 'undefined' && cusParams.navbarColor !== null) {
			cusParam['navbarColor'] = cusParams.navbarColor;
		}
		if (typeof cusParams.navbarType !== 'undefined' && cusParams.navbarType !== null) {
			cusParam['navbarType'] = cusParams.navbarType;
		}
		if (typeof cusParams.footerType !== 'undefined' && cusParams.footerType !== null) {
			cusParam['footerType'] = cusParams.footerType;
		}
		if (typeof cusParams.menuTheme !== 'undefined' && cusParams.menuTheme !== null) {
			cusParam['menuTheme'] = cusParams.menuTheme;
		}
		if (typeof cusParams.hideScrollToTop !== 'undefined' && cusParams.hideScrollToTop !== null) {
			cusParam['hideScrollToTop'] = cusParams.hideScrollToTop;
		}
		params['customizer'] = cusParam;
	}
	const query = new Query('updateProfile', params).find(responseField);
	return `mutation {${query}}`;
};

export const createUserQuery = (args) => {
	let params = {};
	if (_.get(args, 'name', false)) {
		params['name'] = _.get(args, 'name', false);
	}
	if (_.get(args, 'email', false)) {
		params['email'] = _.get(args, 'email', false);
	}
	if (_.get(args, 'password_create', false)) {
		params['password'] = _.get(args, 'password_create', false);
	}
	if (_.get(args, 'confirm_password_create', false)) {
		params['confirm_password'] = _.get(args, 'confirm_password_create', false);
	}
	const query = new Query('createUser', params).find(responseField);
	return `mutation {${query}}`;
};

export const usersQuery = (args) => {
	var status = _.get(args, 'status', '');
	if (status === 'Opened') {
		status = '';
	}
	const query = new Query('users', {
		identity: _.get(args, 'identity', ''),
		email: _.get(args, 'email', ''),
		search: _.get(args, 'search', ''),
		status: status,
		group: _.get(args, 'group', ''),
		start: _.get(args, 'start', false)
			? moment(_.get(args, 'start', ''), 'DD-MM-YYYY').startOf('days').toISOString()
			: '',
		end: _.get(args, 'end', false) ? moment(_.get(args, 'end', ''), 'DD-MM-YYYY').endOf('days').toISOString() : '',
	}).find(responseField);
	return `query {${query}}`;
};

export const userQueryMake = (args) => {
	let params = {
		id: _.get(args, '_id', ''),
		params: {
			email: _.get(args, 'email', ''),
			name: _.get(args, 'name', '') ? _.get(args, 'name', '') : '',
			status: _.get(args, 'status', ''),
			// group: _.get(args, 'group_id', ''),
			...() => {
				if (_.get(args, 'groups', []) && _.get(args, 'groups', []).length) {
					return {
						groups: _.get(args, 'groups', []),
					};
				}
			},
			// password: _.get(args, 'password', ''),
			new_password: _.get(args, 'new_password', ''),
			confirm_password: _.get(args, 'confirm_password', ''),
			vip: _.get(args, 'vip', 0) ? parseInt(_.get(args, 'vip', '')) : 0,
			identity_label: _.get(args, 'identity_label', ''),
			ship_by_seller: Boolean(_.get(args, 'ship_by_seller')),

			phone_number: _.get(args, 'phone_number', '') || '',
			sale_plan: _.get(args, 'sale_plan', '') || '',
			facebook: _.get(args, 'facebook', '') || '',
			skype: _.get(args, 'skype', '') || '',
			// ...(() => {
			//   if (_.get(args, "hub", false)) {
			//     return {domain_name: _.get(args, "domain_name", "")}
			//   }
			// })()
			// user_type: String(_.get(args, 'user_type', '')),
			// active_status: String(_.get(args, 'active_status', '')),
		},
		...(() => {
			if (_.get(args, 'hub_action', '') === 'Create' && _.get(args, 'hub_domain_name', '')) {
				return {
					hubs: {
						hub_domain_name: _.get(args, 'hub_domain_name', ''),
						hub_label: _.get(args, 'hub_label', ''),
						hub_server: _.get(args, 'hub_server', ''),
						hub_action: _.get(args, 'hub_action', ''),
						hub_groups: _.get(args, 'docker_groups', []),
					},
				};
			} else if (
				(_.get(args, 'hub_action', '') === 'Assigned' || _.get(args, 'hub_action', '') === 'Unset') &&
				_.get(args, 'hub_id', '')
			) {
				return {
					hubs: {
						hub_id: _.get(args, 'hub_id', ''),
						hub_action: _.get(args, 'hub_action', ''),
						hub_groups: _.get(args, 'docker_groups', []),
					},
				};
			} else if (_.get(args, 'hub_action', '') === 'Edit' && _.get(args, 'hub_id', '')) {
				return {
					hubs: {
						hub_id: _.get(args, 'hub_id', ''),
						hub_label: _.get(args, 'hub_label', ''),
						hub_action: _.get(args, 'hub_action', ''),
						hub_groups: _.get(args, 'docker_groups', []),
					},
				};
			}
		})(),
	};
	const query = new Query('makeUser', params).find(responseField);
	return `mutation {${query}}`;
};

export const userQueryMakeMany = (args) => {
	args = JSON.parse(args);
	const params = args.map((arg) => {
		return {
			id: _.get(arg, 'id', ''),
			params: {
				email: _.get(arg, 'email', ''),
				name: _.get(arg, 'name', ''),
				status: _.get(arg, 'status', ''),
				group: _.get(arg, 'group_id', ''),
				password: _.get(arg, 'password', ''),
				new_password: _.get(arg, 'new_password', ''),
				confirm_password: _.get(arg, 'confirm_password', ''),
			},
		};
	});
	const query = new Query('makeUsers', params).find(responseField);
	return `mutation {${query}}`;
};

export const deleteById = (args) => {
	let params = {};
	if (_.get(args, '_id', false)) {
		params['_id'] = _.get(args, '_id', false);
	} else {
		const delete_ids = _.get(args, 'delete_ids', []).map((d) => {
			return d.trim();
		});
		params['_id'] = _.get(delete_ids, 0);
	}

	const query = new Query('deleteById', params).find(responseField);
	return `mutation {${query}}`;
};

// async fetch users suggestion for assign task
// added by branch feature/task-manager
export const usersQueryByName = (args) => {
	const query = new Query('usersByName', {
		name: _.get(args, 'name', ''),
	}).find(responseField);
	return `query {${query}}`;
};

export const updateUserShopifyInfoQuery = (args) => {
	let shopify_shop_info = _.get(args, 'shopify_shop_info');
	let params = {
		_id: _.get(args, '_id'),
	};

	if (shopify_shop_info) {
		let info = {};
		if (_.get(shopify_shop_info, 'uid')) {
			info.uid = _.get(shopify_shop_info, 'uid');
		}
		if (_.get(shopify_shop_info, 'shop')) {
			info.shop = _.get(shopify_shop_info, 'shop');
		}
		if (_.get(shopify_shop_info, 'access_token')) {
			info.access_token = _.get(shopify_shop_info, 'access_token');
		}
		if (_.get(shopify_shop_info, 'session')) {
			info.session = _.get(shopify_shop_info, 'session');
		}
		if (_.get(shopify_shop_info, 'expires_at')) {
			info.expires_at = parseInt(_.get(shopify_shop_info, 'expires_at'));
		}
		params.shopify_shop_info = info;
	}
	const query = new Query('updateUserShopifyInfo', params).find(['shop_url']);
	return `mutation {${query}}`;
};
// export const deleteById = args => {
//   let params = {}
//   if (_.get(args, '_id', false)) {
//     params['_id'] = _.get(args, '_id', false)
//   }
//   const query = new Query("deleteById", params).find(responseField)
//   return `mutation {${query}}`
// }
