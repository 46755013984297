import _ from 'lodash';

const uid = function () {
	return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

const backgroundReducer = (
	state = {
		queueTask: null,
		tasks: [],
		tasksError: [],
	},
	action
) => {
	var tasks = []
	switch (action.type) {
		case 'PUSH_BACKGROUND_TASK':
			tasks = [
				...state.tasks,
				{
					key: uid(),
					...action.payload,
				},
			];
			return {
				...state,
				tasks: tasks,
				queueTask: _.head(tasks),
			};
		case 'REMOVE_BACKGROUND_TASK':
			tasks = _.filter(state.tasks, (task) => task.key !== action.payload?.key);
			return {
				...state,
				tasks: tasks,
				queueTask: _.head(tasks),
			};
		case 'NULL_QUEUE_TASK':
			return {
				...state,
				queueTask: null,
			};
		case 'ERROR_BACKGROUND_TASK':
			return {
				...state,
				tasksError: [...state.tasksError, action.payload],
			};

		case 'REMOVE_ERROR_BACKGROUND_TASK':
			tasks = _.filter(state.tasksError, (task) => task.key !== action.payload?.key);
			return {
				...state,
				tasksError: tasks,
			};

		default:
			return state;
	}
};

export default backgroundReducer;
