export const scaleUpBatchCsv = () => {
  return dispatch => dispatch({ type: "SCALE_UP_BATCH_CSV" })
}

export const scaleDownBatchCsv = () => {
  return dispatch => dispatch({ type: "SCALE_DOWN_BATCH_CSV" })
}

export const clearBatchCsv = () => {
  return dispatch => dispatch({ type: "CLEAR_BATCH_CSV" })
}
