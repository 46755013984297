import gql from 'graphql-tag';
import _ from 'lodash';
import { toFloat } from './../utils/string';
import { responseField as jobResponseField } from './job.query';
import { responseField as mockupResponseField } from './mockup-design.query';
const Query = require('graphql-query-builder');

export const responseField = [
	'_id',
	'identity',
	'name',
	'description',
	'description_ai',
	'description_ai_pos',
	{
		images: ['src'],
	},
	'front',
	'back',
	'sleeve',
	'hood',
	'type',
	'product_id',
	'plf_price',
	{
		prety_attributes: [
			'attribute_type',
			'plf_attribute_name',
			{
				options: ['ffm_value', 'plf_value', 'plf_price', 'hex', 'default'],
			},
		],
	},
	{
		specifics: ['key', 'value', 'required'],
	},
	{
		attribute_specifics: [
			'sale_price',
			'base_price',
			{
				name_value: ['name', 'type', 'value'],
			},
			'fix_profit',
			'fixed_profit',
		],
	},
	{
		attribute_specifics_modify: [
			'sale_price',
			'base_price',
			{
				name_value: ['name', 'type', 'value'],
			},
			'fix_profit',
			'fixed_profit',
			'sku',
			'barcode',
			{
				image: [
					'_id',
					'url',
					{
						image_sizes: ['url', 'width', 'height'],
					},
				],
			},
		],
	},
	'tags',
	'collections',
	'size_chart',
	'shipping_preset',
	{
		items: [
			'_id',
			'imported_at',
			'platform',
			'platform_id',
			'store_id',
			'external_url',
			'sku',
			{
				store: ['_id', 'sto_name', 'sto_type', 'sto_ebay_payment', 'sto_status', 'issuu_token'],
			},
			'job_list',
			{
				issuu: ['url', 'time'],
			},
		],
	},
	'fix_profit',
	'fixed_profit',
	'tax_fee_fix',
	'tax_fee',
	{
		platform_category: [
			'type',
			{
				category_selected: ['id', 'level', 'name', 'parent'],
			},
			'alternate_name',
		],
	},
	{
		product: ['_id', 'name', 'provider'],
	},
	'include_size_chart',
	'custom_name',
	'include_extend_images',
	'ebay_template_id',
	{
		platform_specifics: [
			'platform',
			{
				specifics: ['key', 'value', 'required'],
			},
		],
	},
	'mockup_id',
	{
		mockup: mockupResponseField,
	},
	{
		store_queue: [
			'store_id',
			'queue_at',
			'queue_status',
			'queue_messenger',
			{
				store: ['_id', 'sto_name', 'sto_type'],
			},
		],
	},
	'weight',
	'package_width',
	'package_height',
	'package_length',
	'package_dimension_unit',
	'sku',
	'barcode',
	'barcode_type',
	{
		extend_images: ['src'],
	},
	'price_addition',
	'price',
	'job_list',
	'url'
];

export const internalItemQuery = (args) => {
	const query = new Query('itemsInfo', {
		_id: _.get(args, '_id', ''),
		identity: _.get(args, 'identity', ''),
		name: _.get(args, 'name', ''),
		store: _.get(args, 'store', ''),
		search: _.get(args, 'search', ''),
		collection: _.get(args, 'collection', ''),
	}).find(responseField);
	return `query {${query}}`;
};

export const jobItemQuery = (args) => {
	const ids = _.get(args, 'ids', []).map((d) => {
		return d.trim();
	});
	const store_ids = _.get(args, 'store_ids', []).map((d) => {
		return d.trim();
	});
	const query = new Query('jobItem', {
		ids: ids,
		store_ids: store_ids,
	}).find(jobResponseField);
	return `mutation {${query}}`;
};

export const queueItemQuery = (args) => {
	const ids = _.get(args, 'ids', []).map((d) => {
		return d.trim();
	});
	const store_id = _.get(args, 'store_id', '');
	const platform = _.get(args, 'platform', '');
	const query = new Query('queueItem', {
		ids: ids,
		store_id: store_id,
		platform,
	}).find(['_id']);
	return `mutation {${query}}`;
};

export const queueRemoveItemQuery = (args) => {
	const delete_ids = _.get(args, 'ids', []).map((d) => {
		return d.trim();
	});
	const query = new Query('queueRemoveItem', {
		ids: delete_ids,
	}).find(responseField);
	return `mutation {${query}}`;
};

export const removeInternalItemQuery = (args) => {
	const delete_ids = _.get(args, 'delete_ids', []).map((d) => {
		return d.trim();
	});
	const query = new Query('removeItemInfo', {
		ids: delete_ids,
	}).find(['_id']);
	return `mutation {${query}}`;
};
export const convertItemImages = (args) => {
	const query = new Query('convertItemImages', {
		_id: _.get(args, '_id', ''),
		image_id: _.get(args, 'image_id', ''),
		images_remove: _.get(args, 'images_remove', ''),
	}).find(responseField);
	return `mutation {${query}}`;
};
export const removeItemImages = (args) => {
	const query = new Query('removeItemImages', {
		_id: _.get(args, '_id', ''),
		images_remove: _.get(args, 'images_remove', ''),
	}).find(responseField);
	return `mutation {${query}}`;
};
export const makeItemInfo = (args) => {
	const itemInfoFragment = gql`
		fragment Item_a on Item {
			_id
		}
	`;
	let query = gql`
		mutation makeItemInfo(
			$_id: String
			$sku: String
			$item_id: String
			$front: Upload
			$back: Upload
			$sleeve: Upload
			$hood: Upload
			$type: String
			$product_id: String
			$plf_price: Float
			$name: String
			$attributes: [ItemPretyAttributeInput]
			$specifics: [ItemSpecificInput]
			$platform_specifics: [PlatformItemSpecificInput]
			$attribute_specifics: [SpecificAttributeInput]
			$attribute_specifics_modify: [SpecificAttributeInput]
			$description: String
			$images: [String]
			$images_upload: [Upload]
			$tags: [String]
			$collections: [String]
			$size_chart: Upload
			$size_chart_remove: String
			$shipping_preset: String
			$fix_profit: Float
			$fixed_profit: Float
			$tax_fee_fix: Float
			$tax_fee: Float
			$platform_category: [PlatformCategoryInput]
			$include_size_chart: Boolean
			$mockup_id: String
			$weight: Float
			$package_width: Float
			$package_height: Float
			$package_length: Float
			$package_dimension_unit: String
			$extend_images: [String]
		) {
			makeItemInfo(
				_id: $_id
				sku: $sku
				item_id: $item_id
				front: $front
				back: $back
				sleeve: $sleeve
				hood: $hood
				type: $type
				product_id: $product_id
				plf_price: $plf_price
				name: $name
				attributes: $attributes
				specifics: $specifics
				platform_specifics: $platform_specifics
				attribute_specifics: $attribute_specifics
				attribute_specifics_modify: $attribute_specifics_modify
				description: $description
				images: $images
				images_upload: $images_upload
				tags: $tags
				collections: $collections
				size_chart: $size_chart
				size_chart_remove: $size_chart_remove
				shipping_preset: $shipping_preset
				fix_profit: $fix_profit
				fixed_profit: $fixed_profit
				tax_fee_fix: $tax_fee_fix
				tax_fee: $tax_fee
				platform_category: $platform_category
				include_size_chart: $include_size_chart
				mockup_id: $mockup_id
				weight: $weight
				package_width: $package_width
				package_height: $package_height
				package_length: $package_length
				package_dimension_unit: $package_dimension_unit
				extend_images: $extend_images
			) {
				...Item_a
			}
		}
		${itemInfoFragment}
	`;

	const { product } = args;

	const images = _.get(product, 'images', [])
		? _.filter(_.get(product, 'images', []), (img) => _.get(img, 'src', ''))
		: [];
	const extend_images = _.get(product, 'extend_images', [])
		? _.filter(_.get(product, 'extend_images', []), (img) => _.get(img, 'src', ''))
		: [];
	const images_upload = _.flatMapDeep(
		_.filter(_.get(product, 'images', []), (img) => _.get(img, 'file', false)),
		(img) => _.get(img, 'file', '')
	);
	const tags = _.flatMapDeep(_.get(product, 'tags', []), (tag) => tag);
	const collections = _.flatMapDeep(_.get(product, 'collections', []), (tag) => tag);

	let variables = {};
	if (_.get(product, '_id', '') !== undefined) {
		variables._id = _.get(product, '_id', '');
	}
	variables.status = _.get(product, 'status', '') ? _.get(product, 'status', '') : 'Active';
	if (_.get(product, 'name', '') !== undefined) {
		variables.name = _.get(product, 'name', '');
	}
	if (_.get(product, 'sku', '') !== undefined) {
		variables.sku = _.get(product, 'sku', '');
	}
	if (_.get(product, 'item_id', '') !== undefined) {
		variables.item_id = _.get(product, 'item_id', '');
	}
	if (_.get(product, 'front', '') !== undefined) {
		variables.front = _.get(product, 'front', '');
	}
	if (_.get(product, 'back', '') !== undefined) {
		variables.back = _.get(product, 'back', '');
	}
	if (_.get(product, 'sleeve', '') !== undefined) {
		variables.sleeve = _.get(product, 'sleeve', '');
	}
	if (_.get(product, 'hood', '') !== undefined) {
		variables.hood = _.get(product, 'hood', '');
	}
	if (_.get(product, 'type') !== undefined) {
		variables.type = _.get(product, 'product_type') ? String(_.get(product, 'product_type')) : '';
	}
	if (_.get(product, 'product_id') !== undefined && _.get(product, 'product_id')) {
		variables.product_id = String(_.get(product, 'product_id', ''));
	}
	if (_.get(product, 'plf_price') !== undefined) {
		variables.plf_price = parseFloat(_.get(product, 'plf_price'));
	}
	if (_.get(product, 'prety_attributes') !== undefined && _.get(product, 'prety_attributes')) {
		variables.attributes = [
			..._.get(product, 'prety_attributes', []).map((pAttr) => {
				return {
					...pAttr,
					options: _.get(pAttr, 'options', []),
				};
			}),
		];
	}

	if (_.get(product, 'attribute_specifics') !== undefined && _.get(product, 'attribute_specifics')) {
		variables.attribute_specifics = _.get(product, 'attribute_specifics', [])
			? _.get(product, 'attribute_specifics', []).map((aSpec) => {
					delete aSpec.checked;
					delete aSpec.selected;
					return {
						...aSpec,
						image: {
							_id: _.get(aSpec, ['image', '_id'], ''),
							url: _.get(aSpec, ['image', 'url'], ''),
							type: _.get(aSpec, ['image', 'type'], ''),
							image_sizes: _.get(aSpec, ['image', 'image_sizes'], [])
								? _.get(aSpec, ['image', 'image_sizes'], [])
								: [],
						},
						fix_profit: toFloat(_.get(aSpec, 'fix_profit', 1)),
						fixed_profit: toFloat(_.get(aSpec, 'fixed_profit', 0)),
					};
			  })
			: [];
	}

	if (_.get(product, 'attribute_specifics_modify') !== undefined && _.get(product, 'attribute_specifics_modify')) {
		variables.attribute_specifics_modify = _.get(product, 'attribute_specifics_modify', [])
			? _.get(product, 'attribute_specifics_modify', []).map((aSpec) => {
					delete aSpec.checked;
					delete aSpec.selected;
					return {
						...aSpec,
						image: {
							_id: _.get(aSpec, ['image', '_id'], ''),
							url: _.get(aSpec, ['image', 'url'], ''),
							type: _.get(aSpec, ['image', 'type'], ''),
							image_sizes: _.get(aSpec, ['image', 'image_sizes'], [])
								? _.get(aSpec, ['image', 'image_sizes'], [])
								: [],
						},
						fix_profit: toFloat(_.get(aSpec, 'fix_profit', 1)),
						fixed_profit: toFloat(_.get(aSpec, 'fixed_profit', 0)),
					};
			  })
			: [];
	}
	if (_.get(product, 'specifics', []) !== undefined) {
		variables.specifics = Array.isArray(_.get(product, 'specifics', []))
			? _.get(product, 'specifics', []).map((spec) => {
					return {
						key: _.get(spec, 'key', ''),
						value: String(_.get(spec, 'value', '')),
						required: String(_.get(spec, 'required', '')),
					};
			  })
			: [];
	}
	if (_.get(product, 'platform_specifics', []) !== undefined) {
		variables.platform_specifics = _.map(_.get(product, 'platform_specifics', []), (platform) => {
			return {
				platform: _.get(platform, 'platform', ''),
				specifics: _.get(platform, 'specifics', []).map((spec) => {
					return {
						key: _.get(spec, 'key', ''),
						value: String(_.get(spec, 'value', '')),
						required: String(_.get(spec, 'required', '')),
					};
				}),
			};
		});
	}
	if (_.get(product, 'description', []) !== undefined) {
		variables.description = _.get(product, 'description', '');
	}
	if (images !== undefined && images) {
		variables.images = _.map(images, (image) => _.get(image, 'src'));
	}
	if (extend_images !== undefined && extend_images) {
		variables.extend_images = _.map(extend_images, (image) => _.get(image, 'src'));
	}
	variables.images_upload = images_upload;
	if (tags !== undefined) {
		variables.tags = tags;
	}
	if (collections !== undefined) {
		variables.collections = collections;
	}
	if (_.get(product, 'shipping_preset') !== undefined) {
		variables.shipping_preset = _.get(product, 'shipping_preset', '');
	}
	if (_.get(product, 'fix_profit') !== undefined) {
		variables.fix_profit = toFloat(_.get(product, 'fix_profit', ''));
	}
	if (_.get(product, 'fixed_profit') !== undefined) {
		variables.fixed_profit = toFloat(_.get(product, 'fixed_profit', ''));
	}
	if (_.get(product, 'tax_fee_fix') !== undefined) {
		variables.tax_fee_fix = toFloat(_.get(product, 'tax_fee_fix', ''));
	}
	if (_.get(product, 'tax_fee') !== undefined) {
		variables.tax_fee = toFloat(_.get(product, 'tax_fee', ''));
	}
	if (_.get(product, 'include_size_chart') !== undefined) {
		variables.include_size_chart = _.get(product, 'include_size_chart', false);
	}
	if (_.get(product, 'platform_category', []) !== undefined) {
		variables.platform_category = _.map(_.get(product, 'platform_category', []), (pCate) => {
			return {
				...pCate,
				category_selected: _.map(_.get(pCate, 'category_selected', []), (cateSelect) => {
					return {
						...cateSelect,
						parent: _.get(cateSelect, 'parent', null) === null ? '' : _.get(cateSelect, 'parent', null),
					};
				}),
			};
		});
	}

	variables = {
		...variables,
		...(() => {
			if (typeof _.get(product, 'size_chart', '') !== 'string') {
				return { size_chart: _.get(product, 'size_chart', {}) };
			}
			return {};
		})(),
		...(() => {
			if (_.get(product, 'size_chart', {}) === 'remove') {
				return { size_chart_remove: 'remove' };
			}
			if (typeof _.get(product, 'size_chart', {}) === 'string') {
				return { size_chart_remove: _.get(product, 'size_chart', '') };
			}
			return {};
		})(),
	};

	if (_.get(product, 'mockup_id', false)) {
		variables.mockup_id = _.get(product, 'mockup_id', '');
	}

	if (_.get(product, 'weight', false)) {
		variables.weight = parseFloat(_.get(product, 'weight', 0));
	}

	if (_.get(product, 'package_width', false)) {
		variables.package_width = parseFloat(_.get(product, 'package_width', 0));
	}

	if (_.get(product, 'package_height', false)) {
		variables.package_height = parseFloat(_.get(product, 'package_height', 0));
	}

	if (_.get(product, 'package_length', false)) {
		variables.package_length = parseFloat(_.get(product, 'package_length', 0));
	}

	if (_.get(product, 'package_dimension_unit', false)) {
		variables.package_dimension_unit = parseFloat(_.get(product, 'package_dimension_unit', 0));
	}

	return {
		operationName: 'makeItemInfo',
		query: query.loc.source.body,
		variables,
	};
};

export const bulkItemInfo = (args) => {
	const query = new Query('bulkItemInfo', {
		shipping_preset: _.get(args, 'shipping_preset', ''),
		product_id: _.get(args, 'product_id', ''),
		custom_preset_id: _.get(args, 'custom_preset_id', ''),
		size_chart: _.get(args, 'size_chart', ''),
		...(() =>
			typeof args.price !== 'undefined' && args.price !== '' && args.price
				? { price: parseFloat(_.get(args, 'price', '')) }
				: {})(),
		...(() =>
			typeof args.include_size_chart !== 'undefined'
				? { include_size_chart: !!_.get(args, 'include_size_chart', false) }
				: {})(),
		...(() =>
			typeof args.include_extend_images !== 'undefined'
				? { include_extend_images: !!_.get(args, 'include_extend_images', false) }
				: {})(),
		size_chart_remove: _.get(args, 'size_chart_remove', ''),
		...(() => (typeof args.tags !== 'undefined' ? { tags: _.get(args, 'tags', []) } : {}))(),
		...(() => (typeof args.collections !== 'undefined' ? { collections: _.get(args, 'collections', []) } : {}))(),
		...(() => (typeof args.specifics !== 'undefined' ? { specifics: _.get(args, 'specifics', []) } : {}))(),
		...(() =>
			typeof args.platform_specifics !== 'undefined'
				? { platform_specifics: _.get(args, 'platform_specifics', []) }
				: {})(),
		...(() =>
			typeof args.platform_category !== 'undefined'
				? {
						platform_category: _.map(_.get(args, 'platform_category', []), (pCate) => {
							return {
								...pCate,
								category_selected: _.map(_.get(pCate, 'category_selected', []), (cateSelect) => {
									return {
										...cateSelect,
										parent:
											_.get(cateSelect, 'parent', null) === null
												? ''
												: _.get(cateSelect, 'parent', null),
									};
								}),
							};
						}),
				  }
				: {})(),
		description: _.get(args, 'description', ''),
		params: _.map(_.get(args, 'items', []), (item) => {
			return {
				_id: _.get(item, '_id', ''),
				name: _.get(item, 'name', ''),
				description_ai: _.get(item, 'description_ai', '') || '',
				description_ai_pos: _.get(item, 'description_ai_pos', '') || '',
			};
		}),
		update_item_sku: _.get(args, 'update_item_sku', false),
	}).find(responseField);
	return `mutation {${query}}`;
};
