import { getCookie, setCookie, delCookie } from './../../actions/cookie/cookie';
import _ from 'lodash';

const hasRole = (role, user) => {
	const userGroups = _.get(user, 'groups', []) || [];
	if (Array.isArray(role)) {
		return (
			_.find(role, (r) => _.includes(userGroups, r)) ||
			_.includes(role, user.role) ||
			_.includes(userGroups, 'super_root')
		);
	}
	return (
		_.includes(userGroups, role) ||
		user.role === role ||
		user.role === 'super_root' ||
		_.includes(userGroups, 'super_root')
	);
};
const hasRoles = (roles, user) => {
	const userGroups = _.get(user, 'groups', []) || [];
	if (Array.isArray(roles)) {
		return (
			typeof _.find(roles, (r) => !_.includes(userGroups, r)) === 'undefined' || _.includes(userGroups, 'super_root')
		);
	}
	return (
		_.includes(userGroups, roles) ||
		user.role === roles ||
		user.role === 'super_root' ||
		_.includes(userGroups, 'super_root')
	);
};

export const login = (state = { token: getCookie('_token'), userRole: getCookie('_rl'), loggedInUser: {} }, action) => {
	switch (action.type) {
		case '@@INIT': {
			return {
				...state,
				hasRole: (r) => hasRole(r, {}),
				hasRoles: (r) => hasRoles(r, {}),
			};
		}
		case 'LOGIN_WITH_EMAIL': {
			const token = _.get(action, ['payload', 'loggedInUser', 'token'], '');
			const role = _.get(action, ['payload', 'loggedInUser', 'role'], '');
			const uid = _.get(action, ['payload', 'loggedInUser', '_id'], '');
			// const total_credit = _.get(action, ["payload", "loggedInUser", "total_credit"], "0.00")
			const permissions = _.get(action, ['payload', 'loggedInUser', 'permission'], null);
			setCookie('_token', token);
			setCookie('_uid', uid);
			delCookie('shopify_shop_info');
			const user = _.cloneDeep(action.payload.loggedInUser);
			delete action.payload.loggedInUser.token;
			delete action.payload.loggedInUser.role;
			delete action.payload.loggedInUser.permission;
			return {
				...state,
				...action.payload,
				token: token,
				warning_ip: false,
				userRole: role,
				userPermission: permissions,
				hasRole: (r) => hasRole(r, user),
				hasRoles: (r) => hasRoles(r, user),
			};
		}
		case 'LOGOUT': {
			delCookie('_token');
			delCookie('_rl');
			delCookie('_uid');
			delCookie('shopify_shop_info');
			return { ...state, ...action.payload, token: null, loggedInUser: {}, warning_ip: false };
		}
		case 'WRONG_TOKEN': {
			return { ...state, token: null, loggedInUser: {} };
		}
		case 'WARNING_IP': {
			return { ...state, warning_ip: true };
		}
		case 'LOGIN_WITH_TOKEN': {
			const token = _.get(action, ['payload', 'loggedInUser', 'token'], '');
			const role = _.get(action, ['payload', 'loggedInUser', 'role'], '');
			const groups = _.get(action, ['payload', 'loggedInUser', 'groups'], '');
			const uid = _.get(action, ['payload', 'loggedInUser', '_id'], '');
			const total_credit = _.get(action, ['payload', 'loggedInUser', 'total_credit'], null);
			const permissions = _.get(action, ['payload', 'loggedInUser', 'permission'], '');
			setCookie('_token', token);
			setCookie('_uid', uid);
			const user = _.cloneDeep(action.payload.loggedInUser);
			delete action.payload.loggedInUser.token;
			delete action.payload.loggedInUser.role;
			delete action.payload.loggedInUser.permission;
			return {
				...state,
				...action.payload,
				totalCredit: total_credit,
				token: token,
				userRole: role,
				userGroups: groups,
				userPermission: permissions,
				hasRole: (r) => hasRole(r, user),
				hasRoles: (r) => hasRoles(r, user),
			};
		}
		case 'NOTIFICATION_COUNT': {
			return {
				...state,
				...action.payload,
			};
		}
		// case "LOGIN_WITH_JWT": {
		//   const token = _.get(action, ['payload', 'token'], '')
		//   const role = _.get(action, ['payload', 'loggedInUser', 'role'], '')
		//   const uid = _.get(action, ['payload', 'loggedInUser', 'id'], '')
		//   const permissions = _.get(action, ['payload', 'loggedInUser', 'permission'], '')
		//   setCookie('_token', token)
		//   setCookie('_uid', uid)
		//   return { ...state, ...action.payload, token: token, userRole: role, userPermission: permissions }
		// }

		case 'CHANGE_INFO': {
			const token = _.get(action, ['payload', 'loggedInUser', 'token'], '');
			const uid = _.get(action, ['payload', 'loggedInUser', '_id'], '');
			const permissions = _.get(action, ['payload', 'loggedInUser', 'permission'], null);
			const user = _.cloneDeep(action.payload.loggedInUser);
			setCookie('_token', token);
			setCookie('_uid', uid);
			delete action.payload.loggedInUser.token;
			delete action.payload.loggedInUser.role;
			delete action.payload.loggedInUser.permission;
			return {
				...state,
				...action.payload,
				token: token,
				// userRole: role,
				userPermission: permissions,
				hasRole: (r) => hasRole(r, user),
				hasRoles: (r) => hasRoles(r, user),
			};
		}
		case 'LOGIN_FAIL': {
			return { ...state, ...action.payload };
		}
		default: {
			return state;
		}
	}
};
