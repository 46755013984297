import _ from 'lodash';
import { responseField as fulfilmentResponseField } from './fulfilment.query';
import { responseField as storeResponseField } from './store.query';
const Query = require('graphql-query-builder');

export const responseField = ['waiting', 'pending', 'rejected', 'total'];

export const responseFieldGroup = [
	'_id',
	'created_at',
	'job_type',
	{
		store: storeResponseField,
	},
	{
		fulfilment: fulfilmentResponseField,
	},
	{
		job: responseField,
	},
];

export const responseFieldForListDetail = [
	'_id',
	'created_at',
	'job_type',
	{
		store: storeResponseField,
	},
	{
		order: ['_id', 'identity'],
	},
	{
		fulfilment: fulfilmentResponseField,
	},
	'data',
	'pending',
	{
		msg: ['code', 'msg'],
	},
];

export const jobGroupQuery = (args) => {
	const query = new Query('jobGroups', {
		search: _.get(args, 'search', ''),
		type: _.get(args, 'type', ''),
		order: _.get(args, 'order', ''),
	}).find(responseFieldGroup);
	return `query {${query}}`;
};

export const jobListQuery = (args) => {
	const query = new Query('jobslist', {
		created_at: _.get(args, 'created_at', ''),
		store_id: _.get(args, 'store_id', ''),
		fulfilment_id: _.get(args, 'fulfilment_id', ''),
		type: _.get(args, 'type', ''),
		// status: _.get(args, 'status', [])
	}).find(responseFieldForListDetail);
	return `query {${query}}`;
};

export const jobStartQuery = (args) => {
	const query = new Query('jobsStart', {
		_id: _.get(args, '_id', '').includes(_.get(args, 'created_at', '')) ? null : _.get(args, '_id', ''),
		created_at: _.get(args, 'created_at', ''),
		store_id: _.get(args, 'store_id', ''),
		fulfilment_id: _.get(args, 'fulfilment_id', ''),
		type: _.get(args, 'type', ''),
	}).find(responseFieldGroup);
	return `mutation {${query}}`;
};

export const jobStopQuery = (args) => {
	const query = new Query('jobsStop', {
		_id: _.get(args, '_id', '').includes(_.get(args, 'created_at', '')) ? null : _.get(args, '_id', ''),
		created_at: parseInt(_.get(args, 'created_at', '')),
		store_id: _.get(args, 'store_id', ''),
		fulfilment_id: _.get(args, 'fulfilment_id', ''),
		type: _.get(args, 'type', ''),
	}).find(responseFieldGroup);
	return `mutation {${query}}`;
};

export const jobRemoveGroupQuery = (args) => {
	const delete_ids = _.get(args, 'delete_ids', []).map((d) => {
		const idArr = d.split('_');
		const time = _.head(idArr);
		const store = _.get(idArr, 1, '');
		const type = _.get(idArr, 2, '');
		return {
			created_at: parseInt(time),
			store_id: store,
			type: type,
		};
	});
	const query = new Query('jobsRemove', {
		ids: delete_ids,
	}).find(['code', 'msg']);
	return `mutation {${query}}`;
};
export const jobRemoveQuery = (args) => {
	const delete_ids = _.get(args, 'delete_ids', []).map((d) => {
		return { _id: d };
	});
	const query = new Query('jobsRemove', {
		ids: delete_ids,
	}).find(['code', 'msg']);
	return `mutation {${query}}`;
};

export const jobQuantityItemQuery = (args) => {
	const query = new Query('updateQuantityItem', {
		platform_ids: _.get(args, 'platform_ids', ''),
		store_id: _.get(args, 'store_id', ''),
		quantity: parseInt(_.get(args, 'quantity', 0)),
	}).find(responseFieldForListDetail);
	return `mutation {${query}}`;
};

export const jobOptimizeItemQuery = (args) => {
	const query = new Query('liveOptimizeItem', {
		platform_ids: _.get(args, 'platform_ids', ''),
		store_id: _.get(args, 'store_id', ''),
	}).find(responseFieldForListDetail);
	return `mutation {${query}}`;
};

export const jobPaypalItemQuery = (args) => {
	const query = new Query('updatePaypalItem', {
		ids: _.get(args, 'ids', ''),
		store_id: _.get(args, 'store', ''),
		email: _.get(args, 'email', ''),
	}).find(responseFieldForListDetail);
	return `mutation {${query}}`;
};

export const jobPushIssuu = (args) => {
	const query = new Query('pushIssuu', {
		ids: _.get(args, 'ids', ''),
	}).find(responseFieldForListDetail);
	return `mutation {${query}}`;
};
