import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import * as Icon from 'react-feather';
import classnames from 'classnames';
import Autocomplete from '../../../components/@vuexy/autoComplete/AutoCompleteComponent';
import { logoutWithJWT } from './../../../redux/actions/auth/logoutActions';
import themeConfig from '../../../configs/themeConfig';
import { history } from '../../../history';

class NavbarHome extends React.PureComponent {
	state = {
		navbarSearch: false,
		suggestions: [],
	};
	handleNavbarSearch = () => {
		this.setState({
			navbarSearch: !this.state.navbarSearch,
		});
	};

	render() {
		return (
			<ul className="nav navbar-nav navbar-nav-user float-right">
				<NavItem className="nav-search" onClick={this.handleNavbarSearch}>
					<NavLink className="nav-link-search">
						<Icon.Search size={21} data-tour="search" />
					</NavLink>
					<div
						className={classnames('search-input', {
							open: this.state.navbarSearch,
							'd-none': this.state.navbarSearch === false,
						})}
					>
						<div className="search-input-icon">
							<Icon.Search size={17} className="primary" />
						</div>
						<Autocomplete
							className={`form-control bg-${themeConfig.navbarColor}`}
							suggestions={this.state.suggestions}
							filterKey="title"
							filterHeaderKey="groupTitle"
							grouped={true}
							placeholder="Order, Item, ..."
							autoFocus={true}
							clearInput={this.state.navbarSearch}
							externalClick={(e) => {
								this.setState({ navbarSearch: false });
							}}
							onKeyDown={(e) => {
								if (e.keyCode === 27 || e.keyCode === 13) {
									this.setState({
										navbarSearch: false,
									});
									this.props.handleAppOverlay('');
								}
							}}
							customRender={(
								item,
								i,
								filteredData,
								activeSuggestion,
								onSuggestionItemClick,
								onSuggestionItemHover
							) => {
								const IconTag = Icon[item.icon ? item.icon : 'X'];
								return (
									<li
										className={classnames('suggestion-item', {
											active: filteredData.indexOf(item) === activeSuggestion,
										})}
										key={i}
										onClick={(e) => onSuggestionItemClick(item.link, e)}
										onMouseEnter={() => onSuggestionItemHover(filteredData.indexOf(item))}
									>
										<div
											className={classnames({
												'd-flex justify-content-between align-items-center':
													item.file || item.img,
											})}
										>
											<div className="item-container d-flex">
												{item.icon ? (
													<IconTag size={17} />
												) : item.file ? (
													<img src={item.file} height="36" width="28" alt={item.title} />
												) : item.img ? (
													<img
														className="rounded-circle mt-25"
														src={item.img}
														height="28"
														width="28"
														alt={item.title}
													/>
												) : null}
												<div className="item-info ml-1">
													<p className="align-middle mb-0">{item.title}</p>
													{item.by || item.email ? (
														<small className="text-muted">
															{item.by ? item.by : item.email ? item.email : null}
														</small>
													) : null}
												</div>
											</div>
											{item.size || item.date ? (
												<div className="meta-container">
													<small className="text-muted">
														{item.size ? item.size : item.date ? item.date : null}
													</small>
												</div>
											) : null}
										</div>
									</li>
								);
							}}
							onSuggestionsShown={(userInput) => {
								if (this.state.navbarSearch) {
									this.props.handleAppOverlay(userInput);
								}
							}}
						/>
						<div className="search-input-close">
							<Icon.X
								size={24}
								onClick={(e) => {
									e.stopPropagation();
									this.setState({
										navbarSearch: false,
									});
									this.props.handleAppOverlay('');
								}}
							/>
						</div>
					</div>
				</NavItem>
				<NavItem>
					<NavLink className="nav-link-search" href={history.loginUrl()}>
						<Icon.User size={21} data-tour="login" />
						<span className="d-sm-inline d-none pt-1 pl-1">Sign in</span>
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className="nav-link-search" href={history.registerUrl()}>
						<Icon.UserPlus size={21} data-tour="register" />
						<span className="d-sm-inline d-none pt-1 pl-1">Sign up</span>
					</NavLink>
				</NavItem>
			</ul>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		user: state.auth.login.userRole,
		getUserById: state.auth.login.loggedInUser,
	};
};
export default connect(mapStateToProps, { logoutWithJWT })(NavbarHome);
