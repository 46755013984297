import _ from 'lodash';
const Query = require('graphql-query-builder');

const addressField = [
	'first_name',
	'last_name',
	'company',
	'address_1',
	'address_2',
	'city',
	'state',
	'postcode',
	'country',
	'email',
	'phone',
	'owner',
	'need_check_address',
];

export const responseField = [
	'_id',
	'platform_id',
	'platform_name',
	'platform_open_id',
	'sto_name',
	'sto_user',
	'sto_status',
	'sto_auto',
	'sto_auto_messenger',
	'sto_auto_order',
	'sto_auto_fulfilment',
	'sto_auto_fulfilment_delay',
	'sto_ebay_payment',
	'sto_created_at',
	'sto_updated_at',
	'fetched_at',
	'sto_type',
	'sto_mailer',
	'sto_mailer_secret',
	'brand_name',
	'paypal_email',
	'dispatch_time_max',
	'shipping_time_max',
	'location',
	'location_detail',
	'country',
	'shipping_service',
	'shipping_cost',
	'shipping_additional_cost',
	'return_accept',
	'global_shipping',
	'international_shipping_time_max',
	'international_location',
	'international_shipping_service',
	'international_shipping_cost',
	'international_shipping_additional_cost',
	'international_return_accept',
	'default_quantity',
	'private_note',
	'schedule',
	'schedule_fetch',
	'ignore_publish',
	'last_total_product',
	'last_push_item_at',
	'total_order',
	{
		sto_woocommerce_config: ['website', 'key', 'secret'],
	},
	{
		sto_ebay_config: ['expires_in'],
	},
	{
		sto_merchize_config: ['url', 'access_token'],
	},
	{
		sto_wish_config: ['merchant_id', 'client_id', 'client_secret', 'access_token'],
	},
	{
		sto_etsy_config: [
			'etsy_keystring',
			'etsy_shared_secret',
			'etsy_outh_token',
			'etsy_outh_token_secret',
			'etsy_trust_link',
			'shop_id',
			'user_id',
			'login_name',
			'who_made',
			'is_supply',
			'product_state',
			'when_made',
		],
	},
	{
		sto_etsy_config_v3: [
			'code',
			'code_verifier',
			'access_token',
			'expires_in',
			'refresh_token',
			'refresh_token_expires_in',
		],
	},
	{
		sto_bigcommerce_config: [
			'client_id',
			'client_secret',
			'store_hash',
			'access_token',
			'scope',
			'context',
			'url',
			'status',
			'secure_url',
			'api_path',
			'admin_email',
			'order_email',
			'access_token',
			'client_id',
			'client_secret',
		],
	},
	{
		sto_shopify_config: ['website', 'apiKey', 'apiSecretKey'],
	},
	'limit',
	'product_header',
	'product_footer',
	'product_style',
	'ignore_watermark',
	'paypal_emails',
	{
		etsy_shipping: [
			'shipping_profile_id',
			'origin_country_id',
			'dispatch_time_min',
			'dispatch_time_max',
			{
				default_shipping: ['country_id', 'shipping_cost', 'shipping_additional_cost'],
			},
			{
				standard_shipping: ['country_id', 'shipping_cost', 'shipping_additional_cost'],
			},
		],
	},
	'ebay_template_id',
	'banner_image_id',
	'warning_level',
	'schedule_days',
	{
		user_owners: ['_id', 'email'],
	},
	'max_per_day',
	'max_per_week',
	'max_per_month',
	'item_per_day',
	'item_per_week',
	'item_per_month',
	'shipping_profile_id',
	'sto_amazon_type',
	{
		transaction_summary: [
			{
				funds_on_hold: ['value', 'currency'],
			},
			{
				funds_available_for_payout: ['value', 'currency'],
			},
			{
				funds_processing: ['value', 'currency'],
			},
		],
	},
	'transaction_summary_at',
	'dead_state',
	{
		sto_tiktok_config: [
			'expires_in',
			'token',
			{
				shop: ['region', 'shop_id', 'shop_name', 'type'],
			},
			{
				shop_list: ['region', 'shop_id', 'shop_name', 'type', 'shop_cipher'],
			},
		],
	},
	'sto_tracking_delay',
	'issuu_id',
	'issuu_secret',
	'issuu_token',
	'issuu_description',
	'issuu_auto',
	'issuu_limit',
	{
		address: addressField,
	},
	'full_address',
	{
		invoice_id: ['date', 'id'],
	},
];

export const storeQuery = (args) => {
	const query = new Query('stores', {
		id: _.get(args, 'id', ''),
		sto_name: _.get(args, 'name', ''),
		sto_status: _.get(args, 'status', ''),
		sto_type: _.get(args, 'type', ''),
	}).find(responseField);
	return `query {${query}}`;
};

export const storeTransactionSummaryQuery = (args) => {
	const query = new Query('storeTransactionSummaryLive', {
		id: _.get(args, 'id', ''),
	}).find(responseField);
	return `query {${query}}`;
};

export const storeCreateQuery = (args) => {
	let params = {};
	let config = {};
	if (_.get(args, 'name', false)) {
		params['sto_name'] = _.get(args, 'name', false);
	}
	if (_.get(args, 'brand_name', false)) {
		params['brand_name'] = _.get(args, 'brand_name', false);
	}
	if (
		_.get(args, 'website', false) ||
		_.get(args, 'consummberKey', false) ||
		_.get(args, 'consummberSecret', false)
	) {
		config['sto_woocommerce_config'] = {
			website: _.get(args, 'website', ''),
			key: _.get(args, 'consummberKey', ''),
			secret: _.get(args, 'consummberSecret', ''),
		};
	}

	if (_.get(args, 'sto_amazon_config', false)) {
		config['sto_amazon_config'] = {
			type: _.get(args, ['sto_amazon_config', 'type'], ''),
		};
	}
	if (_.get(args, 'merchize_url', false) || _.get(args, 'merchize_access_token', false)) {
		config['sto_merchize_config'] = {
			url: _.get(args, 'merchize_url', ''),
			access_token: _.get(args, 'merchize_access_token', ''),
		};
	}
	if (_.get(args, 'code', false) && _.get(args, 'platform', '') === 'Tiktok') {
		config['sto_tiktok_config'] = {
			code: _.get(args, 'code', ''),
			shop_id: _.get(args, 'shop_id', ''),
		};
	} else if (_.get(args, 'code', false)) {
		config['sto_ebay_config'] = {
			code: _.get(args, 'code', ''),
		};
	}
	if (_.get(args, 'sto_wish_config', false)) {
		config['sto_wish_config'] = { ..._.get(args, 'sto_wish_config', {}) };
	}
	//Store Shopify
	if (
		_.get(args, 'shopName', false) &&
		((_.get(args, 'apiKey', false) && _.get(args, 'apiSecretKey', false)) || _.get(args, 'accessToken', false))
	) {
		config['sto_shopify_config'] = {};
		config['sto_shopify_config']['apiKey'] = _.get(args, 'apiKey', false);
		config['sto_shopify_config']['apiSecretKey'] = _.get(args, 'apiSecretKey', false);
		config['sto_shopify_config']['shopName'] = _.get(args, 'shopName', false);
		config['sto_shopify_config']['accessToken'] = _.get(args, 'accessToken', false);
		params['sto_name'] = _.get(args, 'shopName', false);
		if (_.get(args, 'shopifyWebsite', false)) {
			config['sto_shopify_config']['website'] = _.get(args, 'shopifyWebsite', false);
		} else {
			config['sto_shopify_config']['website'] =
				'https://' + config['sto_shopify_config']['shopName'] + '.myshopify.com';
		}
	}
	//Store BigCommerce
	if (_.get(args, 'bigcommerceClientId', false) && _.get(args, 'bigcommerceAccessToken', false)) {
		config['sto_bigcommerce_config'] = {};
		config['sto_bigcommerce_config']['client_id'] = _.get(args, 'bigcommerceClientId', '');
		config['sto_bigcommerce_config']['client_secret'] = _.get(args, 'bigcommerceSecretId', '');
		config['sto_bigcommerce_config']['access_token'] = _.get(args, 'bigcommerceAccessToken', '');
		config['sto_bigcommerce_config']['api_path'] = _.get(args, 'bigcommerceApiPath', '');
		params['sto_name'] = '';
	}
	//Create Store Etsy
	if (_.get(args, 'etsy_keystring', false) && _.get(args, 'etsy_shared_secret', false)) {
		config['sto_etsy_config'] = {};
		config['sto_etsy_config']['etsy_keystring'] = _.get(args, 'etsy_keystring', false);
		config['sto_etsy_config']['etsy_shared_secret'] = _.get(args, 'etsy_shared_secret', false);
	}
	//Update Store Etsy
	if (
		_.get(args, 'etsy_shopname', false) ||
		_.get(args, 'who_made', false) ||
		_.get(args, 'is_supply', false) ||
		_.get(args, 'product_state', false) ||
		_.get(args, 'when_made', false)
	) {
		if (_.get(args, 'sto_etsy_config', false)) {
			config['sto_etsy_config'] = _.get(args, 'sto_etsy_config', {});
		} else {
			config['sto_etsy_config'] = {};
		}
		if (_.get(args, 'who_made', false)) {
			config['sto_etsy_config']['who_made'] = _.get(args, 'who_made', false);
		}
		if (_.get(args, 'is_supply', false)) {
			config['sto_etsy_config']['is_supply'] = _.get(args, 'is_supply', false);
		}
		if (_.get(args, 'product_state', false)) {
			config['sto_etsy_config']['product_state'] = _.get(args, 'product_state', false);
		}
		if (_.get(args, 'when_made', false)) {
			config['sto_etsy_config']['when_made'] = _.get(args, 'when_made', false);
		}
		if (_.get(args, 'etsy_shopname', false)) {
			params['sto_name'] = _.get(args, 'etsy_shopname', false);
		}
	}
	if (config) {
		params['params'] = config;
	}
	// add template id
	if (_.get(args, 'ebay_template_id', false)) {
		params['ebay_template_id'] = _.get(args, 'ebay_template_id', {});
	}
	if (_.get(args, 'banner_image_id', false)) {
		params['banner_image_id'] = _.get(args, 'banner_image_id', {});
	}
	if (_.get(args, 'default_quantity', false)) {
		params['default_quantity'] = _.get(args, 'default_quantity', false);
	}

	const query = new Query('makeStore', params).find(responseField);
	return `mutation {${query}}`;
};

export const storeUpdateQuery = (args) => {
	let params = { id: _.get(args, '_id', '') };
	let config = {};
	if (_.get(args, 'name', false)) {
		params['sto_name'] = _.get(args, 'name', false);
	}
	if (_.get(args, 'user', false)) {
		params['sto_user'] = _.get(args, 'user', false);
	}
	if (_.get(args, 'status', false)) {
		params['sto_status'] = _.get(args, 'status', false);
	}
	if (_.get(args, 'sto_auto_messenger', false)) {
		params['sto_auto_messenger'] = _.get(args, 'sto_auto_messenger', false);
	}
	if (_.get(args, 'sto_auto_order', false)) {
		params['sto_auto_order'] = _.get(args, 'sto_auto_order', false);
	}

	if (_.get(args, 'sto_auto_fulfilment', false)) {
		params['sto_auto_fulfilment'] = _.get(args, 'sto_auto_fulfilment', false);
	}
	if (_.get(args, 'sto_auto_fulfilment_delay', false)) {
		params['sto_auto_fulfilment_delay'] = _.get(args, 'sto_auto_fulfilment_delay', false);
	}
	if (typeof _.get(args, 'sto_tracking_delay') !== 'undefined') {
		params['sto_tracking_delay'] =
			parseInt(_.get(args, 'sto_tracking_delay', 0)) >= 0 ? parseInt(_.get(args, 'sto_tracking_delay', 0)) : 0;
	}
	if (_.get(args, 'sto_ebay_payment', false) !== false) {
		params['sto_ebay_payment'] = _.get(args, 'sto_ebay_payment', false);
	}
	if (_.get(args, 'auto', false)) {
		params['sto_auto'] = _.get(args, 'auto', false);
	}
	if (_.get(args, 'mailer', false)) {
		params['sto_mailer'] = _.get(args, 'mailer', false);
	}
	if (_.get(args, 'mailer_secret', false)) {
		params['sto_mailer_secret'] = _.get(args, 'mailer_secret', false);
	}
	if (_.get(args, 'brand_name', false)) {
		params['brand_name'] = _.get(args, 'brand_name', false);
	}
	if (_.get(args, 'paypal_email', false)) {
		params['paypal_email'] = _.get(args, 'paypal_email', false);
	}
	if (_.get(args, 'dispatch_time_max', false)) {
		params['dispatch_time_max'] = _.get(args, 'dispatch_time_max', false);
	}
	if (_.get(args, 'shipping_time_max', false)) {
		params['shipping_time_max'] = _.get(args, 'shipping_time_max', false);
	}
	if (_.get(args, 'international_shipping_time_max', false)) {
		params['international_shipping_time_max'] = _.get(args, 'international_shipping_time_max', false);
	}

	if (_.get(args, 'location', false)) {
		params['location'] = _.get(args, 'location', false);
	}
	if (_.get(args, 'location_detail', false)) {
		params['location_detail'] = _.get(args, 'location_detail', false);
	}
	if (_.get(args, 'country', false)) {
		params['country'] = _.get(args, 'country', false);
	}
	if (_.get(args, 'shipping_service', false)) {
		params['shipping_service'] = _.get(args, 'shipping_service', false);
	}
	if (_.get(args, 'shipping_cost', false)) {
		params['shipping_cost'] = _.get(args, 'shipping_cost', false);
	}
	if (_.get(args, 'shipping_additional_cost', false)) {
		params['shipping_additional_cost'] = _.get(args, 'shipping_additional_cost', false);
	}
	if (_.get(args, 'return_accept', false)) {
		params['return_accept'] = _.get(args, 'return_accept', false);
	}
	if (_.get(args, 'global_shipping', false)) {
		params['global_shipping'] = _.get(args, 'global_shipping', false);
	}
	if (_.get(args, 'international_location', false)) {
		params['international_location'] = _.get(args, 'international_location', false);
	}
	if (_.get(args, 'international_shipping_service', false)) {
		params['international_shipping_service'] = _.get(args, 'international_shipping_service', false);
	}
	if (_.get(args, 'international_shipping_cost', false)) {
		params['international_shipping_cost'] = _.get(args, 'international_shipping_cost', false);
	}
	if (_.get(args, 'international_shipping_additional_cost', false)) {
		params['international_shipping_additional_cost'] = _.get(args, 'international_shipping_additional_cost', false);
	}
	if (_.get(args, 'international_return_accept', false)) {
		params['international_return_accept'] = _.get(args, 'international_return_accept', false);
	}
	if (_.get(args, 'shipping_profile_id', false) !== false) {
		params['shipping_profile_id'] = _.get(args, 'shipping_profile_id', false);
	}
	if (_.get(args, 'schedule', false)) {
		params['schedule'] = parseInt(_.get(args, 'schedule', false));
	}
	if (_.get(args, 'schedule_fetch', false)) {
		params['schedule_fetch'] = parseInt(_.get(args, 'schedule_fetch', false));
	}
	if (_.get(args, 'SMTPStatus', false)) {
		params['SMTPStatus'] = _.get(args, 'SMTPStatus', false);
	}
	if (_.get(args, 'SMTPHost', false)) {
		params['SMTPHost'] = _.get(args, 'SMTPHost', false);
	}

	if (_.get(args, 'SMTPPort', false)) {
		params['SMTPPort'] = _.get(args, 'SMTPPort', false);
	}

	if (_.get(args, 'SMTPUser', false)) {
		params['SMTPUser'] = _.get(args, 'SMTPUser', false);
	}

	if (_.get(args, 'SMTPPassword', false)) {
		params['SMTPPassword'] = _.get(args, 'SMTPPassword', false);
	}

	if (_.get(args, 'SMTPSender', false)) {
		params['SMTPSender'] = _.get(args, 'SMTPSender', false);
	}

	if (_.get(args, 'default_quantity', false)) {
		params['default_quantity'] = _.get(args, 'default_quantity', false);
	}

	if (_.get(args, 'private_note', false)) {
		params['private_note'] = _.get(args, 'private_note', false);
	}
	if (_.get(args, 'product_header', false) !== false) {
		params['product_header'] = _.get(args, 'product_header', '');
	}
	if (_.get(args, 'product_footer', false) !== false) {
		params['product_footer'] = _.get(args, 'product_footer', '');
	}
	if (_.get(args, 'product_style', false) !== false) {
		params['product_style'] = _.get(args, 'product_style', '');
	}
	if (typeof _.get(args, 'ignore_watermark', undefined) !== 'undefined') {
		params['ignore_watermark'] = _.get(args, 'ignore_watermark', true);
	}
	if (typeof _.get(args, 'ignore_publish', undefined) !== 'undefined') {
		params['ignore_publish'] = _.get(args, 'ignore_publish', true);
	}

	if (_.get(args, 'schedule_days', false) !== false) {
		params['schedule_days'] = _.get(args, 'schedule_days', []);
	}

	if (_.get(args, 'max_per_day', false) !== false) {
		params['max_per_day'] = parseInt(_.get(args, 'max_per_day', 0)) || 0;
	}

	if (_.get(args, 'max_per_week', false) !== false) {
		params['max_per_week'] = parseInt(_.get(args, 'max_per_week', 0)) || 0;
	}

	if (_.get(args, 'max_per_month', false) !== false) {
		params['max_per_month'] = parseInt(_.get(args, 'max_per_month', 0)) || 0;
	}

	if (_.get(args, 'issuu_id', false)) {
		params['issuu_id'] = _.get(args, 'issuu_id', 0);
	}

	if (_.get(args, 'issuu_secret', false)) {
		params['issuu_secret'] = _.get(args, 'issuu_secret', 0);
	}

	if (_.get(args, 'issuu_token', false)) {
		params['issuu_token'] = _.get(args, 'issuu_token', 0);
	}

	if (_.get(args, 'issuu_description', false)) {
		params['issuu_description'] = _.get(args, 'issuu_description', 0);
	}

	if (_.get(args, 'issuu_auto', false)) {
		params['issuu_auto'] = _.get(args, 'issuu_auto', '');
	}

	if (_.get(args, 'issuu_limit', false)) {
		params['issuu_limit'] =
			parseInt(_.get(args, 'issuu_limit', 0)) > 0 ? parseInt(_.get(args, 'issuu_limit', 0)) : 0;
	}

	if (
		_.get(args, 'website', false) ||
		_.get(args, 'consummberKey', false) ||
		_.get(args, 'consummberSecret', false)
	) {
		config['sto_woocommerce_config'] = {
			website: _.get(args, 'website', ''),
			key: _.get(args, 'consummberKey', ''),
			secret: _.get(args, 'consummberSecret', ''),
		};
	}
	if (_.get(args, 'code', false) && _.get(args, 'platform', '') === 'Tiktok') {
		config['sto_tiktok_config'] = {
			code: _.get(args, 'code', ''),
			shop_id: _.get(args, 'shop_id', ''),
		};
	} else if (_.get(args, 'code', false)) {
		config['sto_ebay_config'] = {
			code: _.get(args, 'code', ''),
		};
	}
	if (_.get(args, 'merchize_access_token', false) || _.get(args, 'merchize_url', false)) {
		config['sto_merchize_config'] = {
			url: _.get(args, 'merchize_url', ''),
			access_token: _.get(args, 'merchize_access_token', ''),
		};
	}
	//Store Shopify
	if (_.get(args, 'shopifyWebsite', false) || _.get(args, 'apiKey', false) || _.get(args, 'apiSecretKey', false)) {
		config['sto_shopify_config'] = {};
		if (_.get(args, 'apiKey', false)) {
			config['sto_shopify_config']['apiKey'] = _.get(args, 'apiKey', false);
		}
		if (_.get(args, 'apiSecretKey', false)) {
			config['sto_shopify_config']['apiSecretKey'] = _.get(args, 'apiSecretKey', false);
		}
		if (_.get(args, 'shopifyWebsite', false)) {
			config['sto_shopify_config']['website'] = _.get(args, 'shopifyWebsite', false);
		}
	}
	//Store BigCommerce
	if (
		_.get(args, 'bigcommerce_store_hash', false) ||
		_.get(args, 'bigcommerce_client_id', false) ||
		_.get(args, 'bigcommerce_client_secret', false) ||
		_.get(args, 'bigcommerce_api_path', false)
	) {
		config['sto_bigcommerce_config'] = {};
		if (_.get(args, 'bigcommerce_client_id', false)) {
			config['sto_bigcommerce_config']['client_id'] = _.get(args, 'bigcommerce_client_id', false);
		}
		if (_.get(args, 'bigcommerce_client_secret', false)) {
			config['sto_bigcommerce_config']['client_secret'] = _.get(args, 'bigcommerce_client_secret', false);
		}
		if (_.get(args, 'bigcommerce_store_hash', false)) {
			config['sto_bigcommerce_config']['store_hash'] = _.get(args, 'bigcommerce_store_hash', false);
		}
		if (_.get(args, 'bigcommerce_api_path', false)) {
			config['sto_bigcommerce_config']['api_path'] = _.get(args, 'bigcommerce_api_path', false);
		}
	}
	//Store Etsy
	if (
		_.get(args, 'who_made', false) ||
		_.get(args, 'is_supply', false) ||
		_.get(args, 'product_state', false) ||
		_.get(args, 'when_made', false)
	) {
		if (_.get(args, 'sto_etsy_config', false)) {
			config['sto_etsy_config'] = _.get(args, 'sto_etsy_config', {});
		} else {
			config['sto_etsy_config'] = {};
		}
		if (_.get(args, 'who_made', false)) {
			config['sto_etsy_config']['who_made'] = _.get(args, 'who_made', false);
		}
		if (_.get(args, 'is_supply', false)) {
			config['sto_etsy_config']['is_supply'] = _.get(args, 'is_supply', false);
		}
		if (_.get(args, 'product_state', false)) {
			config['sto_etsy_config']['product_state'] = _.get(args, 'product_state', false);
		}
		if (_.get(args, 'when_made', false)) {
			config['sto_etsy_config']['when_made'] = _.get(args, 'when_made', false);
		}
	}
	//Store Etsy shipping template
	if (_.get(args, 'etsy_shipping', false)) {
		params['etsy_shipping'] = _.get(args, 'etsy_shipping', {});
	}

	// add template id
	if (_.get(args, 'ebay_template_id', false)) {
		params['ebay_template_id'] = _.get(args, 'ebay_template_id', {});
	}
	if (_.get(args, 'banner_image_id', false)) {
		params['banner_image_id'] = _.get(args, 'banner_image_id', {});
	}
	if (config) {
		params['params'] = config;
	}

	if (_.get(args, 'address', false)) {
		params['address'] = {
			first_name: _.get(args, ['address', 'first_name'], '') || '',
			last_name: _.get(args, ['address', 'last_name'], '') || '',
			address_1: _.get(args, ['address', 'address_1'], '') || '',
			address_2: _.get(args, ['address', 'address_2'], '') || '',
			company: _.get(args, ['address', 'company'], '') || '',
			city: _.get(args, ['address', 'city'], '') || '',
			state: _.get(args, ['address', 'state'], '') || '',
			postcode: _.get(args, ['address', 'postcode'], '') || '',
			country: _.get(args, ['address', 'country'], '') || '',
			email: _.get(args, ['address', 'email'], '') || '',
			phone: _.get(args, ['address', 'phone'], '') || '',
		};
	}
	if (_.get(args, 'invoice_id', false)) {
		params['invoice_id'] = {
			date: _.get(args, ['invoice_id', 'date'], '') || '',
			id: _.get(args, ['invoice_id', 'id'], '') || '',
		};
	}

	if (_.get(args, 'full_address', false)) {
		params['full_address'] = _.get(args, 'full_address', '');
	}

	const query = new Query('makeStore', params).find(responseField);
	return `mutation {${query}}`;
};

export const storeTrashQuery = (args) => {
	const delete_ids = _.get(args, 'delete_ids', []).map((d) => {
		return d.trim();
	});
	const query = new Query('trashStore', {
		ids: delete_ids,
	}).find(responseField);
	return `mutation {${query}}`;
};

export const storeCountQuery = () => {
	const query = new Query('storeCountQuery').find(['all', 'valid', 'lostconnection', 'invalid']);
	return `query {${query}}`;
};

export const storeWarehouseQuery = (args) => {
	const query = new Query('promotionWarehouseLive', {
		_id: _.get(args, '_id', ''),
		store: _.get(args, 'store', ''),
	}).find([
		{
			"address": [
				"city",
				"contact_person",
				"distict",
				"full_address",
				"phone_number",
				"postal_code",
				"region",
				"region_code",
				"state",
				"town",
			]
		},
        "effect_status",
        "id",
        "is_default",
        "name",
        "sub_type",
        "type",
	]);
	return `query {${query}}`;
};
