import _ from 'lodash';
import { toFloat } from './../utils/string';
const Query = require('graphql-query-builder');

const presetResponseField = [
	'_id',
	'id',
	'mockup_type',
	'product_id', // catalog
	{
		product: ['_id'],
	},
	'src',
	{
		print_areas: [
			'position',
			{
				image: [
					'_id',
					'url',
					{
						image_sizes: ['name', 'url', 'width', 'height'],
					},
				],
			},
		],
	},
	{
		sides: [
			'id',
			'name',
			{
				image: ['url', 'width', 'height'],
			},
			{
				// output image
				mockup: ['url', 'width', 'height'],
			},
			{
				document: [
					'width',
					'height',
					{
						settings: ['name', 'value'],
					},
				],
			},
			{
				print_area: ['x', 'y', 'width', 'height', 'scaleX', 'scaleY', 'rotation', 'knewX', 'knewY'],
			},
			{
				layers: [
					'id',
					'type',
					'name',
					{
						settings: ['name', 'value'],
					},
				],
			},
		],
	},
	'created_at',
	'updated_at',
];

export const responseField = [
	...presetResponseField,
	'preset_id',
	{
		preset: ['_id', 'id'],
	},
	'item_info_id',
];

export const productMockupQuery = (args, exclude_items = false) => {
	let params = {};
	if (_.get(args, '_id') !== undefined && _.get(args, '_id')) {
		params._id = _.get(args, '_id', '');
	}
	if (_.get(args, 'id') !== undefined && _.get(args, 'id')) {
		params.id = _.get(args, 'id', '');
	}
	if (_.get(args, 'name') !== undefined && _.get(args, 'name')) {
		params.name = _.get(args, 'name', '');
	}
	if (_.get(args, 'item_info_id')) {
		params.item_info_id = _.get(args, 'item_info_id');
	}
	if (_.get(args, 'product_id') !== undefined && _.get(args, 'product_id')) {
		params.product_id = _.get(args, 'product_id', '');
	}
	if (_.get(args, 'preset_id') !== undefined && _.get(args, 'preset_id')) {
		params.preset_id = _.get(args, 'preset_id', '');
	}
	let itemResponse = responseField;
	const query = new Query('productMockups', params).find(itemResponse);
	return `query {${query}}`;
};

export const makeProductMockupQuery = (args) => {
	let params = {};
	if (_.get(args, '_id')) {
		params._id = _.get(args, '_id');
	}
	if (_.get(args, 'src')) {
		params.src = _.get(args, 'src');
	}
	if (_.get(args, 'item_info_id')) {
		params.item_info_id = _.get(args, 'item_info_id');
	}
	if (_.get(args, 'product_id')) {
		params.product_id = _.get(args, 'product_id');
	}
	if (_.get(args, 'preset_id')) {
		params.preset_id = _.get(args, 'preset_id');
	}
	if (_.get(args, 'name')) {
		params.name = _.get(args, 'name');
	}
	if (_.get(args, 'mockup_type')) {
		params.mockup_type = _.get(args, 'mockup_type');
	}

	// 3D model
	if (_.get(args, 'model')) {
		params.model = _.get(args, 'model');
	}

	const FloatRanges = [
		'x',
		'y',
		'width',
		'height',
		'fontSize',
		'lineHeight',
		'opacity',
		'rotation',
		'knewX',
		'knewY',
		'scaleY',
		'scaleX',
		'letterSpacing',
	];

	if (_.get(args, 'sides') && _.get(args, 'sides').length > 0) {
		params.sides = _.map(_.get(args, 'sides'), (side) => {
			let settings = {
				id: _.get(side, 'id', ''),
				name: _.get(side, 'name', ''),
				image: {
					_id: _.get(side, ['image', '_id'], ''),
					url: _.get(side, ['image', 'url'], ''),
					image_sizes: _.map(_.get(side, ['image', 'image_sizes'], []), (size) => {
						return {
							name: _.get(size, 'name', ''),
							url: _.get(size, 'url', ''),
							...(() => {
								let settings = {};
								if (_.get(size, 'width')) {
									settings.width = _.get(size, 'width');
								}
								if (_.get(size, 'height')) {
									settings.height = _.get(size, 'height');
								}
								return settings;
							})(),
						};
					}),
				},
				mockup: {
					_id: _.get(side, ['mockup', '_id'], ''),
					url: _.get(side, ['mockup', 'url'], ''),
					image_sizes: _.map(_.get(side, ['mockup', 'image_sizes'], []), (size) => {
						return {
							name: _.get(size, 'name', ''),
							url: _.get(size, 'url', ''),
							...(() => {
								let settings = {};
								if (_.get(size, 'width')) {
									settings.width = _.get(size, 'width');
								}
								if (_.get(size, 'height')) {
									settings.height = _.get(size, 'height');
								}
								return settings;
							})(),
						};
					}),
				},
				document: {
					..._.get(side, 'document'),
					settings: _.get(side, ['document', 'settings'])
						? _.reduce(
								Object.keys(_.get(side, ['document', 'settings'])),
								(acc, name) => {
									let value = _.get(side, ['document', 'settings', name]);
									if (FloatRanges.includes(name) && value !== '' && value !== null) {
										value = parseFloat(parseFloat(value).toFixed(2));
									}
									return [
										...acc,
										{
											name: name,
											value: value ? value : '',
										},
									];
								},
								[]
						  )
						: [],
				},
			};

			if (_.get(side, 'print_area')) {
				settings.print_area = {
					x: toFloat(_.get(side, ['print_area', 'x'])),
					y: toFloat(_.get(side, ['print_area', 'y'])),
					width: toFloat(_.get(side, ['print_area', 'width'])),
					height: toFloat(_.get(side, ['print_area', 'height'])),
					scaleX: toFloat(_.get(side, ['print_area', 'scaleX'], 1)),
					scaleY: toFloat(_.get(side, ['print_area', 'scaleY'], 1)),
					rotation: toFloat(_.get(side, ['print_area', 'rotation'], 0)),
					...(() => {
						let settings = {};
						if (_.get(side, ['print_area', 'knewX'], 1) !== undefined && _.get(side, ['print_area', 'knewX'], 1)) {
							settings.knewX = toFloat(_.get(side, ['print_area', 'knewX'], 1));
						}
						if (_.get(side, ['print_area', 'knewY'], 1) !== undefined && _.get(side, ['print_area', 'knewY'], 1)) {
							settings.knewY = toFloat(_.get(side, ['print_area', 'knewY'], 1));
						}
						return settings;
					})(),
				};
			}

			if (Array.isArray(_.get(side, 'layers')) && _.get(side, 'layers').length > 0) {
				settings.layers = _.map(
					_.filter(_.cloneDeep(_.get(side, 'layers')), (l) => l.type),
					(layer) => {
						return {
							id: _.get(layer, 'id'),
							name: _.get(layer, 'name'),
							type: _.get(layer, 'type'),
							settings: _.reduce(
								Object.keys(_.get(layer, 'settings')),
								(acc, name) => {
									if (name === 'file') {
										return [
											...acc,
											{
												name: 'file_id',
												value: _.get(layer, ['settings', name, '_id'])
													? String(_.get(layer, ['settings', name, '_id']))
													: '',
											},
											{
												name: 'file_url',
												value: _.get(layer, ['settings', name, 'url'])
													? String(_.get(layer, ['settings', name, 'url']))
													: '',
											},
										];
									}
									let value = _.get(layer, ['settings', name]);
									if (FloatRanges.includes(name) && value !== '' && value !== null) {
										value = parseFloat(parseFloat(value).toFixed(2));
									}
									return [
										...acc,
										{
											name: name,
											value: value ? String(value) : '',
										},
									];
								},
								[]
							),
						};
					}
				);
			}
			return settings;
		});
	}

	const query = new Query('makeProductMockup', params).find(responseField);
	return `mutation {${query}}`;
};

export const makeProductMockupPresetQuery = (args) => {
	let params = {};
	if (_.get(args, '_id')) {
		params._id = _.get(args, '_id');
	}
	if (_.get(args, 'name')) {
		params.name = _.get(args, 'name');
	}
	if (_.get(args, 'src')) {
		params.src = _.get(args, 'src');
	}
	if (_.get(args, 'status')) {
		params.status = _.get(args, 'status');
	}
	if (_.get(args, 'product_id')) {
		params.product_id = _.get(args, 'product_id');
	}
	if (_.get(args, 'provider')) {
		params.provider = _.get(args, 'provider');
	}
	if (_.get(args, 'name')) {
		params.name = _.get(args, 'name');
	}
	if (_.get(args, 'mockup_type')) {
		params.mockup_type = _.get(args, 'mockup_type');
	}

	// 3D model
	if (_.get(args, 'model')) {
		params.model = _.get(args, 'model');
	}

	const FloatRanges = [
		'x',
		'y',
		'width',
		'height',
		'fontSize',
		'lineHeight',
		'opacity',
		'rotation',
		'knewX',
		'knewY',
		'scaleY',
		'scaleX',
		'letterSpacing',
	];

	if (_.get(args, 'sides') && _.get(args, 'sides').length > 0) {
		params.sides = _.map(_.get(args, 'sides'), (side) => {
			let settings = {
				id: _.get(side, 'id', ''),
				name: _.get(side, 'name', ''),
				image: {
					_id: _.get(side, ['image', '_id'], ''),
					url: _.get(side, ['image', 'url'], ''),
					image_sizes: _.map(_.get(side, ['image', 'image_sizes'], []), (size) => {
						return {
							name: _.get(size, 'name', ''),
							url: _.get(size, 'url', ''),
							...(() => {
								let settings = {};
								if (_.get(size, 'width')) {
									settings.width = _.get(size, 'width');
								}
								if (_.get(size, 'height')) {
									settings.height = _.get(size, 'height');
								}
								return settings;
							})(),
						};
					}),
					...(() => {
						let settings = {};
						if (_.get(side, ['image', 'width'])) {
							settings.width = _.get(side, ['image', 'width']);
						}
						if (_.get(side, ['image', 'height'])) {
							settings.height = _.get(side, ['image', 'height']);
						}
						return settings;
					})(),
				},
				mockup: {
					_id: _.get(side, ['mockup', '_id'], ''),
					url: _.get(side, ['mockup', 'url'], ''),
					image_sizes: _.map(_.get(side, ['mockup', 'image_sizes'], []), (size) => {
						return {
							name: _.get(size, 'name', ''),
							url: _.get(size, 'url', ''),
							...(() => {
								let settings = {};
								if (_.get(size, 'width')) {
									settings.width = _.get(size, 'width');
								}
								if (_.get(size, 'height')) {
									settings.height = _.get(size, 'height');
								}
								return settings;
							})(),
						};
					}),
				},
				document: {
					..._.get(side, 'document'),
					settings: _.get(side, ['document', 'settings'])
						? _.reduce(
								Object.keys(_.get(side, ['document', 'settings'])),
								(acc, name) => {
									let value = _.get(side, ['document', 'settings', name]);
									if (FloatRanges.includes(name) && value !== '' && value !== null) {
										value = parseFloat(parseFloat(value).toFixed(2));
									}
									return [
										...acc,
										{
											name: name,
											value: value ? value : '',
										},
									];
								},
								[]
						  )
						: [],
				},
			};

			if (_.get(side, 'print_area')) {
				settings.print_area = {
					x: toFloat(_.get(side, ['print_area', 'x'])),
					y: toFloat(_.get(side, ['print_area', 'y'])),
					width: toFloat(_.get(side, ['print_area', 'width'])),
					height: toFloat(_.get(side, ['print_area', 'height'])),
					scaleX: toFloat(_.get(side, ['print_area', 'scaleX'], 1)),
					scaleY: toFloat(_.get(side, ['print_area', 'scaleY'], 1)),
					rotation: toFloat(_.get(side, ['print_area', 'rotation'], 0)),
					...(() => {
						let settings = {};
						if (_.get(side, ['print_area', 'knewX'], 1) !== undefined && _.get(side, ['print_area', 'knewX'], 1)) {
							settings.knewX = toFloat(_.get(side, ['print_area', 'knewX'], 1));
						}
						if (_.get(side, ['print_area', 'knewY'], 1) !== undefined && _.get(side, ['print_area', 'knewY'], 1)) {
							settings.knewY = toFloat(_.get(side, ['print_area', 'knewY'], 1));
						}
						return settings;
					})(),
				};
			}

			if (Array.isArray(_.get(side, 'layers')) && _.get(side, 'layers').length > 0) {
				settings.layers = _.map(
					_.filter(_.cloneDeep(_.get(side, 'layers')), (l) => l.type),
					(layer) => {
						return {
							id: _.get(layer, 'id'),
							name: _.get(layer, 'name'),
							type: _.get(layer, 'type'),
							settings: _.reduce(
								Object.keys(_.get(layer, 'settings')),
								(acc, name) => {
									if (name === 'file') {
										return [
											...acc,
											{
												name: 'file_id',
												value: _.get(layer, ['settings', name, '_id'])
													? String(_.get(layer, ['settings', name, '_id']))
													: '',
											},
											{
												name: 'file_url',
												value: _.get(layer, ['settings', name, 'url'])
													? String(_.get(layer, ['settings', name, 'url']))
													: '',
											},
										];
									}
									let value = _.get(layer, ['settings', name]);
									if (FloatRanges.includes(name) && value !== '' && value !== null) {
										value = parseFloat(parseFloat(value).toFixed(2));
									}
									return [
										...acc,
										{
											name: name,
											value: value ? String(value) : '',
										},
									];
								},
								[]
							),
						};
					}
				);
			}
			return settings;
		});
	}

	const query = new Query('makeProductMockupPreset', params).find([...presetResponseField, 'name', 'type']);
	return `mutation {${query}}`;
};

export const removeProductMockupPresetQuery = (args) => {
	let params = {};

	if (_.get(args, '_id')) {
		params._id = _.get(args, '_id');
	}

	const query = new Query('removeProductMockupPreset', params).find(['_id', 'id']);
	return `mutation {${query}}`;
};

export const makeMockupPreviewQuery = (args) => {
	let params = {};
	if (_.get(args, 'item_info_id')) {
		params.item_info_id = _.get(args, 'item_info_id');
	}
	if (_.get(args, 'preset_id')) {
		params.preset_id = _.get(args, 'preset_id');
	}

	const FloatRanges = [
		'x',
		'y',
		'width',
		'height',
		'fontSize',
		'lineHeight',
		'opacity',
		'rotation',
		'knewX',
		'knewY',
		'scaleY',
		'scaleX',
		'letterSpacing',
	];

	let side = _.get(args, 'side');
	let settings = {
		id: _.get(side, 'id', ''),
		name: _.get(side, 'name', ''),
		image: {
			_id: _.get(side, ['image', '_id'], ''),
			url: _.get(side, ['image', 'url'], ''),
			image_sizes: _.map(_.get(side, ['image', 'image_sizes'], []), (size) => {
				return {
					name: _.get(size, 'name', ''),
					url: _.get(size, 'url', ''),
					...(() => {
						let settings = {};
						if (_.get(size, 'width')) {
							settings.width = _.get(size, 'width');
						}
						if (_.get(size, 'height')) {
							settings.height = _.get(size, 'height');
						}
						return settings;
					})(),
				};
			}),
		},
		mockup: {
			_id: _.get(side, ['mockup', '_id'], ''),
			url: _.get(side, ['mockup', 'url'], ''),
			image_sizes: _.map(_.get(side, ['mockup', 'image_sizes'], []), (size) => {
				return {
					name: _.get(size, 'name', ''),
					url: _.get(size, 'url', ''),
					...(() => {
						let settings = {};
						if (_.get(size, 'width')) {
							settings.width = _.get(size, 'width');
						}
						if (_.get(size, 'height')) {
							settings.height = _.get(size, 'height');
						}
						return settings;
					})(),
				};
			}),
		},
		document: {
			..._.get(side, 'document'),
			settings: _.get(side, ['document', 'settings'])
				? _.reduce(
						Object.keys(_.get(side, ['document', 'settings'])),
						(acc, name) => {
							let value = _.get(side, ['document', 'settings', name]);
							if (FloatRanges.includes(name) && value !== '' && value !== null) {
								value = parseFloat(parseFloat(value).toFixed(2));
							}
							return [
								...acc,
								{
									name: name,
									value: value ? value : '',
								},
							];
						},
						[]
				  )
				: [],
		},
	};

	if (_.get(side, 'print_area')) {
		settings.print_area = {
			x: toFloat(_.get(side, ['print_area', 'x'])),
			y: toFloat(_.get(side, ['print_area', 'y'])),
			width: toFloat(_.get(side, ['print_area', 'width'])),
			height: toFloat(_.get(side, ['print_area', 'height'])),
			scaleX: toFloat(_.get(side, ['print_area', 'scaleX'], 1)),
			scaleY: toFloat(_.get(side, ['print_area', 'scaleY'], 1)),
			rotation: toFloat(_.get(side, ['print_area', 'rotation'], 0)),
			...(() => {
				let settings = {};
				if (_.get(side, ['print_area', 'knewX'], 1) !== undefined && _.get(side, ['print_area', 'knewX'], 1)) {
					settings.knewX = toFloat(_.get(side, ['print_area', 'knewX'], 1));
				}
				if (_.get(side, ['print_area', 'knewY'], 1) !== undefined && _.get(side, ['print_area', 'knewY'], 1)) {
					settings.knewY = toFloat(_.get(side, ['print_area', 'knewY'], 1));
				}
				return settings;
			})(),
		};
	}

	if (Array.isArray(_.get(side, 'layers')) && _.get(side, 'layers').length > 0) {
		settings.layers = _.map(
			_.filter(_.cloneDeep(_.get(side, 'layers')), (l) => l.type),
			(layer) => {
				return {
					id: _.get(layer, 'id'),
					name: _.get(layer, 'name'),
					type: _.get(layer, 'type'),
					settings: _.reduce(
						Object.keys(_.get(layer, 'settings')),
						(acc, name) => {
							if (name === 'file') {
								return [
									...acc,
									{
										name: 'file_id',
										value: _.get(layer, ['settings', name, '_id'])
											? String(_.get(layer, ['settings', name, '_id']))
											: '',
									},
									{
										name: 'file_url',
										value: _.get(layer, ['settings', name, 'url'])
											? String(_.get(layer, ['settings', name, 'url']))
											: '',
									},
								];
							}
							let value = _.get(layer, ['settings', name]);
							if (FloatRanges.includes(name) && value !== '' && value !== null) {
								value = parseFloat(parseFloat(value).toFixed(2));
							}
							return [
								...acc,
								{
									name: name,
									value: value ? String(value) : '',
								},
							];
						},
						[]
					),
				};
			}
		);
	}

	params.side = settings;

	const query = new Query('makeMockupPreview', params).find([
		'url',
		{
			image_sizes: ['name', 'url'],
		},
	]);
	return `mutation {${query}}`;
};
export const productPresetMockupCountProviderQuery = (args) => {
	let params = {};
	// if ( _.get(args, '_id') ) {
	//     params._id = _.get(args, '_id')
	// }
	// if ( _.get(args, 'product_id') ) {
	//     params.product_id = _.get(args, 'product_id')
	// }
	// if ( _.get(args, 'name') ) {
	//     params.name = _.get(args, 'name')
	// }
	// if ( _.get(args, 'mockup_type') ) {
	//     params.mockup_type = _.get(args, 'mockup_type')
	// }
	const query = new Query('productPresetMockupCountProvider', params).find([
		'total',
		'customcat',
		'lionnix',
		'merchize',
		'geargag',
		'scalable',
		'private',
		'printify',
	]);
	return `query {${query}}`;
};
