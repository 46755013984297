// import { combineReducers } from "redux"
import _ from "lodash"
// import uniqid from 'uniqid'
// import { v4 as uuidv4 } from 'uuid'
// import { ucFirst } from './../../../utils/string'
/* eslint-disable */
const DEFAULT_STATES = {
    provider: 'google',
    loading: false,
    google: {
        name: '',
        items: [],
        page: 1,
        total: 0
    },
    custom: {
        name: '',
        items: [],
        page: 1,
        total: 0
    },
    // default to init load
    default_fonts: []
}

const editor = ( state = { ...DEFAULT_STATES }, action ) => {
    switch (action.type) {
        case 'LOADING':
            var loading = _.get(action, ['payload', 'loading'], false)
            return { ...state, loading }
        break;

        case 'SET_PROVIDER':
            var provider = _.get(action, ['payload', 'provider'], false)
            return { ...state, provider }
        break;

        case 'SET_SEARCH_FONT':
            var newState = _.cloneDeep(state)
            var provider = _.get(newState, 'provider', false)
            _.set(newState, [provider, 'name'], _.get(action, ['payload', 'name'], ''))
            return { ...newState }
        break;

        case 'INIT_FONTS':
            var provider = _.get(state, 'provider', '')
            var items = _.get(action, ['payload', 'items'], [])
            var page = _.get(action, ['payload', 'page'], 1)
            var total = _.get(action, ['payload', 'total'], 0)
            return {
                ...state,
                [provider]: {
                    items,
                    page,
                    total
                }
            }
        break;

        case 'SET_FONTS':
            var newState = _.cloneDeep(state)
            var provider = _.get(newState, 'provider', '')
            var items = _.get(action, ['payload', 'items'], [])
            var page = _.get(action, ['payload', 'page'], 1)
            var total = _.get(action, ['payload', 'total'], 0)

            _.set(newState, [provider, 'items'], items )
            _.set(newState, [provider, 'page'], page )
            _.set(newState, [provider, 'total'], total )
            return {
                ...newState,
            }
        break;

        case 'SET_FONT_PAGE':
            var newState = _.cloneDeep(state)
            var provider = _.get(newState, 'provider', '')
            var page = _.get(action, ['payload', 'page'], 1)
            _.set(newState, [provider, 'page'], page )
            return { ...newState }
        break;

        case 'ADD_ITEMS':
            var newState = _.cloneDeep(state)
            var provider = _.get(newState, 'provider', '')
            var items = _.get(action, ['payload', 'items'], [])
            var page = _.get(action, ['payload', 'page'], 1)
            var total = _.get(action, ['payload', 'total'], 0)

            _.set(newState, [provider, 'items'], [
                ...state.provider.items,
                ...items
            ] )
            _.set(newState, [provider, 'page'], page )
            _.set(newState, [provider, 'total'], total )
            return {
                ...newState
            }
        break;

        case 'SET_DEFAULT_FONTS':
            var default_fonts = _.get(action, ['payload', 'default_fonts'], [])
            default_fonts = _.reduce(default_fonts, (acc, font) => {
                if ( ! _.find(acc, (f) => f._id === _.get(font, '_id')) ) {
                    acc = [
                        ...acc,
                        font
                    ]
                }
                return acc
            }, _.cloneDeep(_.get(state, 'default_fonts', [])))
            return {
                ...state,
                default_fonts: [
                    ...default_fonts
                ]
            }
        break;

        default: {
            return state
        }
    }
}

export default editor
