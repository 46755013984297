/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Spinner } from 'reactstrap';
import { AlertTriangle, CheckSquare, X } from 'react-feather';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
	pushBackgroundTask,
	removeBackgroundTask,
	errorBackgroundTask,
	removeErrorBackgroundTask,
} from '../../../redux/actions/background';
import '../../../assets/scss/components/customizer.scss';

class BackgroundTask extends React.Component {
	state = {
		navbarType: null,
		runningTask: null,
	};

	static getDerivedStateFromProps(props, state) {
		if (props.navbarType !== state.navbarType) {
			return {
				navbarType: props.navbarType,
			};
		}
		// Return null if the state hasn't changed
		return null;
	}

	onUnload = (e) => {
		// the method that will be used for both add and remove event
		if (Array.isArray(this.props.backgroundTask?.tasks) && this.props.backgroundTask?.tasks.length) {
			this.props.handleBackgroundTask(true);
			e.preventDefault();
		}
	};

	componentDidMount() {
		window.addEventListener('beforeunload', this.onUnload);
		this.setState({
			navbarType: this.props.navbarType,
		});
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.onUnload);
	}

	componentDidUpdate(prevProps, prevState) {
		console.log(__filename, 'prevProps: ', prevProps);
		console.log(__filename, this.props);
		if (prevProps?.backgroundTask?.queueTask !== this.props?.backgroundTask?.queueTask) {
			if (this.props?.backgroundTask?.queueTask) {
				const task = this.props?.backgroundTask?.queueTask;
				if (typeof task.func === 'function') {
					try {
						task.func().then((res) => {
							console.log(__filename, 'res: ', res);
							setTimeout(() => {
								if (res !== 'success') {
									this.props.errorBackgroundTask(task);
								}
								this.props.removeBackgroundTask(task);
							}, 200);
						});
					} catch (error) {
						setTimeout(() => {
							this.props.errorBackgroundTask(task);
							this.props.removeBackgroundTask(task);
						}, 200);
					}
				}
			}
		}
	}

	render() {
		const { navbarType } = this.state;
		return (
			<div
				className={classnames('customizer background-task d-none d-md-block', {
					open: this.props.backgroundTaskState === true,
				})}
			>
				{navbarType === 'hidden' ? (
					<div
						className="customizer-toggle"
						onClick={() => this.props.handleBackgroundTask(!this.props.backgroundTaskState)}
					>
						<CheckSquare className="open-icon" size={15} />
					</div>
				) : (
					false
				)}
				<div className="header d-flex justify-content-between px-2 pt-2">
					<div className="title">
						<h4 className="text-uppercase mb-0">Background Task</h4>
						<small>Do not close the browser window until it is finished running</small>
					</div>
					<div className="close-icon cursor-pointer" onClick={() => this.props.handleBackgroundTask(false)}>
						<X size={24} />
					</div>
				</div>
				<hr />
				<PerfectScrollbar
					options={{
						wheelPropagation: false,
					}}
					className="customizer-content p-2"
				>
					<p className="text-center text-bold-600">
						<span>
							{Array.isArray(this.props.backgroundTask?.tasks)
								? this.props.backgroundTask?.tasks.length
								: 0}{' '}
							Task,{' '}
							{Array.isArray(this.props.backgroundTask?.tasksError)
								? this.props.backgroundTask?.tasksError.length
								: 0}{' '}
							Error
						</span>
					</p>
					<ul className="list-unstyled categories-list">
						{Array.isArray(this.props.backgroundTask?.tasksError) &&
						this.props.backgroundTask?.tasksError.length
							? _.map(this.props.backgroundTask?.tasksError, (task, index) => {
									return (
										<li className="d-flex align-items-center mb-1" key={task.key + '.' + index}>
											<AlertTriangle className="text-danger" />
											{task.name}
											<a
												className="link ml-1"
												href="#"
												onClick={(e) => {
													e.preventDefault();
													this.props.removeErrorBackgroundTask(task);
													this.props.pushBackgroundTask(task);
												}}
											>
												Try again
											</a>
										</li>
									);
							  })
							: false}

						{Array.isArray(this.props.backgroundTask?.tasks) && this.props.backgroundTask?.tasks.length ? (
							_.map(this.props.backgroundTask?.tasks, (task, index) => {
								return (
									<li className="d-flex align-items-center mb-1" key={task.key + '.' + index}>
										<Spinner size="sm" color="warning" className="mr-50" />
										{task.name}
									</li>
								);
							})
						) : (
							<li className="text-center">No task</li>
						)}
					</ul>
				</PerfectScrollbar>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		backgroundTask: state.background.background,
	};
};

export default connect(mapStateToProps, {
	pushBackgroundTask,
	removeBackgroundTask,
	errorBackgroundTask,
	removeErrorBackgroundTask,
})(BackgroundTask);
