import React from "react"
import { Navbar } from "reactstrap"
import classnames from "classnames"
import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
import NavbarHome from "./NavbarHome"
import userImg from "../../../assets/img/portrait/small/avatar-default.jpg"
import { getCookie } from "../../../redux/actions/cookie/cookie"
import { getDomainInfo } from "./../../../client"

const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark"]
const navbarTypes = ["floating" , "static" , "sticky" , "hidden"]

class ThemeNavbar extends React.PureComponent {
	// const ThemeNavbar = props => {
	render() {
		const props = this.props
		return (
			<React.Fragment>
				<div className="content-overlay" />
				<div className="header-navbar-shadow" />
				<Navbar
					className={classnames(
						"header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow main-nav",
						{
							"navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
							"navbar-dark": colorsArr.includes(props.navbarColor),
							"bg-primary":
				  props.navbarColor === "primary" && props.navbarType !== "static",
							"bg-danger":
				  props.navbarColor === "danger" && props.navbarType !== "static",
							"bg-success":
				  props.navbarColor === "success" && props.navbarType !== "static",
							"bg-info":
				  props.navbarColor === "info" && props.navbarType !== "static",
							"bg-warning":
				  props.navbarColor === "warning" && props.navbarType !== "static",
							"bg-dark":
				  props.navbarColor === "dark" && props.navbarType !== "static",
							"d-none": props.navbarType === "hidden" && !props.horizontal,
							"floating-nav":
				  (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
							"navbar-static-top":
				  props.navbarType === "static" && !props.horizontal,
							"fixed-top": props.navbarType === "sticky" || props.horizontal,
							"scrolling": props.horizontal && props.scrolling
	
						}
					)}
				>
					<div className="navbar-wrapper">
						<div className="navbar-container content">
							<div
								className="navbar-collapse d-flex justify-content-between align-items-center"
								id="navbar-mobile"
							>
								{props.horizontal ? null : (<div className="bookmark-wrapper">
									<NavbarBookmarks
										sidebarVisibility={props.sidebarVisibility}
										handleAppOverlay={props.handleAppOverlay}
										customizerState={props.customizerState}
										handleCustomizer={props.handleCustomizer}
										backgroundTaskState={props.backgroundTaskState}
										handleBackgroundTask={props.handleBackgroundTask}
									/>
								</div>)}
								{props.horizontal ? (
									<div className="logo d-flex align-items-center">
										<div className={classnames("brand-logo mr-50", {"brand-logo-salehunter": getDomainInfo().rootDomain === "salehunter.io"})}></div>
										{/* <h2 className="text-primary brand-text mb-0">{""}</h2> */}
									</div>
								) : null}
								{
									getCookie("_token") !== "" ? (<NavbarUser
										handleAppOverlay={props.handleAppOverlay}
										changeCurrentLang={props.changeCurrentLang}
										userName="John Doe"
										userImg={ userImg }
									/>) : (<NavbarHome
										handleAppOverlay={props.handleAppOverlay}
										changeCurrentLang={props.changeCurrentLang}
										userName="John Doe"
										userImg={ userImg }
									/>)
								}
	
							</div>
						</div>
					</div>
				</Navbar>
			</React.Fragment>
		)
	}
}


export default ThemeNavbar
