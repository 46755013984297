import gql from 'graphql-tag';
import _ from 'lodash';
const Query = require('graphql-query-builder');

export const responseFileField = [
	'_id',
	'family',
	'slug',
	'variants',
	'subsets',
	{
		files: ['variant', 'url', 'style', 'weight'],
	},
	'provider',
	'preview',
];

/*****************************Start Query for Files*****************************/
export const fontsQuery = (args) => {
	let params = {
		id: _.get(args, 'id', ''),
		provider: _.get(args, 'provider', ''),
		name: _.get(args, 'name', ''),
	};
	const query = new Query('fonts', params).find(responseFileField);
	return `query {${query}}`;
};

export const makeSignedUploadFont = (args) => {
	const signedFileFragment = gql`
		fragment SignedFileFont on FontFileSigned {
			variant
			path
			url
		}
	`;

	const query = gql`
		mutation signedUploadFont($family: String, $files: [InputSignedUploadFont]) {
			signedUploadFont(family: $family, files: $files) {
				...SignedFileFont
			}
		}
		${signedFileFragment}
	`;

	let variables = {
		family: _.get(args, 'family', ''),
		files: _.map(_.get(args, 'files'), (file) => {
			return {
				filename: _.get(file, 'name'),
				mimetype: _.get(file, 'type'),
				size: _.get(file, 'size'),
			};
		}),
	};

	return {
		operationName: 'signedUploadFont',
		query: query.loc.source.body,
		variables,
	};
};

export const fontUpdatePreSignedFile = (args) => {
	const signedFileFragment = gql`
		fragment UpdateSignedFile on Font {
			_id
			family
			files {
				variant
				url
				style
				weight
			}
			provider
		}
	`;
	let query = gql`
		mutation fontUpdatePreSignedFile($family: String, $variants: [InputFontFile]) {
			fontUpdatePreSignedFile(family: $family, variants: $variants) {
				...UpdateSignedFile
			}
		}
		${signedFileFragment}
	`;

	let variables = {
		family: _.get(args, 'family', ''),
		variants: _.get(args, 'variants'),
	};

	return {
		operationName: 'fontUpdatePreSignedFile',
		query: query.loc.source.body,
		variables,
	};
};
