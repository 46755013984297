import React from 'react';
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import classnames from 'classnames';
import { connect } from 'react-redux';
import * as Icon from 'react-feather';
import { logoutWithJWT } from './../../../redux/actions/auth/logoutActions';
import { clearBatchCsv } from './../../../redux/actions/order/index';
import { history } from './../../../history';
import NavPlan from './NavPlan';
import NavContainer from './NavContainer';
import NavNotification from './NavNotification';
import NavBalance from './NavBalance';
import { getDomainInfo } from './../../../client';
import _ from 'lodash';

const UserDropdown = (props) => {
	return (
		<DropdownMenu className={classnames()}>
			<DropdownItem tag="a" href="#" disabled={true} className="hidden">
				<Icon.Mail size={14} className="mr-50" />
				<span className="align-middle">My Inbox</span>
			</DropdownItem>
			<DropdownItem divider className="hidden" />
			{props.hasRole('super_root', props.getUserById.email) ? (
				<a
					href={`${getDomainInfo().restURL}/test-job?job=&id=`}
					target="_blank"
					className="dropdown-item"
					tabIndex={0}
					role="menuitem"
					rel="noreferrer"
				>
					<Icon.Loader size={14} className="mr-50" />
					<span className="align-middle">Test JOB</span>
				</a>
			) : (
				false
			)}
			{props.hasRole('super_root', props.getUserById.email) ? <DropdownItem divider /> : false}
			<a href={history.accountUrl() + '/profile/info'} className="dropdown-item" tabIndex={0} role="menuitem">
				<Icon.User size={14} className="mr-50" />
				<span className="align-middle">Edit Profile</span>
			</a>
			<a href={history.accountUrl() + '/profile/password'} className="dropdown-item" tabIndex={0} role="menuitem">
				<Icon.Lock size={14} className="mr-50" />
				<span className="align-middle">Edit Password</span>
			</a>
			<DropdownItem divider />
			<DropdownItem tag="a" onClick={props.logoutWithJWT}>
				<Icon.Power size={14} className="mr-50" />
				<span className="align-middle">Log Out</span>
			</DropdownItem>
		</DropdownMenu>
	);
};

class NavbarUser extends React.PureComponent {
	state = {
		navbarSearch: false,
		suggestions: [],
	};
	handleNavbarSearch = () => {
		this.setState({
			navbarSearch: !this.state.navbarSearch,
		});
	};

	render() {
		return (
			<ul className="nav navbar-nav navbar-nav-user float-right">
				<NavPlan handleAppOverlay={this.props.handleAppOverlay} />
				<NavContainer handleAppOverlay={this.props.handleAppOverlay} />
				<NavBalance />
				<NavNotification />
				<UncontrolledDropdown tag="li" className="dropdown-user nav-item">
					<DropdownToggle tag="a" className="nav-link dropdown-user-link">
						<div className="user-nav d-sm-flex d-none">
							<span className="user-name text-bold-600">
								{this.props.getUserById.name || this.props.getUserById.email || 'Username'}
							</span>
							<span className="user-status">
								<span className={`bullet bullet-success bullet-xs`} />
								<span className="text-capitalize ml-25">Online</span>
							</span>
						</div>
						<span data-tour="user">
							<img src={this.props.userImg} className="round" height="40" width="40" alt="avatar" />
						</span>
					</DropdownToggle>
					<UserDropdown {...this.props} />
				</UncontrolledDropdown>
			</ul>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		user: state.auth.login.userRole,
		getUserById: state.auth.login.loggedInUser,
		batchCount: _.get(state, ['order', 'batch', 'count']),
		customizer: state.customizer.customizer,
		hasRole: state.auth.login.hasRole,
	};
};
export default connect(mapStateToProps, { logoutWithJWT, clearBatchCsv })(NavbarUser);
