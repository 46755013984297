import React from 'react';
import _ from 'lodash';
import * as Icon from 'react-feather';
import { history } from './../history';

let navigationConfig = [
	{
		id: 'dashboard',
		title: 'Dashboard',
		type: 'item',
		icon: <Icon.Home size={18} />,
		roles: ['root', 'sale'],
		permissions: ['dashboard'],
		navLink: '/dashboard',
		filterBase: '/dashboard',
	},
	{
		id: 'manager_report',
		title: 'Report',
		type: 'collapse',
		icon: <Icon.PieChart size={18} />,
		children: [
			// {
			//   id: "report",
			//   title: "Finance Report",
			//   type: "item",
			//   icon: < Icon.DollarSign size={ 18 }
			//   />,
			//   roles: ["dashboard"],
			//   navLink: "/report",
			//   filterBase: "/report",
			// },
			{
				id: 'fulfillment-report',
				title: 'Fulfillment Report',
				type: 'item',
				icon: <Icon.Square size={18} />,
				roles: ['root', 'sale'],
				navLink: '/fulfillment-report',
				filterBase: '/fulfillment-report',
			},
			{
				id: 'order-report',
				title: 'Order Report',
				type: 'item',
				icon: <Icon.Package size={18} />,
				roles: ['root', 'sale'],
				navLink: '/order-report',
				filterBase: '/order-report',
			},
			{
				id: 'order-invoice',
				title: 'Invoice',
				type: 'item',
				icon: <Icon.Package size={18} />,
				roles: ['super_root', 'sale'],
				navLink: '/order-invoice',
				filterBase: '/order-invoice',
			},
		],
	},
	{
		type: 'groupHeader',
		groupTitle: 'Sales',
	},
	{
		id: 'orders',
		title: 'Orders',
		type: 'item',
		icon: <Icon.Package size={18} />,
		roles: ['root', 'sale'],
		permissions: ['order'],
		navLink: '/orders/:filter',
		filterBase: '/orders/all?status=Processing',
	},
	{
		id: 'trackingmore',
		title: 'Trackings',
		type: 'item',
		icon: <Icon.List size={18} />,
		roles: ['root', 'sale'],
		permissions: ['trackingmore'],
		navLink: '/trackings',
		filterBase: '/trackings?status=All',
	},
	{
		id: 'support',
		title: 'Support',
		type: 'item',
		icon: <Icon.MessageSquare size={18} />,
		navLink: '/support/:filter',
		filterBase: '/support/order?status=Inprocess',
		roles: ['root', 'sale'],
		permissions: ['messenger'],
	},
	{
		id: 'paypal_menu',
		title: 'Paypal',
		type: 'item',
		icon: (
			<span
				style={{
					display: 'inline-block',
					lineHeight: '18px',
					fontSize: '18px',
					width: '32px',
					height: '18px',
				}}
			>
				P
			</span>
		),
		roles: ['root', 'sale'],
		permissions: ['paypal'],
		navLink: '/paypal/:filter',
		filterBase: '/paypal/all',
	},
	{
		id: 'manager_item',
		title: 'Items',
		type: 'collapse',
		icon: <Icon.Archive size={18} />,
		children: [
			{
				id: 'external_items',
				title: 'External',
				type: 'item',
				icon: <Icon.Cloud size={14} />,
				navLink: '/items/external',
				filterBase: '/items/external',
				roles: ['root', 'sale'],
				permissions: ['item'],
			},
			{
				id: 'internal_items',
				title: 'Internal',
				type: 'item',
				icon: <Icon.Package size={14} />,
				navLink: '/items/internal',
				filterBase: '/items/internal',
				roles: ['root', 'sale'],
				permissions: ['item_info'],
			},
			{
				id: 'trend_items',
				title: 'Trend',
				type: 'item',
				icon: <Icon.Package size={14} />,
				navLink: '/items/trend',
				filterBase: '/items/trend',
				roles: ['root', 'sale'],
				permissions: ['item_info'],
				showCondition: (args) => {
					const containers = _.get(args, 'auth_docker', [])
					const currentContainer = _.get(_.find(containers, con => con.current), "docker", {})
					return _.get(currentContainer, 'trend', false) === 'trend'
				}
			},
			{
				id: 'preset_items',
				title: 'Product type',
				type: 'item',
				icon: <Icon.Grid size={14} />,
				roles: ['root', 'sale'],
				permissions: ['item_preset'],
				navLink: '/products/type',
				filterBase: '/products/type',
				parentOf: '/products/type/:filter',
			},
			// {
			// 	id: "mockup_designs",
			// 	title: "Mockup Designs",
			// 	type: "item",
			// 	icon: < Icon.PenTool size={
			// 		14
			// 	}
			// 	/>,
			// 	roles: ["root", "sale"],
			// 	permissions: ["productDesigns"],
			// 	navLink: "/items/mockups/:filter",
			// 	filterBase: "/items/mockups/all?status=Published",
			// },
			{
				id: 'mockups',
				title: '2D Design',
				type: 'item',
				icon: <Icon.Grid size={14} />,
				roles: ['root', 'sale'],
				permissions: ['mockup'],
				navLink: '/mockups/:filter',
				filterBase: '/mockups/all',
			},
		],
	},
	{
		id: 'manager_blog',
		title: 'Blogs',
		type: 'collapse',
		icon: <Icon.BookOpen size={18} />,
		children: [
			{
				id: 'blog-make',
				title: 'Write blog',
				type: 'item',
				icon: <Icon.PenTool size={18} />,
				roles: ['root', 'sale'],
				navLink: '/blogs/write',
				filterBase: '/blogs/write',
			},
			{
				id: 'blog',
				title: 'Blogs',
				type: 'item',
				icon: <Icon.BookOpen size={18} />,
				roles: ['root', 'sale'],
				navLink: '/blogs/all',
				filterBase: '/blogs/all',
			},
		],
	},
	// {
	// 	id: 'manager_network',
	// 	title: 'Social Network',
	// 	type: 'collapse',
	// 	icon: <Icon.Share2 size={18} />,
	// 	children: [
	// 		{
	// 			id: 'social_post',
	// 			title: 'Post',
	// 			type: 'item',
	// 			icon: <Icon.Twitter size={18} />,
	// 			roles: ['root', 'sale'],
	// 			navLink: '/social/post',
	// 			filterBase: '/social/post',
	// 		},
	// 		{
	// 			id: 'social_profile',
	// 			title: 'Social Profiles',
	// 			type: 'item',
	// 			icon: <Icon.Share2 size={18} />,
	// 			roles: ['root', 'sale'],
	// 			navLink: '/social/networks',
	// 			filterBase: '/social/networks',
	// 		},
	// 	],
	// },
	{
		type: 'groupHeader',
		groupTitle: 'Market & Provider',
	},
	{
		id: 'store_menu',
		title: 'Stores',
		type: 'item',
		icon: <Icon.ShoppingBag size={18} />,
		roles: ['root', 'sale'],
		permissions: ['store'],
		navLink: '/stores/:filter',
		filterBase: '/stores/all?status=Valid',
	},
	{
		id: 'fulfilment_menu',
		title: 'Fulfilments',
		type: 'item',
		icon: <Icon.ShoppingCart size={18} />,
		roles: ['root', 'sale'],
		permissions: ['fulfilment'],
		navLink: '/fulfilments/:filter',
		filterBase: '/fulfilments/all',
	},
	{
		id: 'shipping_preset_menu',
		title: 'Policies',
		type: 'item',
		icon: <Icon.Truck size={14} />,
		roles: ['root', 'sale'],
		permissions: ['shipping_preset'],
		navLink: '/shipping-preset',
		filterBase: '/shipping-preset',
	},
	// {
	// 	id: "shipments",
	// 	title: "Shipments",
	// 	type: "item",
	// 	icon: < Icon.Truck size = {
	// 		14
	// 	}
	// 	/>,
	// 	roles: ["root"],
	// 	permissions: ["shipments"],
	// 	navLink: "/shipments",
	// 	filterBase: "/shipments?status=All",
	// },
	{
		type: 'groupHeader',
		groupTitle: 'Management',
	},
	{
		id: 'jobs',
		title: 'Jobs',
		type: 'item',
		icon: <Icon.Loader size={18} />,
		navLink: '/jobs',
		filterBase: '/jobs',
		roles: ['root', 'sale'],
		permissions: ['job'],
	},
	{
		id: 'tasks_manager',
		title: 'Tasks',
		type: 'collapse',
		icon: <Icon.CheckSquare size={18} />,
		roles: ['root', 'sale'],
		permissions: ['tasks'],
		children: [
			{
				id: 'task-design',
				title: 'Design',
				type: 'item',
				icon: <Icon.Image size={14} />,
				navLink: '/tasks/design/:category/:status',
				filterBase: '/tasks/design/all',
				roles: ['root', 'sale'],
				permissions: ['tasks'],
			},
			{
				id: 'task-support',
				title: 'Order Tickets',
				type: 'item',
				icon: <Icon.Truck size={14} />,
				navLink: '/tasks/support/:category/:status',
				filterBase: '/tasks/support/all',
				roles: ['root', 'sale'],
				permissions: ['tasks'],
			},
		],
	},
	{
		id: 'media',
		title: 'Media',
		type: 'collapse',
		icon: <Icon.Image size={18} />,
		roles: ['root', 'sale'],
		permissions: ['file', 'design'],
		children: [
			{
				id: 'design_collection',
				title: 'Designs',
				type: 'item',
				icon: <Icon.Image size={18} />,
				roles: ['root', 'sale'],
				permissions: ['design'],
				navLink: '/design-collection/:filter',
				filterBase: '/design-collection/all',
			},
			{
				id: 'files',
				title: 'Files',
				type: 'item',
				icon: <Icon.File size={18} />,
				roles: ['root', 'sale'],
				permissions: ['file'],
				navLink: '/files/:filter',
				filterBase: '/files/all',
			},
		],
	},
	// {
	// 	id: "manager_menu",
	// 	title: "Setting",
	// 	type: "collapse",
	// 	icon: < Icon.Settings size={
	// 		18
	// 	}
	// 	/>,
	// 	children: [
	// 		{
	// 			id: "manager_storage",
	// 			title: "Storage",
	// 			type: "item",
	// 			icon: < Icon.Database size={
	// 				14
	// 			}
	// 			/>,
	// 			navLink: "/storage",
	// 			filterBase: "/storage",
	// 			roles: ["root", "sale"],
	// 			permissions: ["storage"],
	// 		},
	// 		{
	// 			id: "manager_ebay_app",
	// 			title: "Ebay App",
	// 			type: "item",
	// 			icon: < Icon.Database size={
	// 				14
	// 			}
	// 			/>,
	// 			navLink: "/ebay-app",
	// 			filterBase: "/ebay-app",
	// 			roles: ["root", "sale"],
	// 			permissions: ["configuration"],
	// 		},
	// 		{
	// 			id: "manager_ebay_template",
	// 			title: "Ebay Template",
	// 			type: "item",
	// 			icon: <Icon.Database size={14} />,
	// 			navLink: "/ebay-template/:filter",
	// 			filterBase: "/ebay-template/all",
	// 			roles: ["root", "sale"],
	// 		},
	// 		{
	// 			id: "manager_etsy_app",
	// 			title: "Etsy App",
	// 			type: "item",
	// 			icon: < Icon.Database size={
	// 				14
	// 			}
	// 			/>,
	// 			navLink: "/etsy-app",
	// 			filterBase: "/etsy-app",
	// 			roles: ["root", "sale"],
	// 			permissions: ["etsy_configuration"],
	// 		},
	// 		{
	// 			id: "etsy_key",
	// 			title: "Etsy API Keys",
	// 			type: "item",
	// 			icon: <Icon.Package size={14} />,
	// 			navLink: "/etsy_key/:filter",
	// 			filterBase: "/etsy_key/all",
	// 			roles: ["root", "sale"],
	// 			permissions: ["etsy_key"]
	// 		},
	// 		{
	// 			id: "auth_token",
	// 			title: "Api auth token",
	// 			type: "item",
	// 			icon: < Icon.Terminal size={
	// 				14
	// 			}
	// 			/>,
	// 			roles: ["root", "sale"],
	// 			permissions: ["auth_token"],
	// 			navLink: "/auth_token",
	// 			filterBase: "/auth_token",
	// 		},
	// 		{
	// 			id: "email_content",
	// 			title: "Email Content",
	// 			type: "item",
	// 			icon: < Icon.Mail size={
	// 				14
	// 			}
	// 			/>,
	// 			roles: ["root", "sale"],
	// 			permissions: ["email"],
	// 			navLink: "/email-content",
	// 			filterBase: "/email-content",
	// 		},
	// 		{
	// 			id: "customer_menu",
	// 			title: "Customer",
	// 			type: "item",
	// 			icon: < Icon.Coffee size={
	// 				18
	// 			}
	// 			/>,
	// 			roles: ["root", "sale"],
	// 			permissions: ["customer"],
	// 			navLink: "/customers/:filter",
	// 			filterBase: "/customers/all",
	// 		},
	// 		{
	// 			id: "send_email",
	// 			title: "Send Email",
	// 			type: "item",
	// 			icon: < Icon.Mail size={
	// 				18
	// 			}
	// 			/>,
	// 			roles: ["root", "sale"],
	// 			permissions: ["sendemail"],
	// 			navLink: "/emails/:filter",
	// 			filterBase: "/emails/all",
	// 		},
	// 	],
	// },
	{
		id: 'manager_users',
		title: 'Manage Users',
		type: 'item',
		icon: <Icon.Users size={18} />,
		navLink: '/users/:filter',
		filterBase: '/users/all',
		roles: ['root', 'sale'],
		permissions: ['user'],
	},
	// {
	// 	id: "manage_users_groups",
	// 	title: "Manage Users",
	// 	type: "collapse",
	// 	roles: ["root", "sale"],
	// 	permissions: ["user", "usergroup"],
	// 	icon: < Icon.Menu size={
	// 		18
	// 	}
	// 	/>,
	// 	children: [
	// 		{
	// 			id: "manager_users",
	// 			title: "Users",
	// 			type: "item",
	// 			icon: < Icon.Users size={
	// 				18
	// 			}
	// 			/>,
	// 			navLink: "/users/:filter",
	// 			filterBase: "/users/all",
	// 			roles: ["root", "sale"],
	// 			permissions: ["user"],
	// 		},
	// 		{
	// 			id: "manager_groups",
	// 			title: "User Groups",
	// 			type: "item",
	// 			icon: < Icon.UserCheck size={
	// 				18
	// 			}
	// 			/>,
	// 			navLink: "/user_groups/:filter",
	// 			filterBase: "/user_groups/all",
	// 			roles: ["root", "sale"],
	// 			permissions: ["usergroup"],
	// 		},
	// 	]
	// },
	{
		id: 'app_config',
		title: 'Integrations',
		type: 'item',
		icon: <Icon.Codesandbox size={18} />,
		roles: ['root', 'sale'],
		permissions: ['app_config'],
		navLink: '/app-config/:filter',
		filterBase: '/app-config/all',
	},
	{
		id: 'billing_account',
		title: 'Wallet',
		type: 'collapse',
		permissions: ['billing'],
		icon: <Icon.DollarSign size={18} />,
		children: [
			{
				id: 'top_up_account',
				title: 'Topup',
				type: 'external-link',
				icon: <Icon.Zap size={18} />,
				navLink: history.accountUrl() + '/billing/topup/all',
				filterBase: history.accountUrl() + '/billing/topup/all',
				roles: ['root', 'sale'],
				permissions: ['billing'],
			},
			{
				id: 'history_account',
				title: 'History',
				type: 'external-link',
				icon: <Icon.Activity size={18} />,
				navLink: history.accountUrl() + '/billing/history/all',
				filterBase: history.accountUrl() + '/billing/history/all',
				roles: ['root', 'sale'],
				permissions: ['billing'],
			},
			// {
			// 	id: "invoice",
			// 	title: "Invoice",
			// 	type: "external-link",
			// 	icon: < Icon.FileText size={ 18 }
			// 	/>,
			// 	navLink: "/billing/invoice",
			// 	filterBase: "/billing/invoice",
			// 	roles: ["root", "sale"],
			// }
		],
	},
	{
		id: 'settings',
		title: 'Settings',
		type: 'item',
		icon: <Icon.Settings size={18} />,
		roles: ['root', 'sale'],
		navLink: '/settings/:filter',
		filterBase: '/settings',
	},
	// {
	//   id: "vero",
	//   title: "Vero",
	//   type: "item",
	//   icon: < Icon.CheckCircle size = {
	//     18
	//   }
	//   />,
	//   roles: ["root", "sale"],
	//   permissions: ["vero"],
	//   navLink: "/vero",
	//   filterBase: "/vero",
	// },
	{
		type: 'groupHeader',
		groupTitle: 'Private',
	},
	{
		id: 'help',
		title: 'Help',
		type: 'external-link',
		icon: <Icon.HelpCircle size={18} />,
		navLink: 'https://podorder.io/docs',
	},
	// {
	// 	id: "container_menu",
	// 	title: "Container",
	// 	type: "item",
	// 	icon: < Icon.Server size={
	// 		18
	// 	}
	// 	/>,
	// 	navLink: "/containers/:filter",
	// 	filterBase: "/containers/all",
	// 	roles: ["super_root"],
	// },
	// {
	// 	id: "private-fulfilment",
	// 	title: "Self FF",
	// 	type: "item",
	// 	icon: < Icon.List size={
	// 		18
	// 	}
	// 	/>,
	// 	roles: ["super_root"],
	// 	navLink: "/private-fulfilment/:filter",
	// 	filterBase: "/private-fulfilment/all?status=Processing",
	// },
];

export default navigationConfig;
