import _ from 'lodash';
const Query = require('graphql-query-builder');

export const responseField = [
	'_id',
	'name',
	'status',
	'auto',
	'created_at',
	'updated_at',
	'type',
	{ customcat_config: ['key', 'permissions'] },
	{ merchize_config: ['url', 'key'] },
	{ geargag_config: ['key'] },
	{ scalable_config: ['key'] },
	{ private_config: ['url', 'key'] },
	{ printify_config: ['key', 'store'] },
	{ dreamship_config: ['key'] },
	{ gearment_config: ['key', 'signature'] },
	{
		shineon_config: ['key'],
	},
	{
		onos_config: ['key'],
	},
	{
		printway_config: ['key'],
	},
	'total_paid', // only available with payment for fulfillment
	'default',
];

export const fulfilmentQuery = (args) => {
	let params = {
		id: _.get(args, 'id', ''),
		name: _.get(args, 'name', ''),
		status: _.get(args, 'status', ''),
		type: _.get(args, 'type', ''),
		types: _.get(args, 'types', []),
		private: _.get(args, 'private', false),
	};
	let response = [...responseField];

	if (_.get(args, 'include_balance', false)) {
		response = [...response, 'balance'];
	}
	const query = new Query('fulfilments', params).find(response);
	return `query {${query}}`;
};

export const fulfilmentCreateQuery = (args) => {
	let params = {};
	let config = {};
	if (_.get(args, 'name', false)) {
		params['name'] = _.get(args, 'name', false);
	}
	if (_.get(args, 'custom_cat_key', false)) {
		config['customcat_config'] = {
			key: _.get(args, 'custom_cat_key', false),
		};
	}

	if (_.get(args, 'merchize_access_token', false)) {
		config['merchize_config'] = {
			url: _.get(args, 'merchize_url', false),
			key: _.get(args, 'merchize_access_token', false),
		};
	}

	if (_.get(args, 'geargag_access_token', false)) {
		config['geargag_config'] = {
			key: _.get(args, 'geargag_access_token', false),
		};
	}

	if (_.get(args, 'scalable_access_token', false)) {
		config['scalable_config'] = {
			key: _.get(args, 'scalable_access_token', false),
		};
	}

	if (_.get(args, 'private_access_token', false)) {
		config['private_config'] = {
			url: _.get(args, 'private_url', ''),
			key: _.get(args, 'private_access_token', ''),
		};
	}

	if (_.get(args, 'printify_key', false)) {
		config['printify_config'] = {
			key: _.get(args, 'printify_key', false),
			store: String(_.get(args, 'printify_store', false)),
		};
	}

	if (_.get(args, 'dreamship_key', false)) {
		config['dreamship_config'] = {
			key: _.get(args, 'dreamship_key', false),
		};
	}
	if (_.get(args, 'gearment_key', false)) {
		config['gearment_config'] = {
			key: _.get(args, 'gearment_key', false),
			signature: String(_.get(args, 'gearment_signature', false)),
		};
	}
	if (_.get(args, 'shineon_access_token', false)) {
		config['shineon_config'] = {
			key: _.get(args, 'shineon_access_token', false),
		};
	}
	if (_.get(args, 'onos_key', false)) {
		config['onos_config'] = {
			key: _.get(args, 'onos_key', false),
		};
	}

	if (_.get(args, 'geekpod_key', false)) {
		config['geekpod_config'] = {
			key: _.get(args, 'geekpod_key', false),
		};
	}

	if (_.get(args, 'printway_key', false)) {
		config['printway_config'] = {
			key: _.get(args, 'printway_key', false),
			email: _.get(args, 'printway_email', false),
			password: _.get(args, 'printway_password', false),
		};
	}

	if (_.get(args, 'lenful_key', false)) {
		config['lenful_config'] = {
			key: _.get(args, 'lenful_key', false),
			email: _.get(args, 'lenful_email', false),
			password: _.get(args, 'lenful_password', false),
			lenful_store: _.get(args, 'lenful_store', false),
		};
	}

	if (config) {
		params['params'] = config;
	}
	const query = new Query('makeFulfilment', params).find(responseField);
	return `mutation {${query}}`;
};

export const fulfilmentUpdateQuery = (args) => {
	let params = { id: _.get(args, '_id', false) };
	let config = {};
	if (_.get(args, 'name', false)) {
		params['name'] = _.get(args, 'name', false);
	}
	if (_.get(args, 'status', false)) {
		params['status'] = _.get(args, 'status', false);
	}
	if (_.get(args, 'auto', false)) {
		params['auto'] = _.get(args, 'auto', false);
	}
	if (_.get(args, 'custom_cat_key', false)) {
		config['customcat_config'] = {
			key: _.get(args, 'custom_cat_key', false),
		};
	}
	if (_.get(args, 'merchize_access_token', false)) {
		config['merchize_config'] = {
			url: _.get(args, 'merchize_url', false),
			key: _.get(args, 'merchize_access_token', false),
		};
	}

	if (_.get(args, 'scalable_access_token', false)) {
		config['scalable_config'] = {
			key: _.get(args, 'scalable_access_token', false),
		};
	}

	if (_.get(args, 'geargag_access_token', false)) {
		config['geargag_config'] = {
			key: _.get(args, 'geargag_access_token', false),
		};
	}
	if (_.get(args, 'private_access_token', false)) {
		config['private_config'] = {
			url: _.get(args, 'private_url', false),
			key: _.get(args, 'private_access_token', false),
		};
	}
	if (_.get(args, 'printify_key', false)) {
		config['printify_config'] = {
			key: _.get(args, 'printify_key', false),
		};
	}
	if (_.get(args, 'printify_store', false)) {
		config['printify_config'] = {
			..._.get(config, 'printify_config', {}),
			store: String(_.get(args, 'printify_store', false)),
		};
	}
	if (_.get(args, 'dreamship_key', false)) {
		config['dreamship_config'] = {
			key: _.get(args, 'dreamship_key', false),
		};
	}
	if (_.get(args, 'gearment_key', false)) {
		config['gearment_config'] = {
			key: _.get(args, 'gearment_key', false),
			signature: _.get(args, 'gearment_signature', false),
		};
	}
	if (_.get(args, 'onos_key', false)) {
		config['onos_config'] = {
			key: _.get(args, 'onos_key', false),
		};
	}

	if (_.get(args, 'geekpod_key', false)) {
		config['geekpod_config'] = {
			key: _.get(args, 'geekpod_key', false),
		};
	}

	if (config) {
		params['params'] = config;
	}
	const query = new Query('makeFulfilment', params).find(responseField);
	return `mutation {${query}}`;
};

export const fulfilmentTrashQuery = (args) => {
	const delete_ids = _.get(args, 'delete_ids', []).map((d) => {
		return d.trim();
	});
	const query = new Query('trashFulfilment', {
		ids: delete_ids,
	}).find(responseField);
	return `mutation {${query}}`;
};
