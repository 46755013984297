import React from "react"
import {
	NavItem,
	Spinner,
	NavLink
} from "reactstrap"
import { connect } from "react-redux"

class NavBalance extends React.PureComponent {
	state = {
	}

	componentDidMount() {
	}

	render() {
		return <NavItem className="nav-item d-none d-lg-block">
			<NavLink>
				<b style={{ lineHeight: "24px", fontSize: "13px" }} className={`text-${this.props.app.customizer.navbarColor === 'default' ? this.props.app.customizer.menuTheme : 'default'}`}>{
					typeof this.props.user?._id === undefined
						? <Spinner className="reload-spinner" color={this.props.app.customizer.menuTheme} />
						: `$` + parseFloat(this.props.user.total_credit || 0).toFixed(2)
				}</b>
			</NavLink>
		</NavItem>
	}
}
const mapStateToProps = state => {
	return {
		user: state.auth.login.loggedInUser,
		app: state.customizer
	}
}
export default connect(mapStateToProps)(NavBalance)
