// import axios from "axios"
import { history } from "../../../history"

export const logoutWithJWT = user => {
  return dispatch => {
    const loggedInUser = {}
    dispatch({
      type: "LOGOUT",
      payload: { loggedInUser, loggedInWith: "" }
    })
    history.logout()
    // axios
    //   .post("/api/authenticate/login/user", {
    //     email: user.email,
    //     password: user.password
    //   })
    //   .then(response => {
        // var loggedInUser
        //
        // if (response.data) {
        //   loggedInUser = response.data.user
        // let loggedInUser = {}

        //   dispatch({
        //     type: "LOGOUT",
        //     payload: { loggedInUser, loggedInWith: "" }
        //   })

        //   history.logout()
        // }
      // })
  }
}
